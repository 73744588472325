import { useEffect, useMemo, useRef, useState } from 'react';
import { AppButton } from '../../../general/AppButton/AppButton';
import AppDatePicker from '../../../general/AppDatePicker/AppDatePicker';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { appDelay, SupplierJobApiResponse } from '@dill/dill-shared';
import { COLORS } from '../../../../utils/colors';
import { Plus, Trash, X } from 'phosphor-react';
import { AppDropDown2 } from '../../../general/AppDropDown2/AppDropDown2';
import { getSupplierJobs } from '../../../../redux/services/supplierJobsService';
import { manualCreatePreliminaryNotice } from '../../../../redux/services/preliminaryNoticesService';
import { Modal } from '@mui/material';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';

const BulkAddManualPrelimNoticesModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { supplierJobsNoInvoicesMap } = useAppSelector((globalState) => globalState.supplierJobs);

  const [dataFullyLoaded, setDataFullyLoaded] = useState(false);
  // const [isSomeMissingFields, setIsSomeMissingFields] = useState(false);
  const [isCheckErrors, setIsCheckErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const count = useRef(0);

  const [filesDetails, setFilesDetails] = useState<
    {
      fileName: string;
      fileUrl: string;
      errorFields?: string[];
      selectedJob?: SupplierJobApiResponse;
      createdAt?: Date;
    }[]
  >([]);

  const checkErrors = () => {
    let isMissingFields = false;

    const updatedFilesDetails = filesDetails.map((details) => {
      const errorFields = [];
      if (!details.selectedJob) {
        errorFields.push('selectedJob');
        isMissingFields = true;
      }
      if (!details.createdAt) {
        isMissingFields = true;
        errorFields.push('createdAt');
      }
      return { ...details, errorFields };
    });
    setFilesDetails([...updatedFilesDetails]);
    if (isMissingFields) {
      setErrorMessage('There are some missing fields');
    }
    if (filesDetails.length === 0) {
      setErrorMessage('No files have been added');
    }
    if (filesDetails.length > 0 && !isMissingFields) {
      setErrorMessage('');
    }
    setIsCheckErrors(true);
    return isMissingFields;
  };

  const handleSubmitPreliminaryNotice = async () => {
    const isErrors = checkErrors();
    if (isErrors || filesDetails.length === 0) {
      return;
    }
    dispatch(
      openMessageModal({
        modalType: 'INFO',
        title: `Are you sure you want to manually add these preliminary notices?`,
        subTitle: 'These will be added as completed preliminary notices',
        buttonText: 'No, cancel ',
        isSecondButton: true,
        secondButtonText: 'Yes, add them',
        onSecondButtonClick: async () => {
          await appDelay(100);
          dispatch(
            openMessageModal({
              modalType: 'LOADING',
              title: `Manually Adding Preliminary Notices (${count.current + 1}/${
                filesDetails.length
              })`,
              subTitle: 'Please do not close this tab before the upload is complete.',
              buttonText: '',
            })
          );
          let isErrorUploading = false;
          for (const filesDetail of filesDetails) {
            const preliminaryNoticeData = {
              supplierId: user?.userSupplier?.id ?? '',
              jobId: filesDetail.selectedJob?.id ?? '',
              templateId: '',
              ownerStatus: null,
              contractorStatus: null,
              lenderStatus: null,
              status: 'completed',
              fieldsData: [],
              pdf: { url: '', name: filesDetail.fileName ?? '' },
            };
            const results = await dispatch(
              manualCreatePreliminaryNotice({
                preliminaryNoticeDetails: {
                  ...preliminaryNoticeData,
                },
                preliminaryNoticePdf: filesDetail.fileUrl ?? '',
                createdAt: filesDetail.createdAt ?? null,
                isHideLoadingIndicator: true,
              })
            );

            if (results.type === 'preliminaryNotices/manualCreatePreliminaryNotice/fulfilled') {
              count.current = count.current + 1;
              dispatch(
                openMessageModal({
                  modalType: 'LOADING',
                  title: `Manually Adding Preliminary Notices (${count.current + 1}/${
                    filesDetails.length
                  })`,
                  subTitle: 'Please do not close this tab before the upload is complete.',
                  buttonText: '',
                })
              );
            } else {
              const error = results.payload;
              dispatch(
                openMessageModal({
                  modalType: 'ERROR',
                  title: `Failed to upload preliminary notice for file ${filesDetail.fileName}`,
                  subTitle: `${error || ''}`,
                  buttonText: 'Close',
                })
              );
              isErrorUploading = true;
              break;
            }
          }
          count.current = 0;
          if (!isErrorUploading) {
            dispatch(
              openMessageModal({
                modalType: 'SUCCESS',
                title: `Preliminary Notices manually added!`,
                subTitle: 'These have been added as completed preliminary notices.',
                buttonText: 'View All Preliminary Notices',
                onClose: () => {
                  handleClose();
                },
              })
            );
          }
        },
      })
    );
  };

  const recursiveFetchSupplierJob = async ({
    lastDockId,
    fetchJobs,
  }: {
    lastDockId: string;
    fetchJobs: SupplierJobApiResponse[];
  }) => {
    const pageSize = 100;
    let currentPreviousLastDocumentId = lastDockId;
    setDataFullyLoaded(false);

    const results = await dispatch(
      getSupplierJobs({
        includeInvoices: false,
        includePreLiens: false,
        previousLastDocumentId: currentPreviousLastDocumentId,
        pageSize,
        sortOrder: 'asc',
        sortBy: 'name',
        isHideLoadingIndicator: true,
      })
    );
    if (results.type === 'supplierJobs/getSupplierJobs/fulfilled') {
      if (results.payload) {
        const payload = results.payload as any;
        const fetchedSupplierJobs: SupplierJobApiResponse[] = payload.data as any;
        const lastDocumentId = payload.lastDocumentId;
        if (
          lastDocumentId === currentPreviousLastDocumentId ||
          fetchedSupplierJobs.length === pageSize
        ) {
          currentPreviousLastDocumentId = lastDocumentId;
          await recursiveFetchSupplierJob({
            lastDockId: lastDocumentId,
            fetchJobs: [...fetchJobs, ...fetchedSupplierJobs],
          });
        } else {
          console.log('No more pages to fetch');
          setDataFullyLoaded(true);
        }
      }
    } else {
      console.log('Fetch failed or interrupted');
      setDataFullyLoaded(true);
    }
  };

  const fetchData = async () => {
    recursiveFetchSupplierJob({ lastDockId: '', fetchJobs: [] });
  };

  useEffect(() => {
    if (open) {
      setFilesDetails([]);
      setErrorMessage('');
      setIsCheckErrors(false);
      fetchData();
      count.current = 0;
    }

    return () => {};
  }, [open]);

  const handleEdit = ({
    index,
    value,
    key,
  }: {
    index: number;
    value: any;
    key: 'createdAt' | 'selectedJob';
  }) => {
    const editedDetails = filesDetails.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key]: value,
          errorFields: (item.errorFields || []).filter((ii) => ii !== key),
        };
      }
      return item;
    });
    setFilesDetails([...editedDetails]);
  };
  const handleDelete = ({ index }: { index: number }) => {
    const editedDetails = filesDetails.filter((item, i) => index !== i);
    setFilesDetails([...editedDetails]);
  };

  const columns = useMemo(() => {
    // 'File Name', 'Job', 'Created Date';
    return [
      { id: 'fileName', name: 'File Name', width: 100 },
      { id: 'job', name: 'Job', width: 100 },
      { id: 'createdAt', name: 'Created Date', width: 100 },
      { id: 'actions', name: '', width: 40 },
    ];
  }, [open]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-40 w-[50vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col px-5 py-1">
        <div className="sticky top-0 z-10 bg-white py-2">
          <X
            size={20}
            className="absolute right-0 top-3 cursor-pointer"
            color={COLORS.GREY_500}
            onClick={() => {
              handleClose();
            }}
          />
          <h3 className="text-lg font-bold ">Bulk Manual Add Preliminary Notice</h3>
        </div>
        <div className="flex flex-col py-4  px-4">
          <div className="relative w-[100px] cursor-pointer">
            <input
              id="upload"
              multiple
              className="absolute w-[100px] h-full bg-red-500 top-0 bottom-0 opacity-0  cursor-pointer  z-30"
              type="file"
              accept="application/pdf"
              onChange={async (event) => {
                if (!event?.target.files) {
                  return;
                }
                const files = event?.target?.files;

                if (files.length > 0) {
                  const details: {
                    fileName: string;
                    fileUrl: string;
                    selectedJobId?: string;
                    createdAt?: Date;
                  }[] = [];
                  for (let index = 0; index < files.length; index++) {
                    const ddd: {
                      fileName: string;
                      fileUrl: string;
                      selectedJobId?: string;
                      createdAt?: Date;
                    } = await new Promise((resolve, reject) => {
                      const file = files[index];
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const base64String = reader.result as string;
                        const fileName = file.name.split('.')[0];

                        resolve({ fileName, fileUrl: base64String });
                      };
                      reader.readAsDataURL(file);
                    });
                    details.push({
                      ...ddd,
                    });
                  }
                  setFilesDetails([...filesDetails, ...details]);
                }
              }}
            />
            <AppButton
              text="Add files"
              type="SECONDARY"
              icon={<Plus color={COLORS.PRIMARY_500} />}
              buttonStyles={{
                height: '30px',
                width: '100px',
                cursor: 'pointer',
              }}
            />
          </div>
          <div className="flex flex-col overflow-scroll max-h-80 min-h-40 border mt-3 rounded-md">
            <div className="flex sticky top-0 PRIMARY_50-BG py-1 z-10 px-4 ">
              {columns.map((column, index) => {
                return (
                  <div
                    style={{ flex: column.width / totalWidth }}
                    key={`${index}oo`}
                    className="w-1/3 TEXT_SECONDARY-CLR text-sm  py-2">
                    {column.name}
                  </div>
                );
              })}
            </div>
            {filesDetails.length > 0 && (
              <div>
                {filesDetails.map((files, index) => {
                  return (
                    <div
                      key={`${index}oo`}
                      className="flex border-b text-sm py-0.5 items-center px-4">
                      {columns.map((column, i) => {
                        return (
                          <div
                            style={{ flex: column.width / totalWidth }}
                            key={`${i}oo`}
                            className="px-0.5 flex">
                            {column.id === 'fileName' && (
                              <div className=" px-0.5 text-xs">{files.fileName}</div>
                            )}

                            {column.id === 'job' && (
                              <div className="w-full ">
                                <AppDropDown2
                                  value={files?.selectedJob?.id || ''}
                                  isLoading={!dataFullyLoaded}
                                  errorText={
                                    files.errorFields && files.errorFields.includes('selectedJob')
                                      ? 'Reqiured'
                                      : ''
                                  }
                                  items={[
                                    ...Object.values(supplierJobsNoInvoicesMap)
                                      .filter(
                                        (jb) => !jb?.archivedState?.includes('SUPPLIER_ARCHIVED')
                                      )
                                      .map((job) => {
                                        return {
                                          label: `${job?.jobNumber ? `${job.jobNumber} - ` : ''}${
                                            job.name
                                          }`,
                                          value: job.id,
                                          labelInElement: (
                                            <div className="flex px-2 py-3 m-[-5px] items-center justify-between group relative">
                                              <div className="text-xs">{`${
                                                job?.jobNumber ? `${job.jobNumber} - ` : ''
                                              }${job.name}`}</div>
                                            </div>
                                          ),
                                        };
                                      }),
                                  ]}
                                  onSelectChange={(item) => {
                                    if (item?.value) {
                                      const uu = supplierJobsNoInvoicesMap[item.value];
                                      if (uu) {
                                        handleEdit({ index, key: 'selectedJob', value: uu });
                                      }
                                    }
                                  }}
                                />
                              </div>
                            )}
                            {column.id === 'createdAt' && (
                              <div className="w-full">
                                <AppDatePicker
                                  selectedDate={files?.createdAt}
                                  errorText={
                                    files.errorFields && files.errorFields.includes('createdAt')
                                      ? 'Reqiured'
                                      : ''
                                  }
                                  onDateSelected={(date) => {
                                    handleEdit({ index, key: 'createdAt', value: date });
                                  }}
                                />
                              </div>
                            )}
                            {column.id === 'actions' && (
                              <div className="flex w-full justify-center">
                                <Trash
                                  size={20}
                                  className="text-red-500 cursor-pointer"
                                  onClick={() => {
                                    handleDelete({ index });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="">
            {errorMessage && isCheckErrors && (
              <p className="text-red-500 text-sm">{errorMessage}</p>
            )}
          </div>

          <div className="mt-3 flex flex-col w-full ">
            <div className="self-end">
              <AppButton
                text="Submit"
                onClick={() => {
                  handleSubmitPreliminaryNotice();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BulkAddManualPrelimNoticesModal;
