import React, { useMemo } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import emptyListImg from '../../../assets/images/emptyList.png';
import { RequestedSupplierJobApiResponse } from '@dill/dill-shared';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';

const RequestedJobList = ({
  onClose,
  requestedSupplierJobs,
  onSelectJob,
}: {
  onClose: () => void;
  requestedSupplierJobs: RequestedSupplierJobApiResponse[];
  onSelectJob: (data: RequestedSupplierJobApiResponse) => void;
}) => {
  const columns = useMemo(() => {
    return [
      { id: 'name', name: 'Job Name', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'proposedJobName', name: 'Proposed Job Name', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'buyerName', name: 'Customer', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'createdAt', name: 'Submitted On', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 100, cellType: 'VIEW_TEXT' },
    ];
  }, []);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  return (
    <div className="flex w-[50vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
      <div>
        <h2 className="text-lg font-semibold mx-4 my-3">{'Job Information Sheet Submissions'}</h2>
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={onClose}
        />
      </div>
      <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
      <div className="max-h-[calc(90vh-120px)] p-4 overflow-y-auto">
        <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG items-center z-10">
          {columns.map((column, i) => {
            return (
              <div
                key={column.id}
                style={{ flex: column.width / totalWidth }}
                className={twMerge('flex py-3 mx-1', 'items-center')}>
                <p className="text-xs TEXT_SECONDARY-CLR items-center">{column.name}</p>
              </div>
            );
          })}
        </div>
        {requestedSupplierJobs.length === 0 && (
          <div className="w-full h-[70vh] flex items-center justify-center">
            <div className="flex flex-col items-center">
              <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
              <p>No Requested Job Information</p>
            </div>
          </div>
        )}

        {requestedSupplierJobs.length > 0 &&
          requestedSupplierJobs.map((requestedSupplierJob, index) => {
            return (
              <div key={index + 'oo'} className="w-full flex items-center  px-4 justify-between">
                {columns.map((column, i) => {
                  return (
                    <div
                      key={column.id}
                      style={{ flex: column.width / totalWidth }}
                      className={`flex py-3 mx-1 ${
                        columns.length - 1 !== i ? 'overflow-hidden' : ''
                      }`}>
                      {column.id === 'buyerName' && (
                        <div className="flex  w-full">
                          <p className="text-sm capitalize ">
                            {requestedSupplierJob?.buyerName.toLocaleLowerCase() || ''}
                          </p>
                        </div>
                      )}
                      {column.id === 'proposedJobName' && (
                        <p className="text-sm capitalize w-full">{requestedSupplierJob?.name}</p>
                      )}
                      {column.id === 'name' && (
                        <p className="text-sm capitalize w-full">
                          {requestedSupplierJob?.supplierJob?.name ?? ''}
                        </p>
                      )}
                      {column.id === 'createdAt' && (
                        <p className="text-sm capitalize w-full">
                          {moment(requestedSupplierJob?.createdAt).format('MM/DD/YYYY')}
                        </p>
                      )}
                      {column.id === 'actions' && (
                        <div className="flex gap-2">
                          <AppButton
                            type="PRIMARY"
                            text="View"
                            buttonStyles={{
                              height: '35px',
                              marginLeft: '4px',
                            }}
                            onClick={() => {
                              onSelectJob(requestedSupplierJob);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
      <div className="flex justify-end mx-3 mb-2">
        <AppButton
          text="Close"
          type="TERTIARY"
          onClick={onClose}
          buttonStyles={{ marginRight: '8px' }}
        />
      </div>
    </div>
  );
};

export default RequestedJobList;
