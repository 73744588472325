import { useEffect, useMemo, useRef, useState } from 'react';

import { X } from 'phosphor-react';

import { AppButton } from '../AppButton/AppButton';

import { COLORS } from '../../../utils/colors';

import './AppNewSupplierJobModal.scss';
import {
  getAllStates,
  getStateAbbreviation,
  SupplierJobUpdate,
  Buyer,
  SupplierJobApiResponse,
} from '@dill/dill-shared';
import { PartialSupplierOwnerVerify, SupplierVerifiedJobFormData } from './constants';
import VerifySupplierJob from './VerifyJob';
import { AppInputField } from '../AppInputField/AppInputField';
import { on } from 'events';
import { VerifyJobRequestData } from '../../../redux/services/verifyAddressService';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import EditBuyerModal from '../../modules/suppliers/EditBuyerModal/EditBuyerModal';
import { AppDropDown2 } from '../AppDropDown2/AppDropDown2';
import { getSupplierBuyers } from '../../../redux/services/supplierBuyersService';
import { twMerge } from 'tailwind-merge';

export type VerifiedJobDataPick = Pick<
  SupplierJobUpdate,
  | 'name'
  | 'jobNumber'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
  | 'buyerId'
  | 'owner'
  | 'ownerAddress'
  | 'ownerCity'
  | 'ownerState'
  | 'ownerZipCode'
  | 'GC'
  | 'GCAddress'
  | 'GCCity'
  | 'GCState'
  | 'GCZipCode'
  | 'GCEmail'
  | 'lender'
  | 'lenderAddress'
  | 'lenderCity'
  | 'lenderState'
  | 'lenderZipCode'
  | 'apn'
  | 'constructionMonitorPermitId'
  | 'estimatedMaterialAmount'
  | 'jobType'
  | 'jobSector'
  | 'customerType'
  | 'jobFurnishings'
  | 'hasNoLender'
  | 'jobCounty'
  | 'jobLegalDescription'
>;

interface SupplierVerifyJobFormFunctions {
  supplierJobData: PartialSupplierOwnerVerify;
  onClose: () => void;
  onBack: () => void;
  onUseVerifiedData: (data: Record<string, any>) => void;
  onManualDataUpdate: (data: Record<string, any>) => void;
  editMode: 'ADD' | 'EDIT';
  onBuyerEdit?: () => void;
  onVerify: (data: Record<string, any>) => void;
  verifying: boolean;
  onRequestInfoSheet: (data: any) => void;
  verifyJobCheckBox: boolean;
}

enum ModalState {
  ADD_JOB,
  VERIFY_JOB,
  VERIFY_OWNER,
  VERIFY_OWNER_MANUAL,
}

type SupplierVerifyJobFormProps = SupplierVerifyJobFormFunctions & SupplierVerifiedJobFormData;

const SupplierVerifyJobForm = (props: SupplierVerifyJobFormProps) => {
  const {
    onClose,
    onBack,
    onUseVerifiedData,
    onManualDataUpdate,
    onBuyerEdit,
    onVerify,
    onRequestInfoSheet,
    jobAddress,
    jobCity,
    jobState,
    jobZipCode,
    owner,
    ownerAddress,
    ownerCity,
    ownerState,
    ownerZipCode,
    GC,
    GCAddress,
    GCCity,
    GCState,
    GCZipCode,
    verifiedProperty,
    jobNumber,
    name,
    buyerId,
    supplierJobData,
    constructionMonitorPermitId,
    estimatedMaterialAmount,
    jobType,
    jobSector,
    customerType,
    jobFurnishings,
    hasNoLender,
    editMode,
    verifying,
    verifyJobCheckBox,
  } = props;

  const dispatch = useAppDispatch();
  const [state, setState] = useState<ModalState>(ModalState.VERIFY_JOB);
  const [formState, setFormState] = useState<VerifiedJobDataPick>({});
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [isEditBuyerModalOpen, setIsEditBuyerModalOpen] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState<Buyer | null>(null);
  const [ownerAndGCSame, setOwnerAndGCSame] = useState(false);

  const [errors, setErrors] = useState<VerifiedJobDataPick>({});

  const { user } = useAppSelector((globalState) => globalState.auth);
  const { supplierBuyers } = useAppSelector((globalState) => globalState.supplierBuyers);
  const { supplierJobsMap } = useAppSelector((globalState) => globalState.supplierJobs);

  const changeGCData = (changeName: string, value: string | number | boolean) => {
    if (
      (changeName === 'buyerId' || changeName === 'customerType') &&
      formState.GC?.trim() === ''
    ) {
      if (formState.customerType === 'GC' || value === 'GC') {
        let changeBuyerId = formState.buyerId;
        if (changeName === 'buyerId') {
          changeBuyerId = value as string;
        }
        const foundSupplierBuyer = supplierBuyers.find((supBuy) => supBuy.id === changeBuyerId);
        if (foundSupplierBuyer) {
          const data = {
            GC: foundSupplierBuyer?.name || '',
            GCAddress: foundSupplierBuyer?.address || '',
            GCCity: foundSupplierBuyer?.city || '',
            GCState: foundSupplierBuyer?.state || '',
            GCZipCode: foundSupplierBuyer?.zipCode || '',
          };
          setFormState((prevState) => ({
            ...prevState,
            ...data,
          }));
        }
      }
    }
  };

  const onChange2 = (data: { name: string; value: string | number | boolean }) => {
    setFormState((prevState) => ({
      ...prevState,
      [data.name]: data.value,
    }));
    changeGCData(data.name, data.value);
  };

  const allStates = useMemo(() => {
    return getAllStates();
  }, []);

  const changeAutoState = () => {
    setState(ModalState.VERIFY_OWNER);
  };

  const changeManualState = () => {
    setState(ModalState.VERIFY_OWNER_MANUAL);
  };

  useEffect(() => {
    if (!verifyJobCheckBox) {
      setState(ModalState.VERIFY_OWNER_MANUAL);
    }
  }, []);

  useEffect(() => {
    if (editMode === 'EDIT') {
      setState(ModalState.VERIFY_OWNER_MANUAL);
    }
  }, []);

  const closeModal = () => {
    setFormState({});
    setState(ModalState.VERIFY_JOB);
    onClose();
  };

  const handleBack = () => {
    if (
      (state === ModalState.VERIFY_OWNER || state === ModalState.VERIFY_OWNER_MANUAL) &&
      user?.userSupplier?.ownerVerificationEnabled &&
      verifyJobCheckBox
    ) {
      setState(ModalState.VERIFY_JOB);
    } else {
      onBack();
    }
  };

  const validateInputs = () => {
    const newErrors = {} as VerifiedJobDataPick;

    let isValid = true;

    if (formState.owner?.trim() === '') {
      newErrors.owner = 'Owner is required.';
      isValid = false;
    }
    if (formState.ownerAddress?.trim() === '') {
      newErrors.ownerAddress = 'Owner Address is required.';
      isValid = false;
    }
    if (formState.ownerCity?.trim() === '') {
      newErrors.ownerCity = 'Owner City is required.';
      isValid = false;
    }
    if (formState.ownerState?.trim() === '') {
      newErrors.ownerState = 'Owner State is required.';
      isValid = false;
    }
    if (formState.ownerZipCode?.trim() === '') {
      newErrors.ownerZipCode = 'Owner Zip is required.';
      isValid = false;
    }
    if (formState.GC?.trim() === '') {
      newErrors.GC = 'GC is required.';
      isValid = false;
    }
    if (formState.GCAddress?.trim() === '') {
      newErrors.GCAddress = 'GC Address is required.';
      isValid = false;
    }
    if (formState.GCCity?.trim() === '') {
      newErrors.GCCity = 'GC City is required.';
      isValid = false;
    }
    if (formState.GCState?.trim() === '') {
      newErrors.GCState = 'GC State is required.';
      isValid = false;
    }
    if (formState.GCZipCode?.trim() === '') {
      newErrors.GCZipCode = 'GC Zip is required.';
      isValid = false;
    }
    if (
      formState.estimatedMaterialAmount === '' ||
      Number(formState.estimatedMaterialAmount) <= 0
    ) {
      newErrors.estimatedMaterialAmount =
        formState.estimatedMaterialAmount === ''
          ? 'Estimated material amount is required.'
          : 'Estimated material amount should be more than 0.';
      isValid = false;
    }
    if (formState.jobFurnishings?.trim() === '') {
      newErrors.jobFurnishings = 'Job Furnishings is required.';
      isValid = false;
    }
    if (formState.lender?.trim() === '' && !formState.hasNoLender) {
      newErrors.lender = 'Lender is required.';
      isValid = false;
    }
    if (formState.lenderAddress?.trim() === '' && !formState.hasNoLender) {
      newErrors.lenderAddress = 'Lender Address is required.';
      isValid = false;
    }
    if (formState.lenderCity?.trim() === '' && !formState.hasNoLender) {
      newErrors.lenderCity = 'Lender City is required.';
      isValid = false;
    }
    if (formState.lenderState?.trim() === '' && !formState.hasNoLender) {
      newErrors.lenderState = 'Lender State is required.';
      isValid = false;
    }
    if (formState.lenderZipCode?.trim() === '' && !formState.hasNoLender) {
      newErrors.lenderZipCode = 'Lender Zip is required.';
      isValid = false;
    }
    if (formState.name?.trim() === '' && editMode === 'EDIT') {
      newErrors.name = 'Job name is required.';
      isValid = false;
    }
    if (formState.jobAddress?.trim() === '' && editMode === 'EDIT') {
      newErrors.jobAddress = 'Job address is required.';
      isValid = false;
    }
    if (formState.jobCity?.trim() === '' && editMode === 'EDIT') {
      newErrors.jobCity = 'Job city is required.';
      isValid = false;
    }
    if (formState.jobState?.trim() === '' && editMode === 'EDIT') {
      newErrors.jobState = 'Job state is required.';
      isValid = false;
    }
    if (formState.jobZipCode?.trim() === '' && editMode === 'EDIT') {
      newErrors.jobZipCode = 'Job zip code is required.';
      isValid = false;
    }
    if (formState.buyerId?.trim() === '' && editMode === 'EDIT') {
      newErrors.buyerId = 'Select a buyer.';
      isValid = false;
    }
    if (
      formState.jobState?.trim() === 'AZ' &&
      (formState.jobCounty?.trim() === '' || !formState.jobCounty)
    ) {
      newErrors.jobCounty = 'Job county is required.';
      isValid = false;
    }
    if (
      formState.jobState?.trim() === 'AZ' &&
      (formState.jobLegalDescription?.trim() === '' || !formState.jobLegalDescription)
    ) {
      newErrors.jobLegalDescription = 'Job legal description is required.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const onSubmitData = () => {
    if (!validateInputs()) {
      setShowErrorAbove(true);
      return;
    }
    setShowErrorAbove(false);
    const newFormStateData = {
      ...formState,
      lender: formState.hasNoLender ? '' : formState.lender,
      lenderAddress: formState.hasNoLender ? '' : formState.lenderAddress,
      lenderCity: formState.hasNoLender ? '' : formState.lenderCity,
      lenderState: formState.hasNoLender ? '' : formState.lenderState,
      lenderZipCode: formState.hasNoLender ? '' : formState.lenderZipCode,
    };
    if (state === ModalState.VERIFY_OWNER) {
      const updatdData = {
        ...newFormStateData,
        jobAddress: verifiedProperty?.address ? verifiedProperty.address : jobAddress,
        jobCity: verifiedProperty?.city ? verifiedProperty.city : jobCity,
        jobState: verifiedProperty?.state
          ? getStateAbbreviation(verifiedProperty.state || '')
          : getStateAbbreviation(jobState || ''),
        jobZipCode: verifiedProperty?.zipCode ? verifiedProperty.zipCode : jobZipCode,
        apn: verifiedProperty?.apn ? verifiedProperty.apn : '',
        constructionMonitorPermitId: constructionMonitorPermitId,
      };
      onUseVerifiedData(updatdData);
    } else if (state === ModalState.VERIFY_OWNER_MANUAL) {
      onManualDataUpdate(newFormStateData);
    }
    setState(ModalState.VERIFY_JOB);
    onClose();
  };

  const handleRequestInfoSheet = () => {
    const newErrors = {} as VerifiedJobDataPick;
    if (formState.buyerId?.trim() === '' && editMode === 'EDIT') {
      newErrors.buyerId = 'Select a buyer.';
      setErrors(newErrors);
      setShowErrorAbove(true);
      return;
    }
    setShowErrorAbove(false);
    const foundSupplierBuyer = supplierBuyers.find((supBuy) => supBuy.id === formState.buyerId);
    const data = {
      ...formState,
      jobAddress: formState.jobAddress ?? '',
      jobCity: formState.jobCity ?? '',
      jobState: formState.jobState ?? '',
      jobZipCode: formState.jobZipCode ?? '',
      buyerId: formState.buyerId ?? '',
      jobNumber: formState.jobNumber ?? '',
      name: formState.name ?? '',
      buyerName: foundSupplierBuyer?.name ?? '',
    };
    onRequestInfoSheet(data);
  };

  useEffect(() => {
    if (state === ModalState.VERIFY_OWNER || state === ModalState.VERIFY_OWNER_MANUAL) {
      onChange2({
        name: 'owner',
        value: state === ModalState.VERIFY_OWNER ? owner || '' : '',
      });
      onChange2({
        name: 'ownerAddress',
        value: state === ModalState.VERIFY_OWNER ? ownerAddress || '' : '',
      });
      onChange2({
        name: 'ownerCity',
        value: state === ModalState.VERIFY_OWNER ? ownerCity || '' : '',
      });
      onChange2({
        name: 'ownerState',
        value: state === ModalState.VERIFY_OWNER ? ownerState || '' : '',
      });
      onChange2({
        name: 'ownerZipCode',
        value: state === ModalState.VERIFY_OWNER ? ownerZipCode || '' : '',
      });
      onChange2({
        name: 'apn',
        value: state === ModalState.VERIFY_OWNER ? verifiedProperty?.apn || '' : '',
      });
      const foundSupplierBuyer = supplierBuyers.find((supBuy) => supBuy.id === formState.buyerId);
      onChange2({
        name: 'GC',
        value:
          state === ModalState.VERIFY_OWNER
            ? GC || ''
            : formState.customerType === 'GC'
            ? foundSupplierBuyer?.name || ''
            : '',
      });
      onChange2({
        name: 'GCAddress',
        value:
          state === ModalState.VERIFY_OWNER
            ? GCAddress || ''
            : formState.customerType === 'GC'
            ? foundSupplierBuyer?.address || ''
            : '',
      });
      onChange2({
        name: 'GCCity',
        value:
          state === ModalState.VERIFY_OWNER
            ? GCCity || ''
            : formState.customerType === 'GC'
            ? foundSupplierBuyer?.city || ''
            : '',
      });
      onChange2({
        name: 'GCState',
        value:
          state === ModalState.VERIFY_OWNER
            ? GCState || ''
            : formState.customerType === 'GC'
            ? foundSupplierBuyer?.state || ''
            : '',
      });
      onChange2({
        name: 'GCZipCode',
        value:
          state === ModalState.VERIFY_OWNER
            ? GCZipCode || ''
            : formState.customerType === 'GC'
            ? foundSupplierBuyer?.zipCode || ''
            : '',
      });
      onChange2({ name: 'lender', value: formState.lender || '' });
      onChange2({ name: 'lenderAddress', value: formState.lenderAddress || '' });
      onChange2({ name: 'lenderCity', value: formState.lenderCity || '' });
      onChange2({ name: 'lenderState', value: formState.lenderState || '' });
      onChange2({ name: 'lenderZipCode', value: formState.lenderZipCode || '' });
      onChange2({ name: 'jobNumber', value: jobNumber || '' });
      onChange2({ name: 'jobAddress', value: jobAddress || '' });
      onChange2({ name: 'jobCity', value: jobCity || '' });
      onChange2({ name: 'jobState', value: jobState || '' });
      onChange2({ name: 'jobZipCode', value: jobZipCode || '' });
      onChange2({ name: 'name', value: name || '' });
      onChange2({ name: 'buyerId', value: buyerId || '' });
      onChange2({
        name: 'estimatedMaterialAmount',
        value: formState.estimatedMaterialAmount || 0,
      });
      onChange2({
        name: 'jobType',
        value: state === ModalState.VERIFY_OWNER ? formState.jobType || 'commercial' : 'commercial',
      });
      onChange2({
        name: 'jobSector',
        value: state === ModalState.VERIFY_OWNER ? formState.jobSector || 'private' : 'private',
      });
      onChange2({ name: 'customerType', value: formState.customerType || 'SUB' });
      onChange2({ name: 'jobFurnishings', value: formState.jobFurnishings || '' });
      onChange2({ name: 'hasNoLender', value: formState.hasNoLender || false });
      if (editMode === 'EDIT') {
        onChange2({ name: 'jobNumber', value: formState.jobNumber || '' });
        onChange2({ name: 'jobAddress', value: formState.jobAddress || '' });
        onChange2({ name: 'jobCity', value: formState.jobCity || '' });
        onChange2({ name: 'jobState', value: formState.jobState || '' });
        onChange2({ name: 'jobZipCode', value: formState.jobZipCode || '' });
        onChange2({ name: 'name', value: formState.name || '' });
        onChange2({ name: 'buyerId', value: formState.buyerId || '' });
      }
      if (formState.jobState === 'AZ') {
        onChange2({ name: 'jobCounty', value: formState.jobCounty || '' });
        onChange2({ name: 'jobLegalDescription', value: formState.jobLegalDescription || '' });
      }
    }
  }, [state]);

  const handleOwnerAndGCSame = (value: boolean) => {
    if (value) {
      const data = {
        owner: formState.owner ? formState.owner : formState.GC,
        ownerAddress: formState.ownerAddress ? formState.ownerAddress : formState.GCAddress,
        ownerCity: formState.ownerCity ? formState.ownerCity : formState.GCCity,
        ownerState: formState.ownerState ? formState.ownerState : formState.GCState,
        ownerZipCode: formState.ownerZipCode ? formState.ownerZipCode : formState.GCZipCode,
      };
      setFormState((prevState) => ({
        ...prevState,
        ...data,
      }));
    }
    setOwnerAndGCSame(value);
  };

  useEffect(() => {
    if (supplierJobData) {
      let supplierDetails = {};
      if (editMode === 'EDIT') {
        supplierDetails = {
          jobAddress: supplierJobData.jobAddress ?? '',
          jobCity: supplierJobData.jobCity ?? '',
          jobState: getStateAbbreviation(supplierJobData.jobState || '') || '',
          jobZipCode: supplierJobData.jobZipCode ?? '',
          buyerId: supplierJobData.buyerId ?? '',
          jobNumber: supplierJobData.jobNumber ?? '',
          name: supplierJobData.name ?? '',
        };
      }
      let moreDetails = {};
      if (supplierJobData.jobState === 'AZ') {
        moreDetails = {
          jobCounty: supplierJobData.jobCounty ?? '',
          jobLegalDescription: supplierJobData.jobLegalDescription ?? '',
        };
      }
      setFormState({
        ...supplierDetails,
        apn: supplierJobData.apn,
        owner: supplierJobData.owner,
        ownerAddress: supplierJobData.ownerAddress,
        ownerCity: supplierJobData.ownerCity,
        ownerState: getStateAbbreviation(supplierJobData.ownerState || '') || '',
        ownerZipCode: supplierJobData.ownerZipCode,
        GC: supplierJobData.GC,
        GCAddress: supplierJobData.GCAddress,
        GCCity: supplierJobData.GCCity,
        GCState: getStateAbbreviation(supplierJobData.GCState || '') || '',
        GCZipCode: supplierJobData.GCZipCode,
        lender: supplierJobData.lender,
        lenderAddress: supplierJobData.lenderAddress,
        lenderCity: supplierJobData.lenderCity,
        lenderState: getStateAbbreviation(supplierJobData.lenderState || '') || '',
        lenderZipCode: supplierJobData.lenderZipCode,
        estimatedMaterialAmount: supplierJobData.estimatedMaterialAmount,
        jobType: supplierJobData.jobType,
        jobSector: supplierJobData.jobSector,
        customerType: supplierJobData.customerType,
        jobFurnishings: supplierJobData.jobFurnishings,
        hasNoLender: supplierJobData.hasNoLender,
        ...moreDetails,
      });
    }
  }, [supplierJobData]);

  const fetchedSupplierBuyers = useMemo(() => {
    if (supplierBuyers.length > 0) {
      return supplierBuyers;
    }
    return [];
  }, [supplierBuyers]);

  const [isGCAutoCompleteOpen, setIsGCAutoCompleteOpen] = useState(false);
  const [isOwnerAutoCompleteOpen, setIsOwnerAutoCompleteOpen] = useState(false);

  const [gcSuggestions, ownerSuggestions] = useMemo(() => {
    let gcData: SupplierJobApiResponse[] = [];
    let ownerData: SupplierJobApiResponse[] = [];
    if (supplierJobsMap) {
      const uniqueGCMap: { [x: string]: SupplierJobApiResponse } = {};
      Object.values(supplierJobsMap).forEach((item) => {
        if (item.GC) {
          uniqueGCMap[item.GC.toLowerCase()] = item;
        }
      });
      gcData = Object.values(uniqueGCMap);

      const uniqueOwnerMap: { [x: string]: SupplierJobApiResponse } = {};
      Object.values(supplierJobsMap).forEach((item) => {
        if (item.owner) {
          uniqueOwnerMap[item.owner.toLowerCase()] = item;
        }
      });
      ownerData = Object.values(uniqueOwnerMap);
    }
    return [gcData, ownerData];
  }, [supplierJobsMap, open]);

  const filteredGCSuggestionsOptions = gcSuggestions.filter(
    (option) => formState.GC && option.GC.toLowerCase().includes(formState.GC.toLowerCase())
  );
  const filteredOwnerSuggestionsOptions = ownerSuggestions.filter(
    (option) =>
      formState.owner && option.owner.toLowerCase().includes(formState.owner.toLowerCase())
  );

  return (
    <>
      <EditBuyerModal
        open={isEditBuyerModalOpen}
        buyer={selectedBuyer}
        handleClose={(editSuccess) => {
          if (editSuccess) {
            dispatch(getSupplierBuyers());
            if (onBuyerEdit) {
              onBuyerEdit();
            }
          }
          setIsEditBuyerModalOpen(false);
        }}
      />
      <div className={`new-job-modal-content pt-3`}>
        <div className="new-job-modal-title-container">
          <h3 className="text-lg font-semibold">Verify Job</h3>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={onClose}
          />
        </div>
        <div className="content-job !overflow-y-auto mx-6 my-3 px-4">
          {editMode === 'ADD' && (
            <VerifySupplierJob
              jobAddress={jobAddress}
              jobCity={jobCity}
              jobState={jobState}
              jobZipCode={jobZipCode}
              owner={owner}
              ownerAddress={ownerAddress}
              ownerCity={ownerCity}
              ownerState={ownerState}
              ownerZipCode={ownerZipCode}
              verifiedProperty={verifiedProperty}
              verifyJobCheckBox={verifyJobCheckBox}
              GC={GC}
              GCAddress={GCAddress}
              GCCity={GCCity}
              GCState={GCState}
              GCZipCode={GCZipCode}
            />
          )}
          {editMode === 'EDIT' && (
            <div className="flex flex-col gap-2 w-full">
              <AppDropDown2
                isRequired
                label="Customer"
                errorText={errors.buyerId ? errors.buyerId : ''}
                value={formState.buyerId || ''}
                items={[
                  ...fetchedSupplierBuyers.map((buyer) => {
                    return {
                      label: buyer.name,
                      value: buyer.id,
                      labelInElement: (
                        <div className="flex px-2 py-3 m-[-5px] items-center justify-between group relative">
                          <div className="text-sm">{`${buyer.name}`}</div>
                          <button
                            onClick={(e) => {
                              setIsEditBuyerModalOpen(true);
                              setSelectedBuyer(buyer);
                            }}
                            className="text-sm px-4 py-1 WHITE-BG font-bold TEXT_SECONDARY-CLR rounded absolute hidden group-hover:inline-block right-2">
                            Edit
                          </button>
                        </div>
                      ),
                    };
                  }),
                ]}
                onSelectChange={(item) => {
                  if (item && item?.value !== null) {
                    const foundSupplierBuyer = supplierBuyers.find(
                      (supBuy) => supBuy.id === item?.value
                    );
                    if (foundSupplierBuyer) {
                      onChange2({ name: 'buyerId', value: item.value });
                    }
                  }
                }}
              />
              <div className="flex flex-row justify-around gap-2">
                <div className="w-full">
                  <AppInputField
                    id="name"
                    label="Job Name"
                    isRequired={true}
                    value={formState.name}
                    errorText={errors.name ? errors.name : ''}
                    onTextChange={(text: string) => {
                      onChange2({ name: 'name', value: text });
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="number"
                    label="Your Job Number"
                    placeholder="12345"
                    value={formState.jobNumber}
                    onTextChange={(text) => {
                      onChange2({ name: 'jobNumber', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-full">
                  <AppInputField
                    id="address"
                    label="Job Address"
                    isRequired={true}
                    value={formState.jobAddress}
                    errorText={errors.jobAddress ? errors.jobAddress : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'jobAddress', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-around gap-2 pb-3">
                <div className="w-full">
                  <AppInputField
                    id="city"
                    label="Job City"
                    isRequired={true}
                    value={formState.jobCity}
                    errorText={errors.jobCity ? errors.jobCity : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'jobCity', value: text });
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppDropDown2
                    isRequired
                    label="Job State"
                    value={formState.jobState || null}
                    errorText={errors.jobState ? errors.jobState : ''}
                    items={[
                      ...Object.keys(allStates).map((stateName) => {
                        const key = stateName as keyof typeof allStates;
                        const stateKey = allStates[key];
                        return {
                          label: `${stateKey} - ${stateName}`,
                          value: stateKey,
                        };
                      }),
                    ]}
                    onSelectChange={(item) => {
                      if (item && item?.value !== null) {
                        const foundState = Object.values(getAllStates()).find(
                          (stateName) => stateName === item?.value
                        );
                        if (foundState) {
                          onChange2({ name: 'jobState', value: item.value });
                        }
                      }
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="zip"
                    label="Zip Code"
                    isRequired={true}
                    value={formState.jobZipCode}
                    errorText={errors.jobZipCode ? errors.jobZipCode : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'jobZipCode', value: text });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {(state === ModalState.VERIFY_OWNER || state === ModalState.VERIFY_OWNER_MANUAL) && (
            <>
              <div className="pb-3">
                <div className="text-xs mb-1 font-semibold">Job Type</div>
                <div className="flex flex-row gap-3">
                  <div className="flex  items-center">
                    <div
                      className="flex items-center mr-1 cursor-pointer"
                      onClick={() => onChange2({ name: 'jobType', value: 'commercial' })}>
                      <div
                        className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                          formState.jobType === 'commercial'
                            ? 'PRIMARY_500-BORDER'
                            : 'GREY_500-BORDER'
                        }`}>
                        <div
                          className={`flex items-center h-2 w-2 rounded-full ${
                            formState.jobType === 'commercial' ? 'PRIMARY_500-BG ' : ''
                          }`}></div>
                      </div>
                    </div>
                    <div className="text-xs">Commercial</div>
                  </div>
                  <div className="flex  items-center">
                    <div
                      className="flex items-center mr-1 cursor-pointer"
                      onClick={() => onChange2({ name: 'jobType', value: 'residential' })}>
                      <div
                        className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                          formState.jobType === 'residential'
                            ? 'PRIMARY_500-BORDER'
                            : 'GREY_500-BORDER'
                        }`}>
                        <div
                          className={`flex items-center h-2 w-2 rounded-full ${
                            formState.jobType === 'residential' ? 'PRIMARY_500-BG ' : ''
                          }`}></div>
                      </div>
                    </div>
                    <div className="text-xs">Residential</div>
                  </div>
                </div>
              </div>
              <div className="pb-3">
                <div className="text-xs mb-1 font-semibold">Job Sector</div>
                <div className="flex flex-row gap-3">
                  <div className="flex  items-center">
                    <div
                      className="flex items-center mr-1 cursor-pointer"
                      onClick={() => onChange2({ name: 'jobSector', value: 'private' })}>
                      <div
                        className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                          formState.jobSector === 'private'
                            ? 'PRIMARY_500-BORDER'
                            : 'GREY_500-BORDER'
                        }`}>
                        <div
                          className={`flex items-center h-2 w-2 rounded-full ${
                            formState.jobSector === 'private' ? 'PRIMARY_500-BG ' : ''
                          }`}></div>
                      </div>
                    </div>
                    <div className="text-xs">Private</div>
                  </div>
                  <div className="flex  items-center">
                    <div
                      className="flex items-center mr-1 cursor-pointer"
                      onClick={() => onChange2({ name: 'jobSector', value: 'public' })}>
                      <div
                        className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                          formState.jobSector === 'public'
                            ? 'PRIMARY_500-BORDER'
                            : 'GREY_500-BORDER'
                        }`}>
                        <div
                          className={`flex items-center h-2 w-2 rounded-full ${
                            formState.jobSector === 'public' ? 'PRIMARY_500-BG ' : ''
                          }`}></div>
                      </div>
                    </div>
                    <div className="text-xs">Public</div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="jobFurnishings"
                    label="Job Furnishings"
                    placeholder="Job Furnishings"
                    isRequired={true}
                    value={formState.jobFurnishings}
                    errorText={errors.jobFurnishings ? `${errors.jobFurnishings}` : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'jobFurnishings', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="estimatedMaterialAmount"
                    label="Estimated Material Amount"
                    placeholder="12345"
                    inputType="number"
                    isRequired={true}
                    value={formState.estimatedMaterialAmount}
                    errorText={
                      errors.estimatedMaterialAmount ? `${errors.estimatedMaterialAmount}` : ''
                    }
                    onTextChange={(text) => {
                      onChange2({ name: 'estimatedMaterialAmount', value: text });
                    }}
                  />
                </div>
              </div>
              {formState.jobState === 'AZ' && (
                <div className="flex flex-row  pb-3">
                  <div className="w-full">
                    <AppInputField
                      id="jobCounty"
                      label="Job County"
                      isRequired={true}
                      placeholder="Job County"
                      value={formState.jobCounty}
                      errorText={errors.jobCounty ? `${errors.jobCounty}` : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'jobCounty', value: text });
                      }}
                    />
                  </div>
                </div>
              )}
              {formState.jobState === 'AZ' && (
                <div className="flex flex-row  pb-3">
                  <div className="w-full">
                    <AppInputField
                      id="jobLegalDescription"
                      label="Job Legal Description"
                      isRequired={true}
                      placeholder="Job Legal Description"
                      value={formState.jobLegalDescription}
                      errorText={errors.jobLegalDescription ? `${errors.jobLegalDescription}` : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'jobLegalDescription', value: text });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="apn"
                    label="Property APN"
                    placeholder="12345"
                    isRequired={false}
                    value={formState.apn}
                    errorText={errors.apn ? errors.apn : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'apn', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full pb-3">
                <AppCheckBox
                  className="mr-2"
                  isChecked={formState.customerType === 'GC'}
                  onClick={(val: boolean) => {
                    onChange2({ name: 'customerType', value: val ? 'GC' : 'SUB' });
                  }}
                />
                <div className="text-black text-sm">
                  My customer is the general contractor on the project, not a subcontractor.
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full relative">
                  <div className="w-full relative">
                    <AppInputField
                      id="gc"
                      label="GC"
                      inputType="text"
                      placeholder="GC"
                      isRequired={true}
                      value={formState.GC}
                      onFocus={() => setIsGCAutoCompleteOpen(true)}
                      errorText={errors.GC ? errors.GC : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'GC', value: text });
                      }}
                    />
                    {isGCAutoCompleteOpen && filteredGCSuggestionsOptions.length > 0 && (
                      <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-md z-50">
                        <div className="p-2">
                          {filteredGCSuggestionsOptions.length > 0 ? (
                            filteredGCSuggestionsOptions.map((option) => (
                              <div
                                key={option.id}
                                onClick={() => {
                                  onChange2({ name: 'GC', value: option.GC });
                                  onChange2({ name: 'GCAddress', value: option?.GCAddress || '' });
                                  onChange2({ name: 'GCCity', value: option?.GCCity || '' });
                                  onChange2({ name: 'GCState', value: option?.GCState || '' });
                                  onChange2({ name: 'GCZipCode', value: option?.GCZipCode || '' });

                                  setIsGCAutoCompleteOpen(false);
                                }}
                                className="p-1 hover:bg-gray-100 cursor-pointer">
                                {option.GC}
                              </div>
                            ))
                          ) : (
                            <div className="p-2 text-gray-500"></div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full ">
                  <AppInputField
                    id="GCAddress"
                    label="GC Address"
                    inputType="text"
                    placeholder="GC Address"
                    isRequired={true}
                    value={formState.GCAddress}
                    errorText={errors.GCAddress ? errors.GCAddress : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'GCAddress', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="GCCity"
                    label="GC City"
                    inputType="text"
                    placeholder="San Francisco"
                    isRequired={true}
                    value={formState.GCCity}
                    errorText={errors.GCCity ? errors.GCCity : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'GCCity', value: text });
                    }}
                  />
                </div>

                <div className="spacer" />

                <div className="w-full">
                  <AppDropDown2
                    isRequired
                    label="GC State"
                    value={formState.GCState || null}
                    errorText={errors.GCState ? errors.GCState : ''}
                    items={[
                      ...Object.keys(allStates).map((stateName) => {
                        const key = stateName as keyof typeof allStates;
                        const stateKey = allStates[key];
                        return {
                          label: `${stateKey} - ${stateName}`,
                          value: stateKey,
                        };
                      }),
                    ]}
                    onSelectChange={(item) => {
                      if (item && item?.value !== null) {
                        const foundState = Object.values(getAllStates()).find(
                          (stateName) => stateName === item?.value
                        );
                        if (foundState) {
                          onChange2({ name: 'GCState', value: item.value });
                        }
                      }
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="GCZip"
                    label="GC Zip Code"
                    inputType="text"
                    placeholder="12345"
                    isRequired={true}
                    value={formState.GCZipCode}
                    errorText={errors.GCZipCode ? errors.GCZipCode : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'GCZipCode', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full pb-3">
                <AppCheckBox
                  className="mr-2"
                  isChecked={ownerAndGCSame}
                  onClick={handleOwnerAndGCSame}
                />
                <div className="text-black text-sm">Owner and GC are the same.</div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full relative">
                  <AppInputField
                    id="owner"
                    label="Owner"
                    inputType="text"
                    placeholder="Owner"
                    isRequired={true}
                    value={formState.owner}
                    errorText={errors.owner ? errors.owner : ''}
                    onFocus={() => setIsOwnerAutoCompleteOpen(true)}
                    onTextChange={(text) => {
                      onChange2({ name: 'owner', value: text });
                    }}
                  />
                  {isOwnerAutoCompleteOpen && filteredOwnerSuggestionsOptions.length > 0 && (
                    <div className="absolute w-full mt-1 bg-white border rounded-lg shadow-md z-50">
                      <div className="p-2">
                        {filteredOwnerSuggestionsOptions.length > 0 ? (
                          filteredOwnerSuggestionsOptions.map((option) => (
                            <div
                              key={option.id}
                              onClick={() => {
                                onChange2({ name: 'owner', value: option.owner });
                                onChange2({
                                  name: 'ownerAddress',
                                  value: option?.ownerAddress || '',
                                });
                                onChange2({ name: 'ownerCity', value: option?.ownerCity || '' });
                                onChange2({ name: 'ownerState', value: option?.ownerState || '' });
                                onChange2({
                                  name: 'ownerZipCode',
                                  value: option?.ownerZipCode || '',
                                });

                                setIsOwnerAutoCompleteOpen(false);
                              }}
                              className="p-1 hover:bg-gray-100 cursor-pointer">
                              {option.owner}
                            </div>
                          ))
                        ) : (
                          <div className="p-2 text-gray-500"></div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="ownerAddress"
                    label="Owner Address"
                    inputType="text"
                    placeholder="Owner Address"
                    isRequired={true}
                    value={formState.ownerAddress}
                    errorText={errors.ownerAddress ? errors.ownerAddress : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'ownerAddress', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row pb-3">
                <div className="w-full">
                  <div className="w-full">
                    <AppInputField
                      id="ownerCity"
                      label="Owner City"
                      inputType="text"
                      placeholder="San Francisco"
                      isRequired={true}
                      value={formState.ownerCity}
                      errorText={errors.ownerCity ? errors.ownerCity : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'ownerCity', value: text });
                      }}
                    />
                  </div>
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppDropDown2
                    isRequired
                    label="Owner State"
                    value={formState.ownerState || null}
                    errorText={errors.ownerState ? errors.ownerState : ''}
                    items={[
                      ...Object.keys(allStates).map((stateName) => {
                        const key = stateName as keyof typeof allStates;
                        const stateKey = allStates[key];
                        return {
                          label: `${stateKey} - ${stateName}`,
                          value: stateKey,
                        };
                      }),
                    ]}
                    onSelectChange={(item) => {
                      if (item && item?.value !== null) {
                        const foundState = Object.values(getAllStates()).find(
                          (stateName) => stateName === item?.value
                        );
                        if (foundState) {
                          onChange2({ name: 'ownerState', value: item.value });
                        }
                      }
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <div className="w-full">
                    <AppInputField
                      id="ownerZip"
                      label="Owner Zip Code"
                      inputType="text"
                      placeholder="12345"
                      isRequired={true}
                      value={formState.ownerZipCode}
                      errorText={errors.ownerZipCode ? errors.ownerZipCode : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'ownerZipCode', value: text });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-full pb-3 items-center">
                <AppCheckBox
                  className="mr-2"
                  isChecked={formState.hasNoLender}
                  onClick={(val: boolean) => {
                    onChange2({ name: 'hasNoLender', value: val });
                  }}
                />
                <div className="text-black text-sm">There is no lender on this job</div>
              </div>
              {!formState.hasNoLender && (
                <div>
                  <div className="flex flex-row  pb-3">
                    <div className="w-full">
                      <AppInputField
                        id="lender"
                        label="Lender"
                        inputType="text"
                        placeholder="Lender"
                        isRequired={!formState.hasNoLender}
                        value={formState.lender}
                        errorText={errors.lender ? errors.lender : ''}
                        onTextChange={(text) => {
                          onChange2({ name: 'lender', value: text });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row  pb-3">
                    <div className="w-full ">
                      <AppInputField
                        id="lenderAddress"
                        label="Lender Address"
                        inputType="text"
                        placeholder="Lender Address"
                        isRequired={!hasNoLender}
                        value={formState.lenderAddress}
                        errorText={errors.lenderAddress ? errors.lenderAddress : ''}
                        onTextChange={(text) => {
                          onChange2({ name: 'lenderAddress', value: text });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row  pb-3">
                    <div className="w-full ">
                      <AppInputField
                        id="lenderCity"
                        label="Lender City"
                        inputType="text"
                        placeholder="San Francisco"
                        isRequired={!formState.hasNoLender}
                        value={formState.lenderCity}
                        errorText={errors.lenderCity ? errors.lenderCity : ''}
                        onTextChange={(text) => {
                          onChange2({ name: 'lenderCity', value: text });
                        }}
                      />
                    </div>
                    <div className="spacer" />
                    <div className="w-full">
                      <AppDropDown2
                        label="Lender State"
                        isRequired={!formState.hasNoLender}
                        value={formState.lenderState || null}
                        errorText={errors.lenderState ? errors.lenderState : ''}
                        items={[
                          ...Object.keys(allStates).map((stateName) => {
                            const key = stateName as keyof typeof allStates;
                            const stateKey = allStates[key];
                            return {
                              label: `${stateKey} - ${stateName}`,
                              value: stateKey,
                            };
                          }),
                        ]}
                        onSelectChange={(item) => {
                          if (item && item?.value !== null) {
                            const foundState = Object.values(getAllStates()).find(
                              (stateName) => stateName === item?.value
                            );
                            if (foundState) {
                              onChange2({ name: 'lenderState', value: item.value });
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="spacer" />
                    <div className="w-full">
                      <AppInputField
                        id="lenderZip"
                        label="Lender Zip Code"
                        inputType="text"
                        placeholder="12345"
                        isRequired={!formState.hasNoLender}
                        value={formState.lenderZipCode}
                        errorText={errors.lenderZipCode ? errors.lenderZipCode : ''}
                        onTextChange={(text) => {
                          onChange2({ name: 'lenderZipCode', value: text });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="flex flex-col w-full mb-3 px-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          {showErrorAbove && (
            <div className="my-1 text-xs ERROR_500-CLR text-center">Please see errors above</div>
          )}
          <div className="flex flex-row  px-6 ">
            {state === ModalState.VERIFY_JOB && (
              <>
                <AppButton text="Back" type="TERTIARY" onClick={handleBack} />
                <div className="flex justify-end w-full gap-3">
                  <AppButton
                    text="Manually update information"
                    type="SECONDARY"
                    onClick={changeManualState}
                  />
                  {verifiedProperty && (
                    <AppButton text="Use this data" type="PRIMARY" onClick={changeAutoState} />
                  )}
                </div>
              </>
            )}
            {(state === ModalState.VERIFY_OWNER || state === ModalState.VERIFY_OWNER_MANUAL) && (
              <div
                className={twMerge(
                  'flex flex-row gap-3',
                  editMode === 'EDIT' && user?.userSupplier?.ownerVerificationEnabled
                    ? 'w-[460px] justify-between'
                    : 'gap-3'
                )}>
                <AppButton
                  text={editMode === 'EDIT' ? 'Close' : 'Back'}
                  type="TERTIARY"
                  onClick={() => {
                    if (editMode === 'EDIT') {
                      onClose();
                    } else {
                      handleBack();
                    }
                  }}
                />
                {editMode === 'EDIT' && (
                  <AppButton
                    text="Request Job Info Sheet"
                    type="SECONDARY"
                    buttonStyles={{
                      height: '40px',
                    }}
                    onClick={handleRequestInfoSheet}
                  />
                )}
                <div className="flex flex-row gap-3">
                  {editMode === 'EDIT' && user?.userSupplier?.ownerVerificationEnabled && (
                    <AppButton
                      text="Verify"
                      type="SECONDARY"
                      onClick={() => {
                        if (!validateInputs()) {
                          setShowErrorAbove(true);
                          return;
                        }
                        setShowErrorAbove(false);
                        onVerify(formState);
                      }}
                      isLoading={verifying}
                      isDisabled={verifying}
                      buttonStyles={{
                        width: '80px',
                        height: '40px',
                      }}
                    />
                  )}
                  <AppButton text="Save" type="PRIMARY" onClick={onSubmitData} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierVerifyJobForm;
