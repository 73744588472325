import { useEffect, useMemo, useState } from 'react';
import emptyListImg from '../../../../assets/images/emptyList.png';
import moment from 'moment';

import { roundTo } from '@dill/dill-shared';
import { CaretLeft, CaretRight, Trash } from 'phosphor-react';
import { List, AutoSizer } from 'react-virtualized';
import ReactPaginate from 'react-paginate';
import { deleteSupplierInvoice } from '../../../../redux/services/invoicesService';
import {
  getManualUploadInvoices,
  recursiveGetManualUploadInvoices,
} from '../../../../redux/services/manualUploadService';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { AppInfiniteScroll } from '../../../general/AppInfiniteScroll/AppInfiniteScroll';
import {
  deleteInforDistSXSupplierInvoice,
  getInforDistSXInvoicesService,
} from '../../../../redux/services/inforDistSXService';

export const InforDistSXInvoicesTable = () => {
  const dispatch = useAppDispatch();

  const { invoices } = useAppSelector((state) => state.inforDistSX);

  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const {
    numberOfInvoices,
    invoices: stateInvoices,
    lastRefKey,
  } = useAppSelector((state) => state.inforDistSX);

  useEffect(() => {
    dispatch(getInforDistSXInvoicesService({ itemsPerPage: 50, invoiceType: 'IN' }));
    dispatch(getInforDistSXInvoicesService({ invoiceType: 'MC' }));
    dispatch(getInforDistSXInvoicesService({ invoiceType: 'SV' }));
    dispatch(getInforDistSXInvoicesService({ invoiceType: 'DM' }));
    dispatch(getInforDistSXInvoicesService({ invoiceType: 'CM' }));
  }, []);

  const columns = useMemo(() => {
    const currentColumns = [
      { id: 'invno', name: 'Invoice #', width: 80, cellType: 'VIEW_TEXT' },
      { id: 'transcd', name: 'Type', width: 50, cellType: 'VIEW_TEXT' },
      { id: 'customer', name: 'Customer', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'invdt', name: 'Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'duedt', name: 'Due Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'terms', name: 'Terms', width: 75, cellType: 'VIEW_TEXT' },
      { id: 'amtduex', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'amountx', name: 'Open', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'action', name: '', width: 20, cellType: 'VIEW_TEXT' },
    ];

    return currentColumns;
  }, [invoices]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const handleDeleteSupplierInvoice = async (invoiceId: string, invoiceType: string) => {
    dispatch(
      openMessageModal({
        buttonText: 'Close',
        modalType: 'WARN',
        title: 'Are you sure you would like to delete this invoice?',
        subTitle:
          'Doing so will delete it from the portal as well as any associated payments that you have imported on your side.',
        secondButtonText: 'Proceed',
        isSecondButton: true,
        onSecondButtonClick: async () => {
          const res = await dispatch(deleteInforDistSXSupplierInvoice({ invoiceId, invoiceType }));
          if (res.type === 'invoice/inforDistSX/deleteSupplierInvoice') {
            dispatch(
              openMessageModal({
                buttonText: 'Close',
                modalType: 'SUCCESS',
                title: 'Invoice Deleted',
                subTitle: 'The invoice has been successfully deleted.',
              })
            );
          }
        },
      })
    );
  };

  const fetchData = async () => {
    setLoading(true);

    const filteredStateInvoicesCount = stateInvoices.filter(
      (invoice) => invoice.transcd === 'IN'
    ).length;
    console.log('filteredStateInvoicesCount', filteredStateInvoicesCount);
    console.log('numberOfInvoices', numberOfInvoices);
    console.log(stateInvoices.length);
    if (filteredStateInvoicesCount !== numberOfInvoices) {
      if (filteredStateInvoicesCount > 0) {
        await dispatch(
          getInforDistSXInvoicesService({
            itemsPerPage: 50,
            startAfter: lastRefKey,
            invoiceType: 'IN',
          })
        );
      }
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const renderData = (ref: React.RefCallback<HTMLElement>) => {
    return invoices.map((invoice, index) => {
      return (
        <div
          key={invoice.invno + index}
          ref={index === invoices.length - 1 ? ref : null}
          className={'flex  px-3 my-2 border-b border-b-gray-200'}>
          {columns.map((column) => {
            type ObjectKey = keyof typeof invoice;
            const columnKey = column.id as ObjectKey;
            let value =
              column.id === 'terms'
                ? invoice.customer.generalData.termsType?.toString() || ''
                : invoice[columnKey]?.toString();
            if (
              column.id === 'createdAt' ||
              column.id === 'dueDate' ||
              column.id === 'invoiceDate'
            ) {
              value = moment(value, 'YYYY-MM-DD').utc().format('MM/DD/YYYY');
            }
            if (column.id === 'amtduex' || column.id === 'amountx') {
              value = `$${roundTo(parseFloat(value || '0'), 2)}`;
            }

            return (
              <div
                key={column.id}
                style={{ flex: column.width / totalWidth }}
                className="flex w-full h-full ">
                {column.cellType === 'VIEW_TEXT' && column.id !== 'customer' && (
                  <p className={'text-sm '}>{value}</p>
                )}
                {column.cellType === 'VIEW_TEXT' && column.id === 'customer' && (
                  <p className={'text-sm '}>{invoice.customer.name ?? ''}</p>
                )}

                {column.cellType === 'VIEW_TEXT' && column.id === 'action' && (
                  <Trash
                    className="text-red-600 cursor-pointer"
                    onClick={() => {
                      handleDeleteSupplierInvoice(invoice.invno, invoice.transcd);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    });
  };
  return (
    <div className=" h-full overflow-y-scroll">
      <div className="flex flex-col overflow-x-scroll h-full  w-full border rounded-lg">
        <div className="flex w-full h-full flex-col overflow-y-scroll ">
          <div className="flex border-b px-5 items-center w-full sticky top-0 WHITE-BG py-3 z-10">
            {columns.map((column) => {
              return (
                <div
                  key={column.id}
                  style={{ flex: column.width / totalWidth }}
                  className="flex w-full h-full">
                  {column.cellType !== 'SELECT' && (
                    <div>
                      {column.id === 'customerName' ? (
                        <p className="TEXT_SECONDARY-CLR text-xs" style={{ width: column.width }}>
                          {column.name}
                        </p>
                      ) : (
                        <p className="TEXT_SECONDARY-CLR text-xs ">{column.name}</p>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {invoices.length < 1 && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex flex-col items-center">
                <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                <p>No invoices</p>
              </div>
            </div>
          )}
          {invoices.length > 0 && (
            <AppInfiniteScroll
              fetchData={fetchData}
              renderList={renderData}
              loading={loading}
              hasMore={hasMore}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InforDistSXInvoicesTable;
