import React, {
  CSSProperties,
  HTMLProps,
  useEffect,
  useMemo,
  useRef,
  useState,
  RefObject,
} from 'react';

import './index.css';

import {
  type Column,
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  type HeaderGroup,
  type Row,
  type Table,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer, type VirtualItem, type Virtualizer } from '@tanstack/react-virtual';
import {
  formatMoney,
  getLienDeadlineDate,
  getPreLienDeadlineDate,
  getStateAbbreviation,
  SupplierJobApiResponse,
} from '@dill/dill-shared';
import { AppButton } from '../../../general/AppButton/AppButton';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  ArrowsDownUp,
  SortDescending,
  SortAscending,
  CheckCircle,
  CircleWavyCheck,
  Warning,
  WarningCircle,
  MegaphoneSimple,
  DotsThreeVertical,
} from 'phosphor-react';
import { AlertTriangle, Edit3 } from 'react-feather';
import moment from 'moment';
import { COLORS } from '../../../../utils/colors';
import {
  selectSupplierJobs,
  unSelectSupplierJobs,
} from '../../../../redux/globalSlices/supplierJobsSlice';
import { useWindowActualWidth } from '../../../../utils/domTools';
import { AppRequestInfoJobModal } from '../../../general/AppNewSupplierJobModal/AppRequestInfoJobModal';
import { twMerge } from 'tailwind-merge';

const getCommonPinningStyles = (
  column: Column<SupplierJobApiResponse>,
  isHeader = false
): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px gray inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    backgroundColor: isHeader && isPinned ? '#F9FAFF' : !isHeader && isPinned ? 'white' : undefined,
  };
};

const IndeterminateCheckbox = ({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} className={className + ' cursor-pointer'} {...rest} />;
};

interface TableBodyRowProps {
  row: Row<SupplierJobApiResponse>;
  virtualRow: VirtualItem;
  rowVirtualizer: Virtualizer<HTMLDivElement, HTMLTableRowElement>;
  onGoToSupplierDetails: (data: SupplierJobApiResponse) => void;
}

const TableBodyRow = ({
  row,
  virtualRow,
  rowVirtualizer,
  onGoToSupplierDetails,
}: TableBodyRowProps) => {
  const width = useWindowActualWidth();
  const totalSize = row.getVisibleCells().reduce((acc, cell) => acc + cell.column.getSize(), 0);
  return (
    <tr
      data-index={virtualRow.index}
      ref={(node) => rowVirtualizer.measureElement(node)}
      key={row.id}
      className="items-center justify-between hover:bg-slate-50"
      style={{
        display: 'flex',
        position: 'absolute',
        transform: `translateY(${virtualRow.start}px)`,
        width: '100%',
        zIndex: 0,
      }}>
      {row.getVisibleCells().map((cell) => {
        const columnWidth = (cell.column.getSize() / totalSize) * width;
        return (
          <td
            onClick={(e) => {
              if (e.defaultPrevented) return;
              if (cell.column.id !== 'actions' && cell.column.id !== 'select') {
                onGoToSupplierDetails(row.original);
              }
            }}
            className={
              cell.column.id !== 'actions' && cell.column.id !== 'select' ? 'cursor-pointer' : ''
            }
            key={cell.id}
            style={{
              display: 'flex',
              ...getCommonPinningStyles(cell.column),
              width: `${columnWidth}px`,
              minWidth: cell.column.getSize(),
            }}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};

interface TableBodyProps {
  table: Table<SupplierJobApiResponse>;
  tableContainerRef: RefObject<HTMLDivElement>;
  onGoToSupplierDetails: (data: SupplierJobApiResponse) => void;
}

const TableBody = ({ table, tableContainerRef, onGoToSupplierDetails }: TableBodyProps) => {
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLTableRowElement>({
    count: rows.length,
    estimateSize: () => 33,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <tbody
      style={{
        display: 'grid',
        height: `${rowVirtualizer.getTotalSize()}px`,
        position: 'relative',
        marginBottom: '120PX',
      }}>
      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
        const row = rows[virtualRow.index] as Row<SupplierJobApiResponse>;
        return (
          <TableBodyRow
            key={row.id}
            row={row}
            virtualRow={virtualRow}
            rowVirtualizer={rowVirtualizer}
            onGoToSupplierDetails={onGoToSupplierDetails}
          />
        );
      })}
    </tbody>
  );
};

interface TableHeadRowProps {
  headerGroup: HeaderGroup<SupplierJobApiResponse>;
  onSort: (value: string) => void;
  sortDetails: { sortColumn: string; sortType: string };
}

const TableHeadRow = ({ headerGroup, onSort, sortDetails }: TableHeadRowProps) => {
  const width = useWindowActualWidth();
  const totalSize = headerGroup.headers.reduce((acc, header) => acc + header.getSize(), 0);
  return (
    <tr key={headerGroup.id} style={{ display: 'flex', width: '100%' }}>
      {headerGroup.headers.map((header) => {
        const columnWidth = (header.getSize() / totalSize) * width;
        return (
          <th
            key={header.id}
            className="PRIMARY_50-BG items-center justify-between"
            style={{
              display: 'flex',
              ...getCommonPinningStyles(header.column, true),
              width: `${columnWidth}px`,
              minWidth: header.getSize(),
            }}>
            <div
              onClick={() => onSort(header.column.id)}
              {...{
                className: header.column.getCanSort()
                  ? 'cursor-pointer select-none flex items-center'
                  : '',
              }}>
              {flexRender(header.column.columnDef.header, header.getContext())}
              {sortDetails.sortColumn === header.column.id &&
                sortDetails.sortType === 'asc' &&
                header.column.getCanSort() && (
                  <div className="h-8 w-8 ml-2 flex items-center">
                    <SortDescending color={COLORS.PRIMARY_500} size={20} />
                  </div>
                )}
              {sortDetails.sortColumn === header.column.id &&
                sortDetails.sortType === 'desc' &&
                header.column.getCanSort() && (
                  <div className="h-8 w-8 ml-2 flex items-center">
                    <SortAscending color={COLORS.PRIMARY_500} size={20} />
                  </div>
                )}
              {sortDetails.sortColumn !== header.column.id && header.column.getCanSort() && (
                <div className="h-8 w-8 ml-2 flex items-center">
                  <ArrowsDownUp color={COLORS.GREY_400} size={20} />
                </div>
              )}
              {sortDetails.sortColumn === header.column.id &&
                sortDetails.sortType === '' &&
                header.column.getCanSort() && (
                  <div className="h-8 w-8 ml-2 flex items-center">
                    <ArrowsDownUp color={COLORS.GREY_400} size={20} />
                  </div>
                )}
            </div>
          </th>
        );
      })}
    </tr>
  );
};

interface TableHeadProps {
  table: Table<SupplierJobApiResponse>;
  onSort: (value: string) => void;
  sortDetails: { sortColumn: string; sortType: string };
}

const TableHead = ({ table, onSort, sortDetails }: TableHeadProps) => {
  return (
    <thead
      style={{
        display: 'grid',
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableHeadRow
          headerGroup={headerGroup}
          key={headerGroup.id}
          onSort={onSort}
          sortDetails={sortDetails}
        />
      ))}
    </thead>
  );
};

interface DropdownButtonProps {
  onClick: ({ left, top }: { left: number; top: number }) => void;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({ onClick }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverEnd = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      onClick({ left: rect.left + 39, top: rect.top + 40 });
    } else {
      onClick({ left: 0, top: 0 });
    }
  };

  return (
    <button
      ref={buttonRef}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverEnd}
      onClick={handleClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: `${isHovered ? COLORS.GREY_100 : COLORS.PRIMARY_50}`,
        borderColor: COLORS.GREY_200,
        borderWidth: '1px',
        padding: '8px',
        height: '35px',
        width: '35px',
        margin: '0px 4px',
        borderRadius: '8px',
      }}>
      <DotsThreeVertical color={COLORS.GREY_500} size={20} weight="bold" />
    </button>
  );
};

interface VirtualizeSupplierJobTableProps {
  supplierJobs: SupplierJobApiResponse[];
  onCreateRelease: (data: SupplierJobApiResponse) => void;
  onEditSupplierJobDate: (
    data: SupplierJobApiResponse,
    date: Date | null,
    modalType: 'firstDeliveryDate' | 'lastDeliveryDate' | 'noticeOfCompletionDate'
  ) => void;
  onGoToSupplierDetails: (data: SupplierJobApiResponse) => void;
  onSort: (value: string) => void;
  sortDetails: { sortColumn: string; sortType: string };
  onOpenDropdown: (data: SupplierJobApiResponse, value: { left: number; top: number }) => void;
  dataFullyLoaded: boolean;
}

const VirtualizeSupplierJobTable = ({
  supplierJobs,
  onCreateRelease,
  onEditSupplierJobDate,
  onGoToSupplierDetails,
  onSort,
  sortDetails,
  onOpenDropdown,
  dataFullyLoaded,
}: VirtualizeSupplierJobTableProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((globalState) => globalState.auth);
  const { requestedSupplierJobs } = useAppSelector(
    (globalState) => globalState.requestJobInformation
  );

  const [isAppRequestInfoJobModalOpen, setIsAppRequestInfoJobModalOpen] = useState(false);

  const [selectedJob, setSelectedJob] = useState<SupplierJobApiResponse | null>(null);

  const handleEditSupplierJobDate = (
    data: SupplierJobApiResponse,
    date: Date | null,
    modalType: 'firstDeliveryDate' | 'lastDeliveryDate' | 'noticeOfCompletionDate'
  ) => {
    onEditSupplierJobDate(data, date, modalType);
  };

  const convertDateToPST = (date: Date) => {
    const dtString = moment(date);
    return dtString.toDate();
  };

  const getDeliveryDate = (
    softDeliveryDt: Date | null | undefined,
    invoiceDeliveryDate: Date | null | undefined
  ) => {
    let dt = null;

    if (invoiceDeliveryDate) {
      dt = convertDateToPST(invoiceDeliveryDate);
    } else if (softDeliveryDt) {
      dt = convertDateToPST(softDeliveryDt);
    }

    return dt;
  };

  const getDeliveryDateString = (
    softDeliveryDt: Date | null | undefined,
    invoiceDeliveryDate: Date | null | undefined
  ) => {
    if (softDeliveryDt) {
      return moment(softDeliveryDt).utc().format('MM/DD/YYYY');
    }
    const dt = getDeliveryDate(softDeliveryDt, invoiceDeliveryDate);

    return dt ? moment(dt).format('MM/DD/YYYY') : '';
  };

  const columns = useMemo<ColumnDef<SupplierJobApiResponse>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <div className="px-1.5 mt-1">
            {dataFullyLoaded && (
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  className: 'h-5 w-5 border-2 GREY_100-BORDER rounded-md ',
                  onChange: (e) => {
                    table.getToggleAllRowsSelectedHandler()(e);
                    const isChecked = (e.target as HTMLInputElement).checked;
                    if (isChecked) {
                      dispatch(selectSupplierJobs(supplierJobs));
                    } else {
                      dispatch(unSelectSupplierJobs(supplierJobs));
                    }
                  },
                }}
              />
            )}
          </div>
        ),
        cell: ({ row }) => (
          <div className="px-1 mt-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: (e) => {
                  row.getToggleSelectedHandler()(e);
                  const isChecked = (e.target as HTMLInputElement).checked;
                  if (isChecked) {
                    dispatch(selectSupplierJobs([row.original]));
                  } else {
                    dispatch(unSelectSupplierJobs([row.original]));
                  }
                },
                className: 'h-5 w-5 border-2 GREY_100-BORDER rounded-md ',
              }}
            />
          </div>
        ),
        size: 40,
      },
      {
        accessorFn: (row) => row.name,
        id: 'supplierJob',
        cell: ({ row }) => (
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <p
                data-tooltip-id={`job-tooltip-${row.original.id}`}
                className="text-sm capitalize font-bold app-line-clamp-2">
                {row.original.name}
              </p>
              <ReactTooltip
                id={`job-tooltip-${row.original.id}`}
                place={row.index === 0 ? 'left' : 'top'}
                variant="dark"
                opacity={'100%'}
                style={{
                  background: '#222',
                  zIndex: 99999,
                }}>
                <div className="flex flex-col">
                  <span className="text-xs capitalize overflow-hidden text-ellipsis">
                    {row.original.jobNumber ? `${row.original.jobNumber} - ` : ''}
                  </span>
                  <span className="text-xs capitalize">{row.original.name || ''}</span>
                </div>
              </ReactTooltip>
            </div>

            {row.original.constructionMonitorPermitId && (
              <>
                <div className="flex flex-col items-center pt-1">
                  <CircleWavyCheck color="#0BA5EC" />
                </div>
              </>
            )}
          </div>
        ),
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Job</span>,
        size: 180,
      },
      {
        accessorFn: (row) => row?.buyer?.name ?? '',
        id: 'customer',
        cell: ({ row }) => (
          <div className="w-full flex flex-col">
            <p
              data-tooltip-id={`buyer-tooltip-customer-${row.original.id}`}
              className="text-sm capitalize app-line-clamp-2">
              {`${row.original.buyer?.name ? `${row.original.buyer?.name} - ` : ''} ${
                row.original.buyer?.address || ''
              } ${row.original.buyer?.city ? `${row.original.buyer?.city}, ` : ''}${
                row.original.buyer?.state || ''
              } ${row.original.buyer?.zipCode || ''}`}
            </p>
            <ReactTooltip
              id={`buyer-tooltip-customer-${row.original.id}`}
              place={row.index === 0 ? 'left' : 'top'}
              variant="dark"
              opacity={'100%'}
              style={{
                background: '#222',
                zIndex: 99999,
              }}>
              <div className="flex flex-col">
                <span className="text-xs capitalize">{row.original.buyer?.name || ''}</span>
                <span className="text-xs capitalize overflow-hidden text-ellipsis">
                  {`${row.original.buyer?.address || ''}`}
                </span>
                <span className="text-xs capitalize overflow-hidden text-ellipsis">
                  {`${row.original.buyer?.city ? `${row.original.buyer?.city}, ` : ''}${
                    row.original.buyer?.state || ''
                  } ${row.original.buyer?.zipCode || ''}`}
                </span>
              </div>
            </ReactTooltip>
          </div>
        ),
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Customer</span>,
        size: 180,
      },
      {
        accessorFn: (row) => row.jobAddress,
        id: 'fullAddress',
        cell: ({ row }) => (
          <div className="w-full flex flex-col">
            <p
              data-tooltip-id={`fullAddress-tooltip-${row.original.id}`}
              className="text-sm capitalize app-line-clamp-2">
              {`${row.original.jobAddress || ''} ${
                row.original.jobCity ? `${row.original.jobCity}, ` : ''
              }${row.original.jobState || ''} ${row.original.jobZipCode || ''}`}
            </p>
            <ReactTooltip
              id={`fullAddress-tooltip-${row.original.id}`}
              place={row.index === 0 ? 'left' : 'top'}
              variant="dark"
              opacity={'100%'}
              style={{
                background: '#222',
                zIndex: 99999,
              }}>
              <div className="flex flex-col">
                <span className="text-xs capitalize">{row.original.jobAddress || ''}</span>
                <span className="text-xs capitalize">{`${
                  row.original.jobCity ? `${row.original.jobCity}, ` : ''
                }${row.original.jobState || ''} ${row.original.jobZipCode || ''}`}</span>
              </div>
            </ReactTooltip>
          </div>
        ),
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Full Address</span>,
        size: 180,
      },
      {
        accessorFn: (row) => {
          const finalPreLienStatus = row.preLienNotice ? 'done' : 'not-yet';
          return finalPreLienStatus ?? '';
        },
        id: 'prelien',
        cell: ({ row }) => {
          const finalPreLienStatus = row.original.preLienNotice ? 'done' : 'not-yet';
          return (
            <div className="w-full flex flex-col max-w-[90px]">
              {finalPreLienStatus === 'not-yet' ? (
                <div className="flex ERROR_500-BG text-sm WHITE-CLR font-semibold px-2 py-1 rounded-full items-center">
                  <Warning size={16} color={COLORS.WHITE} />
                  <div className="ml-2 text-xs">Not Yet</div>
                </div>
              ) : (
                <div className="flex SUCCESS_500-BG text-sm WHITE-CLR font-semibold px-2 py-1 rounded-full items-center">
                  <CheckCircle size={16} color={COLORS.WHITE} />
                  <div className="ml-2 text-xs">Done</div>
                </div>
              )}
            </div>
          );
        },
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Pre-Lien</span>,
        size: 100,
      },
      {
        accessorFn: (row) => {
          const supplierJobState = getStateAbbreviation(row.jobState) ?? 'CA';
          const state =
            row?.supplierId === 'krAwPCRGqgEX0CZhRZva'
              ? 'CA'
              : !row.jobState && row?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
              ? 'CA'
              : supplierJobState;
          const firstDeliveryDate = getDeliveryDate(row.firstInvoiceDate, row.firstDeliveryDate);
          const preLienDateDeadlineDate = getPreLienDeadlineDate(state, firstDeliveryDate);
          return preLienDateDeadlineDate ? preLienDateDeadlineDate.getTime() : '';
        },
        id: 'prelienDeadline',
        cell: ({ row }) => {
          const supplierJobState = getStateAbbreviation(row.original.jobState) ?? 'CA';
          const state =
            row.original?.supplierId === 'krAwPCRGqgEX0CZhRZva'
              ? 'CA'
              : !row.original.jobState && row.original?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
              ? 'CA'
              : supplierJobState;
          const firstDeliveryDate = getDeliveryDate(
            row.original.firstInvoiceDate,
            row.original.firstDeliveryDate
          );
          const preLienDateDeadlineDate = getPreLienDeadlineDate(state, firstDeliveryDate);
          const isPreLienDateDeadlinePassed = preLienDateDeadlineDate
            ? moment().isAfter(preLienDateDeadlineDate)
            : false;
          const preLienDateDeadlineDateString =
            preLienDateDeadlineDate &&
            preLienDateDeadlineDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            });
          return (
            <div
              className={` flex flex-col w-full text-sm ${
                preLienDateDeadlineDate && isPreLienDateDeadlinePassed ? 'text-red-500' : ''
              }`}>
              <div className="flex flex-row align-center gap-1 font-semibold">
                <div className="flex flex-col justify-center">
                  {preLienDateDeadlineDate && isPreLienDateDeadlinePassed ? (
                    <WarningCircle
                      data-tooltip-id={`prelienDeadline-tooltip-${row.original.id}`}
                      size={16}
                    />
                  ) : (
                    ''
                  )}
                </div>
                {preLienDateDeadlineDateString}
              </div>
              <ReactTooltip
                id={`prelienDeadline-tooltip-${row.original.id}`}
                place={row.index === 0 ? 'left' : 'top'}
                variant="dark"
                opacity={'100%'}
                style={{
                  background: '#222',
                  zIndex: 99999,
                }}>
                <div className="flex flex-col">
                  <span className="text-xs capitalize">Overdue</span>
                </div>
              </ReactTooltip>
            </div>
          );
        },
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Pre-Lien Deadline</span>,
        size: 140,
      },
      {
        accessorFn: (row) => {
          const lastDeliveryDate = getDeliveryDate(row.lastInvoiceDate, row.lastDeliveryDate);
          const supplierJobState = getStateAbbreviation(row.jobState) ?? 'CA';
          const state =
            row?.supplierId === 'krAwPCRGqgEX0CZhRZva'
              ? 'CA'
              : !row.jobState && row?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
              ? 'CA'
              : supplierJobState;
          const lienDateDeadlineDate = getLienDeadlineDate(
            state,
            lastDeliveryDate,
            row.noticeOfCompletionDate,
            row?.jobSector ?? 'private'
          );
          return lienDateDeadlineDate ? lienDateDeadlineDate.getTime() : '';
        },
        id: 'lienDeadline',
        cell: ({ row }) => {
          const lastDeliveryDate = getDeliveryDate(
            row.original.lastInvoiceDate,
            row.original.lastDeliveryDate
          );
          const supplierJobState = getStateAbbreviation(row.original.jobState) ?? 'CA';
          const state =
            row.original?.supplierId === 'krAwPCRGqgEX0CZhRZva'
              ? 'CA'
              : !row.original.jobState && row.original?.supplierId === 'ddEOEMrTjpVr7aj4DQjD'
              ? 'CA'
              : supplierJobState;
          const lienDateDeadlineDate = getLienDeadlineDate(
            state,
            lastDeliveryDate,
            row.original.noticeOfCompletionDate,
            row.original?.jobSector ?? 'private'
          );
          const isLienDateDeadlinePassed = lienDateDeadlineDate
            ? moment().isAfter(lienDateDeadlineDate)
            : false;
          const totalOpenAR =
            row.original.invoices?.reduce((acc, invoice) => {
              return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
            }, 0) || 0;
          const lienDeadlineDateString = lienDateDeadlineDate
            ? lienDateDeadlineDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            : '';
          return (
            <div
              className={` flex flex-col w-full text-sm ${
                lienDateDeadlineDate && isLienDateDeadlinePassed && totalOpenAR > 0
                  ? 'text-red-500'
                  : 'GREY_500'
              }`}>
              <div className="flex flex-row align-center gap-1 font-semibold">
                <div className="flex flex-col justify-center">
                  {lienDateDeadlineDate && isLienDateDeadlinePassed && totalOpenAR > 0 ? (
                    <WarningCircle
                      data-tooltip-id={`lienDeadline-tooltip-${row.original.id}`}
                      size={16}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                {lienDeadlineDateString}
              </div>
              <ReactTooltip
                id={`lienDeadline-tooltip-${row.original.id}`}
                place={row.index === 0 ? 'left' : 'top'}
                variant="dark"
                opacity={'100%'}
                style={{
                  background: '#222',
                  zIndex: 99999,
                }}>
                <div className="flex flex-col">
                  <span className="text-xs capitalize">Overdue</span>
                </div>
              </ReactTooltip>
            </div>
          );
        },
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Lien Deadline</span>,
        size: 140,
      },
      {
        accessorFn: (row) => {
          return (
            row?.invoices?.reduce((acc, invoice) => {
              return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
            }, 0) || 0
          );
        },
        id: 'openAR',
        cell: ({ row }) => {
          const totalOpenAR =
            row.original?.invoices?.reduce((acc, invoice) => {
              return acc + (typeof invoice.balance === 'number' ? invoice.balance : 0);
            }, 0) || 0;
          return (
            <div className=" flex flex-col w-full">
              <div className="flex flex-row align-center gap-1 GREY_500 text-sm">
                <div className="flex flex-col justify-center">
                  {totalOpenAR > 0 ? formatMoney(totalOpenAR, 2) : ''}
                </div>
              </div>
            </div>
          );
        },
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Open AR</span>,
        size: 120,
      },
      {
        accessorFn: (row) => {
          const finalPreLienStatus = row.jobInfoSheetRequestDate ? 'done' : 'not-yet';
          return finalPreLienStatus ?? '';
        },
        id: 'infoSheetStatus',
        cell: ({ row }) => {
          const jobDetails = row.original;
          let status = 'Not Yet';
          if (jobDetails?.jobInfoSheetRequestDate && !jobDetails?.jobInfoSheetSubmittedDate) {
            status = 'Requested';
          }
          if (jobDetails?.jobInfoSheetRequestDate && jobDetails?.jobInfoSheetSubmittedDate) {
            const diff = moment(jobDetails?.jobInfoSheetRequestDate).diff(
              moment(jobDetails?.jobInfoSheetSubmittedDate)
            );
            if (diff > 0) {
              status = 'Requested';
            } else {
              status = 'Submitted';
            }
          }

          return (
            <div
              className="w-full flex flex-col max-w-[95px]"
              onClick={(e) => {
                setSelectedJob(jobDetails);
                setIsAppRequestInfoJobModalOpen(true);
                e.stopPropagation();
              }}>
              {status === 'Requested' ? (
                <div
                  className="flex PRIMARY_500-BG  WHITE-CLR font-semibold px-1 py-1 rounded-full items-center"
                  data-tooltip-id={`infoSheetStatus-tooltip-${row.original.id}`}>
                  <WarningCircle size={18} color={COLORS.WHITE} />
                  <div className="ml-1 text-xs w-4/5">{status}</div>
                </div>
              ) : status === 'Submitted' ? (
                <div
                  className="flex SUCCESS_500-BG text-sm WHITE-CLR font-semibold px-1 py-1 rounded-full items-center"
                  data-tooltip-id={`infoSheetStatus-tooltip-${row.original.id}`}>
                  <CheckCircle size={18} color={COLORS.WHITE} />
                  <div className="ml-1 text-xs">{status}</div>
                </div>
              ) : (
                <div className="flex ERROR_500-BG text-sm WHITE-CLR font-semibold px-2 py-1 rounded-full items-center">
                  <Warning size={16} color={COLORS.WHITE} />
                  <div className="ml-2 text-xs">{status}</div>
                </div>
              )}

              <ReactTooltip
                id={`infoSheetStatus-tooltip-${row.original.id}`}
                place={row.index <= 1 ? 'left' : 'top'}
                variant="dark"
                opacity={'100%'}
                style={{
                  background: '#222',
                  zIndex: 99999,
                  maxWidth: '300px',
                }}>
                <div className="flex flex-col">
                  {status === 'Submitted' && (
                    <span className="text-xs capitalize">{`Submitted on ${moment(
                      jobDetails?.jobInfoSheetSubmittedDate
                    ).format('MM/DD/YYYY')}`}</span>
                  )}
                  {status === 'Requested' && (
                    <span className="text-xs ">{`Requested on ${moment(
                      jobDetails?.jobInfoSheetRequestDate
                    ).format('MM/DD/YYYY')} ${
                      jobDetails?.jobInfoSheetEmails
                        ? `to ${jobDetails?.jobInfoSheetEmails.join(', ')}`
                        : ''
                    }`}</span>
                  )}
                </div>
              </ReactTooltip>
            </div>
          );
        },
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Job Info Sheet</span>,
        size: 130,
      },
      {
        accessorFn: (row) => {
          const firstDeliveryDate = getDeliveryDate(row.firstInvoiceDate, row.firstDeliveryDate);
          return firstDeliveryDate ? firstDeliveryDate.getTime() : '';
        },
        id: 'firstDeliveryDate',
        cell: ({ row }) => {
          const firstDeliveryDate = getDeliveryDate(
            row.original.firstInvoiceDate,
            row.original.firstDeliveryDate
          );
          return (
            <div className="w-full flex flex-col mr-2">
              <div className="flex flex-row gap-1 items-center justify-end text-sm ">
                {firstDeliveryDate && !row.original.firstDeliveryDate && (
                  <div
                    data-tooltip-id="soft-delivery-date-tooltip"
                    className="w-20px h-20px flex justify-center items-center rounded-full">
                    <AlertTriangle size={16} color="#FDB022" />
                  </div>
                )}
                {
                  <>
                    {getDeliveryDateString(
                      row.original.firstInvoiceDate,
                      row.original.firstDeliveryDate
                    ) || ''}
                  </>
                }
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    const firstDeliveryDt = getDeliveryDate(
                      row.original.firstInvoiceDate,
                      row.original.firstDeliveryDate
                    );
                    handleEditSupplierJobDate(row.original, firstDeliveryDt, 'firstDeliveryDate');
                  }}>
                  <Edit3 size={16} />
                </button>
              </div>
            </div>
          );
        },
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">First Furnishing Date</span>,
        size: 140,
      },
      {
        accessorFn: (row) => {
          const firstDeliveryDate = getDeliveryDate(row.lastInvoiceDate, row.lastDeliveryDate);
          return firstDeliveryDate ? firstDeliveryDate.getTime() : null;
        },
        id: 'lastDeliveryDate',
        cell: ({ row }) => {
          return (
            <div className="w-full flex flex-col mr-2">
              <div className="flex flex-row gap-1 items-center justify-end text-sm mr-1">
                {getDeliveryDate(row.original.lastInvoiceDate, row.original.lastDeliveryDate) &&
                  !row.original.lastDeliveryDate && (
                    <div
                      data-tooltip-id="soft-delivery-date-tooltip"
                      className="w-20px h-20px flex justify-center items-center rounded-full">
                      <AlertTriangle size={16} color="#FDB022" />
                    </div>
                  )}
                {getDeliveryDateString(
                  row.original.lastInvoiceDate,
                  row.original.lastDeliveryDate
                ) || ''}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    const lastDeliveryDt = getDeliveryDate(
                      row.original.lastInvoiceDate,
                      row.original.lastDeliveryDate
                    );
                    handleEditSupplierJobDate(row.original, lastDeliveryDt, 'lastDeliveryDate');
                  }}>
                  <Edit3 size={16} />
                </button>
              </div>
            </div>
          );
        },
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Last Furnishing Date</span>,
        size: 140,
      },
      {
        accessorFn: (row) => {
          const processedNoticeOfCompletionDate =
            row.noticeOfCompletionDate && typeof row.noticeOfCompletionDate === 'string'
              ? new Date(row.noticeOfCompletionDate)
              : row.noticeOfCompletionDate;
          return processedNoticeOfCompletionDate
            ? new Date(processedNoticeOfCompletionDate).getTime()
            : null;
        },
        id: 'noticeOfCompletionDate',
        cell: ({ row }) => {
          const processedNoticeOfCompletionDate =
            row.original.noticeOfCompletionDate &&
            typeof row.original.noticeOfCompletionDate === 'string'
              ? new Date(row.original.noticeOfCompletionDate)
              : row.original.noticeOfCompletionDate;
          return (
            <div className="w-full flex flex-col mr-2">
              <div className="flex flex-row gap-1 items-center justify-end text-sm mr-1">
                {processedNoticeOfCompletionDate &&
                  processedNoticeOfCompletionDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditSupplierJobDate(
                      row.original,
                      processedNoticeOfCompletionDate ?? null,
                      'noticeOfCompletionDate'
                    );
                  }}>
                  <Edit3 size={16} />
                </button>
              </div>
            </div>
          );
        },
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Notice of Completion Date</span>,
        size: 150,
      },
      {
        accessorFn: (row) => row.owner,
        id: 'owner',
        cell: ({ row }) => (
          <div className="w-full flex flex-col">
            <p
              data-tooltip-id={`owner-tooltip-${row.original.id}`}
              className="text-sm capitalize app-line-clamp-2">
              {`${row.original.owner ? `${row.original.owner} - ` : ''} ${
                row.original.ownerAddress || ''
              } ${row.original.ownerCity ? `${row.original.ownerCity}, ` : ''}${
                row.original.ownerState || ''
              } ${row.original.ownerZipCode || ''}`}
            </p>
            <ReactTooltip
              id={`owner-tooltip-${row.original.id}`}
              place={row.index === 0 ? 'left' : 'top'}
              variant="dark"
              opacity={'100%'}
              style={{
                background: '#222',
                zIndex: 99999,
              }}>
              <div className="flex flex-col">
                <span className="text-xs capitalize">{row.original.owner}</span>
                <span className="text-xs capitalize">{row.original.ownerAddress || ''}</span>
                <span className="text-xs capitalize">{`${
                  row.original.ownerCity ? `${row.original.ownerCity}, ` : ''
                }${row.original.ownerState || ''} ${row.original.ownerZipCode || ''}`}</span>
              </div>
            </ReactTooltip>
          </div>
        ),
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Owner</span>,
        size: 180,
      },
      {
        accessorFn: (row) => row.GC,
        id: 'gc',
        cell: ({ row }) => (
          <div className="w-full flex flex-col">
            <p
              data-tooltip-id={`gc-tooltip-${row.original.id}`}
              className="text-sm capitalize app-line-clamp-2">
              {`${row.original.GC ? `${row.original.GC} - ` : ''} ${row.original.GCAddress || ''} ${
                row.original?.GCCity ? `${row.original?.GCCity}, ` : ''
              }${row.original?.GCState || ''} ${row.original?.GCZipCode || ''}`}
            </p>
            <ReactTooltip
              id={`gc-tooltip-${row.original.id}`}
              place={row.index === 0 ? 'left' : 'top'}
              variant="dark"
              opacity={'100%'}
              style={{
                background: '#222',
                zIndex: 99999,
              }}>
              <div className="flex flex-col">
                <span className="text-xs capitalize">{row.original?.GC || ''}</span>
                <span className="text-xs capitalize">{`${row.original?.GCAddress || ''}`}</span>
                <span className="text-xs capitalize">{`${
                  row.original?.GCCity ? `${row.original?.GCCity}, ` : ''
                }${row.original?.GCState || ''} ${row.original?.GCZipCode || ''}`}</span>
              </div>
            </ReactTooltip>
          </div>
        ),
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">GC</span>,
        size: 180,
      },
      {
        accessorFn: (row) => row.estimatedMaterialAmount,
        id: 'estimatedMaterialAmount',
        cell: ({ row }) => (
          <div className=" flex flex-col w-full">
            <div className="flex flex-row align-center gap-1 GREY_500 text-sm">
              <div className="flex flex-col justify-center">
                {row.original?.estimatedMaterialAmount
                  ? formatMoney(row.original?.estimatedMaterialAmount, 2)
                  : ''}
              </div>
            </div>
          </div>
        ),
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Estimated Material Amount</span>,
        size: 140,
      },
      {
        accessorFn: (row) =>
          row.archivedState?.includes('SUPPLIER_ARCHIVED') ? 'Inactive' : 'Active',
        id: 'status',
        cell: ({ row }) => {
          const status = row.original.archivedState?.includes('SUPPLIER_ARCHIVED')
            ? 'Inactive'
            : 'Active';
          return (
            <div>
              <div
                className={twMerge(
                  'text-xs capitalize font-bold py-1.5 px-3 rounded-full w-fit text-center flex items-center',
                  status === 'Active'
                    ? 'SUCCESS_100-BG SUCCESS_500-CLR'
                    : 'WARNING_100-BG WARNING_500-CLR'
                )}>
                {status === 'Active' && <CheckCircle size={15} weight="bold" />}
                {status === 'Inactive' && <Warning size={15} weight="bold" />}
                <p className="ml-2"> {status}</p>
              </div>
            </div>
          );
        },
        header: () => <span className="text-xs TEXT_SECONDARY-CLR">Status</span>,
        size: 140,
      },
      {
        id: 'action',
        cell: ({ row, table }) => {
          return (
            <div
              className={`relative flex w-full min-w-[200px] items-center h-[52px] WHITE-BG pr-4`}
              onClick={(e) => {
                e.preventDefault();
              }}>
              <DropdownButton onClick={(value) => onOpenDropdown(row.original, value)} />
              <AppButton
                buttonStyles={{ width: '100%', marginLeft: '8px', height: '35px' }}
                text="Create Lien Waiver"
                type="PRIMARY"
                onClick={() => {
                  onCreateRelease(row.original);
                }}
              />
            </div>
          );
        },
        header: () => <span></span>,
        size: 240,
      },
    ],
    [supplierJobs]
  );

  const data = useMemo(() => supplierJobs, [supplierJobs]);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      columnPinning: {
        left: [],
        right: ['action'],
      },
    },
    debugTable: true,
  });

  return (
    <div>
      <AppRequestInfoJobModal
        viewState={'REQUEST_JOB_INFO_SHEET'}
        open={isAppRequestInfoJobModalOpen}
        selectedJob={selectedJob}
        handleClose={() => {
          setSelectedJob(null);
          setIsAppRequestInfoJobModalOpen(false);
        }}
      />
      <div
        className="supplier-jobs-virtualized-container"
        ref={tableContainerRef}
        style={{
          overflow: 'auto',
          position: 'relative',
          height: `${
            requestedSupplierJobs.length > 0 ? 'calc(100vh - 270px)' : 'calc(100vh - 240px)'
          }`,
        }}>
        <table style={{ display: 'grid' }}>
          <TableHead table={table} onSort={onSort} sortDetails={sortDetails} />
          <TableBody
            table={table}
            tableContainerRef={tableContainerRef}
            onGoToSupplierDetails={onGoToSupplierDetails}
          />
        </table>
      </div>
    </div>
  );
};

export default VirtualizeSupplierJobTable;
