import { Viewer, ProgressBar, DocumentLoadEvent } from '@react-pdf-viewer/core';
import {
  RenderCurrentPageLabelProps,
  pageNavigationPlugin,
} from '@react-pdf-viewer/page-navigation';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { AppButton } from '../../../general/AppButton/AppButton';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { AppDropDown } from '../../../general/AppDropDown/AppDropDown';
import { AppDropDown2 } from '../../../general/AppDropDown2/AppDropDown2';
import { AppCheckBox } from '../../../general/AppCheckBox/AppCheckBox';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import moment from 'moment';
import AppDatePicker from '../../../general/AppDatePicker/AppDatePicker';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  PreliminaryNoticeFieldData,
  NoticeTemplate,
  NoticeTemplateField,
  PDFFieldNoticeTemplate,
  FromFieldTypeNoticeTemplate,
  SupplierJobApiResponse,
  formatMoney,
} from '@dill/dill-shared';
import {
  removePDFFields,
  populatePagesPdfWithFieldsData,
  structureTemplateNewFieldFromTypePDFData,
  structureTemplateNonNewFieldFromTypePDFData,
  downloadFile,
  flattenPDF,
} from '../../../../utils/helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import { COLORS } from '../../../../utils/colors';
import successChecksCircle from '../../../../assets/svg/success_checks_circle.svg';
import { useTrivialValueDebounce } from '../../../../utils/hooks/useDebounce';
import { getNoticeTemplates } from '../../../../redux/services/noticeTemplatesService';
import { getSupplierJobs } from '../../../../redux/services/supplierJobsService';
import { createPreliminaryNotice } from '../../../../redux/services/preliminaryNoticesService';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { CaretCircleLeft, CaretCircleRight } from 'phosphor-react';
import { AppSuccessModal } from '../../../general/AppSuccessModal/AppSuccessModal';
import CreateSignatureModal from '../../lienWaivers/supplier/CreateSignatureModal/CreateSignatureModal';
import {
  createUserSignature,
  deleteUserSignature,
  getUserDetails,
  getUserSignatures,
  updateUserDetails,
} from '../../../../redux/services/authService';
import { AppNewSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppNewSupplierJobModal';

const AddPreliminaryNotice = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = useLocation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { user, userSignatures } = useAppSelector((globalState) => globalState.auth);
  const { noticeTemplates } = useAppSelector((globalState) => globalState.noticeTemplates);
  const { supplierJobsNoInvoicesMap } = useAppSelector((globalState) => globalState.supplierJobs);

  const [selectedTemplate, setSelectedTemplate] = useState<NoticeTemplate | null>(null);
  const [selectedTemplateFields, setSelectedTemplateFields] = useState<
    NoticeTemplateField[] | null
  >(null);
  const [selectedJob, setSelectedJob] = useState<SupplierJobApiResponse | null>(null);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel, jumpToNextPage, jumpToPreviousPage } = pageNavigationPluginInstance;

  const [currentPdfUrl, setCurrentPdfUrl] = useState('');
  const [flattenedPdfUrl, setFlattenedPdfUrl] = useState('');
  const [debouncedPopulatePdfChange, triggerDebouncedPopulatePdf] = useTrivialValueDebounce(200);
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formFieldsData, setFormFieldsData] = useState<{
    [x: string]: PreliminaryNoticeFieldData;
  }>({});
  const [isPrelimanaryNoticeCreateSuccessModalOpen, setIsPrelimanaryNoticeCreateSuccessModalOpen] =
    useState(false);
  const [isCreateSignatureModalOpen, setIsCreateSignatureModalOpen] = useState(false);
  const [signature, setSignature] = useState('');
  const [manualSign, setManualSign] = useState(false);
  const [defaultIsManualSign, setDefaultIsManualSign] = useState(false);
  const [missingJobDetails, setMissingJobDetails] = useState(false);
  const [openEditJob, setOpenEditJob] = useState(false);
  const [dataFullyLoaded, setDataFullyLoaded] = useState<boolean>(false);
  const [missingJobDetailsList, setMissingJobDetailsList] = useState<string[]>([]);

  const templates = useMemo(() => {
    if (noticeTemplates.length > 0) {
      const processedNoticeTemplates = noticeTemplates.filter(
        (template) => template.status === 'processed'
      );
      return processedNoticeTemplates;
    }
    return [];
  }, [noticeTemplates]);
  const filteredTemplates = useMemo(() => {
    let templatesFiltered: NoticeTemplate[] = [];
    if (templates.length > 0 && selectedJob) {
      templatesFiltered = templates.filter((template) => {
        if (
          template?.state &&
          selectedJob?.jobState &&
          template.state.toUpperCase() === selectedJob.jobState.toUpperCase()
        ) {
          return true;
        }
        return false;
      });
    }
    if (templatesFiltered.length > 0 && location.pathname.split('/').length !== 5) {
      const template = templatesFiltered[0];
      setSelectedTemplate(template);
      if (template.originalPdf?.url) {
        setCurrentPdfUrl(template.originalPdf?.url);
      }
    }
    return templatesFiltered;
  }, [templates, selectedJob]);

  const recursiveFetchSupplierJob = async ({
    lastDockId,
    fetchJobs,
  }: {
    lastDockId: string;
    fetchJobs: SupplierJobApiResponse[];
  }) => {
    const pageSize = 100;
    let currentPreviousLastDocumentId = lastDockId;
    setDataFullyLoaded(false);

    const results = await dispatch(
      getSupplierJobs({
        includeInvoices: false,
        includePreLiens: false,
        previousLastDocumentId: currentPreviousLastDocumentId,
        pageSize,
        sortOrder: 'asc',
        sortBy: 'name',
        isHideLoadingIndicator: true,
      })
    );
    if (results.type === 'supplierJobs/getSupplierJobs/fulfilled') {
      if (results.payload) {
        const payload = results.payload as any;
        const fetchedSupplierJobs: SupplierJobApiResponse[] = payload.data as any;
        const lastDocumentId = payload.lastDocumentId;
        if (
          lastDocumentId === currentPreviousLastDocumentId ||
          fetchedSupplierJobs.length === pageSize
        ) {
          currentPreviousLastDocumentId = lastDocumentId;
          await recursiveFetchSupplierJob({
            lastDockId: lastDocumentId,
            fetchJobs: [...fetchJobs, ...fetchedSupplierJobs],
          });
        } else {
          console.log('No more pages to fetch');
          setDataFullyLoaded(true);
        }
      }
    } else {
      console.log('Fetch failed or interrupted');
      setDataFullyLoaded(true);
    }
  };

  const fetchData = async () => {
    await dispatch(getNoticeTemplates());
    recursiveFetchSupplierJob({ lastDockId: '', fetchJobs: [] });
    await dispatch(getUserSignatures());
  };

  useEffect(() => {
    fetchData();

    return () => {};
  }, []);

  const checkMissingJobDetails = (job: SupplierJobApiResponse | null) => {
    if (job) {
      const detailsMissingList: string[] = [];
      if (
        !job?.owner ||
        job?.owner === '' ||
        !job?.ownerAddress ||
        job?.ownerAddress === '' ||
        !job?.ownerCity ||
        job?.ownerCity === '' ||
        !job?.ownerZipCode ||
        job?.ownerZipCode === '' ||
        !job?.ownerState ||
        job?.ownerState === ''
      ) {
        detailsMissingList.push('Owner');
      }
      if (
        !job?.GC ||
        job?.GC === '' ||
        !job?.GCAddress ||
        job?.GCAddress === '' ||
        !job?.GCCity ||
        job?.GCCity === '' ||
        !job?.GCZipCode ||
        job?.GCZipCode === '' ||
        !job?.GCState ||
        job?.GCState === ''
      ) {
        detailsMissingList.push('GC');
      }
      if (!job?.estimatedMaterialAmount) {
        detailsMissingList.push('Estimated Material Amount');
      }
      setMissingJobDetailsList(detailsMissingList);
      if (
        !job?.owner ||
        job?.owner === '' ||
        !job?.ownerAddress ||
        job?.ownerAddress === '' ||
        !job?.ownerCity ||
        job?.ownerCity === '' ||
        !job?.ownerZipCode ||
        job?.ownerZipCode === '' ||
        !job?.ownerState ||
        job?.ownerState === '' ||
        !job?.GC ||
        job?.GC === '' ||
        !job?.GCAddress ||
        job?.GCAddress === '' ||
        !job?.GCCity ||
        job?.GCCity === '' ||
        !job?.GCZipCode ||
        job?.GCZipCode === '' ||
        !job?.GCState ||
        job?.GCState === '' ||
        !job?.estimatedMaterialAmount
      ) {
        return true;
      }
    }
    return false;
  };

  const populatePdf = async (fieldDetails: PDFFieldNoticeTemplate[]) => {
    if (!selectedTemplate?.originalPdf?.url) {
      return;
    }

    let pdfUrl = '';
    if (flattenedPdfUrl) {
      pdfUrl = flattenedPdfUrl;
    } else {
      const flatPDF = await removePDFFields(selectedTemplate?.originalPdf?.url ?? '');
      pdfUrl = flatPDF ?? '';
    }
    const populatedPdfUrl = await populatePagesPdfWithFieldsData({
      fieldDetails,
      url: pdfUrl,
      releaseId: `${user?.id ?? ''}__${new Date().getTime()}`,
    });
    if (populatedPdfUrl) {
      setCurrentPdfUrl(populatedPdfUrl);
    }
  };

  const getVendorDetails = () => {
    return {};
  };

  const populateTemplateFieldData = () => {
    let data: { [x: string]: PreliminaryNoticeFieldData } = {};
    if (selectedTemplate) {
      selectedTemplate.formFields.forEach((formField) => {
        if (formField.fromFieldType === 'NEW_FIELD' && formField.whoPopulatesField === 'SUPPLIER') {
          let defaultValue: any = '';
          if (
            formField.fieldType === 'CURRENCY' &&
            formField.defaultValue !== null &&
            formField.defaultValue !== undefined
          ) {
            defaultValue = Number(formField.defaultValue).toFixed(2);
          } else if (formField.defaultValue !== null && formField.defaultValue !== undefined) {
            defaultValue = `${formField.defaultValue}`;
          } else {
            defaultValue = formField.defaultValue;
          }
          data = {
            ...data,
            [formField.id]: {
              isRequired: formField.isFieldRequired,
              value:
                formFieldsData[formField.id] && formFieldsData[formField.id].value
                  ? formFieldsData[formField.id].value
                  : formField.defaultValue !== null && formField.defaultValue !== undefined
                  ? defaultValue
                  : '',
              id: formField.id,
              name: formField.fieldName,
              type: formField.fieldType,
            },
          };
        }
      });
    }
    return { ...data };
  };

  const populateAllPdfData = () => {
    const specialFieldsPDFData: { fromFieldType: FromFieldTypeNoticeTemplate; dataToUse: any }[] =
      [];

    const dynamicForFieldsPdfData: {
      fromFieldId: string;
      value: any;
    }[] = [];

    if (selectedJob) {
      const filteredSelectedJob = { ...selectedJob };
      const allNewFormFields = { ...formFieldsData };
      Object.keys(allNewFormFields).forEach((formFieldKey) => {
        if (
          allNewFormFields[formFieldKey].value !== 'X' &&
          allNewFormFields[formFieldKey].id === 'checkContractor' &&
          filteredSelectedJob.GCZipCode
        ) {
          filteredSelectedJob.GC = '';
          filteredSelectedJob.GCAddress = '';
          filteredSelectedJob.GCCity = '';
          filteredSelectedJob.GCState = '';
          filteredSelectedJob.GCZipCode = null;
        }
        if (
          allNewFormFields[formFieldKey].value !== 'X' &&
          allNewFormFields[formFieldKey].id === 'checkLender' &&
          filteredSelectedJob.lenderZipCode
        ) {
          delete filteredSelectedJob.lender;
          delete filteredSelectedJob.lenderAddress;
          delete filteredSelectedJob.lenderCity;
          delete filteredSelectedJob.lenderState;
          delete filteredSelectedJob.lenderZipCode;
        }
        if (
          allNewFormFields[formFieldKey].value !== 'X' &&
          allNewFormFields[formFieldKey].id === 'checkOwner'
        ) {
          filteredSelectedJob.owner = '';
          filteredSelectedJob.ownerAddress = '';
          filteredSelectedJob.ownerCity = '';
          filteredSelectedJob.ownerState = '';
          filteredSelectedJob.ownerZipCode = null;
        }
      });
      if (filteredSelectedJob?.estimatedMaterialAmount) {
        filteredSelectedJob.estimatedMaterialAmount = formatMoney(
          filteredSelectedJob.estimatedMaterialAmount,
          2,
          '.',
          ',',
          ''
        );
      }
      if (filteredSelectedJob?.lender === '') {
        filteredSelectedJob.lender = 'N/A';
      }
      specialFieldsPDFData.push({
        fromFieldType: 'SUPPLIER_JOB_FIELD',
        dataToUse: filteredSelectedJob,
      });
    }

    if (signature) {
      specialFieldsPDFData.push({ fromFieldType: 'SIGNATURE_FIELD', dataToUse: signature });
      if (selectedTemplateFields) {
        const foundField = [...selectedTemplateFields].find((field) => field.id === 'signedDate');
        if (foundField) {
          dynamicForFieldsPdfData.push({
            fromFieldId: 'signedDate',
            value: new Date(),
          });
        }
      }
    }

    if (selectedTemplate) {
      const allNewFormFields = { ...formFieldsData };
      Object.keys(allNewFormFields).forEach((formFieldKey) => {
        if (allNewFormFields[formFieldKey].id !== 'signedDate') {
          dynamicForFieldsPdfData.push({
            fromFieldId: formFieldKey,
            value: allNewFormFields[formFieldKey].value,
          });
        }
      });
    }

    if (user) {
      specialFieldsPDFData.push({
        fromFieldType: 'USER_FIELD',
        dataToUse: user,
      });
    }

    if (user) {
      specialFieldsPDFData.push({
        fromFieldType: 'USER_SUPPLIER_FIELD',
        dataToUse: user,
      });
    }

    let pdfData: PDFFieldNoticeTemplate[] = [];
    if (specialFieldsPDFData.length > 0 && selectedTemplate) {
      const data1 = structureTemplateNonNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: specialFieldsPDFData,
      });
      pdfData = [...pdfData, ...data1];
    }
    if (dynamicForFieldsPdfData.length > 0) {
      const data2 = structureTemplateNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: dynamicForFieldsPdfData,
      });
      pdfData = [...pdfData, ...data2];
    }
    if (pdfData.length > 0) {
      populatePdf(pdfData);
    }
  };

  useEffect(() => {
    populateAllPdfData();
  }, [debouncedPopulatePdfChange]);

  useEffect(() => {
    triggerDebouncedPopulatePdf();
    return () => {};
  }, [selectedTemplate, selectedJob, formFieldsData, signature]);

  useEffect(() => {
    if (signature) {
      if (selectedTemplateFields) {
        const foundField = [...selectedTemplateFields].find((field) => field.id === 'signedDate');
        if (foundField) {
          setFormFieldsData({
            ...formFieldsData,
            signedDate: {
              isRequired: foundField.isFieldRequired,
              id: foundField.id,
              name: foundField.fieldName,
              type: foundField.fieldType,
              value: new Date(),
            },
          });
        }
      }
    }
  }, [signature]);

  useEffect(() => {
    if (selectedTemplate) {
      if (selectedTemplateFields) {
        const foundField = [...selectedTemplateFields].find((field) => field.id === 'checkByMail');
        if (foundField) {
          setFormFieldsData({
            ...formFieldsData,
            checkByMail: {
              isRequired: foundField.isFieldRequired,
              id: foundField.id,
              name: foundField.fieldName,
              type: foundField.fieldType,
              value: 'X',
            },
          });
        }
      }
    }
  }, [selectedTemplate, selectedJob, selectedTemplateFields]);

  useEffect(() => {
    const populatedTemplateFields = populateTemplateFieldData();

    setFormFieldsData({
      ...populatedTemplateFields,
    });

    return () => {};
  }, [flattenedPdfUrl]);

  const setupOriginalAndFlattenPDF = async () => {
    if (selectedTemplate) {
      const flat = await removePDFFields(selectedTemplate?.originalPdf?.url ?? '');
      if (flat) {
        setCurrentPdfUrl(flat);
        setFlattenedPdfUrl(flat);
      } else {
        setCurrentPdfUrl(selectedTemplate?.originalPdf?.url ?? '');
        setFlattenedPdfUrl(selectedTemplate?.originalPdf?.url ?? '');
      }
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      setupOriginalAndFlattenPDF();
      setSelectedTemplateFields(
        [...selectedTemplate.formFields]
          .sort((A, B) => {
            if (
              (A.position === null || A.position === undefined) &&
              B.position !== null &&
              B.position !== undefined
            ) {
              return 1;
            }
            if (
              (B.position === null || B.position === undefined) &&
              A.position !== null &&
              A.position !== undefined
            ) {
              return -1;
            }
            if (
              A.position !== null &&
              A.position !== undefined &&
              B.position !== null &&
              B.position !== undefined &&
              A.position > B?.position
            ) {
              return 1;
            }
            return -1;
          })
          .filter(
            (formFields) =>
              formFields.fromFieldType === 'NEW_FIELD' &&
              formFields.whoPopulatesField === 'SUPPLIER'
          )
      );
    }

    return () => {};
  }, [selectedTemplate]);

  const checkErrors = () => {
    let fields: string[] = [];

    if (!selectedJob) {
      fields = [...fields, 'job'];
    }
    if (!selectedTemplate) {
      fields = [...fields, 'template'];
    }

    Object.keys(formFieldsData).forEach((fieldKey) => {
      const fieldData = formFieldsData[fieldKey];
      if (fieldData.isRequired && !fieldData.value && !fieldData.id.startsWith('check')) {
        fields = [...fields, fieldKey];
      }
    });
    setErrorFields(fields);
    return fields;
  };

  useEffect(() => {
    if (selectedJob && Object.keys(supplierJobsNoInvoicesMap).length > 0) {
      const updatedSupplierJob = supplierJobsNoInvoicesMap[selectedJob.id];
      if (updatedSupplierJob) {
        setSelectedJob(updatedSupplierJob);
      }
    }
    if (missingJobDetails) {
      const checkMissingDetails = checkMissingJobDetails(selectedJob);
      setMissingJobDetails(checkMissingDetails);
    } else {
      setMissingJobDetails(false);
    }
    return () => {};
  }, [supplierJobsNoInvoicesMap]);

  useEffect(() => {
    const pathSplit = location.pathname.split('/');
    if (pathSplit.length === 4 && pathSplit[3] === 'addPreliminaryNotice') {
      const searchParams = new URLSearchParams(location.search);
      const jobId = searchParams.get('jobId');

      if (jobId) {
        const foundJob = supplierJobsNoInvoicesMap[jobId];
        if (foundJob && !selectedJob) {
          setSelectedJob(foundJob);
        }
      }
    }
    return () => {};
  }, [location.pathname, supplierJobsNoInvoicesMap]);

  const handleCreateSignature = async () => {
    if (signature.startsWith('data:image')) {
      const foundSignature = userSignatures.find((item) => item.signature === signature);
      if (!foundSignature) {
        if (userSignatures.length > 4) {
          await dispatch(
            deleteUserSignature({ userSignatureId: userSignatures[userSignatures.length - 1].id })
          );
        }
        await dispatch(
          createUserSignature({
            signature,
            date: new Date(),
          })
        );
      }
    }
  };

  const vendorContactTitleId = useMemo(() => {
    if (selectedTemplate) {
      const hasTitle = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' && formFields.id.startsWith('vendorContactTitle')
      );
      if (hasTitle.length > 0) {
        const titleId = hasTitle?.[0]?.id;
        setFormFieldsData({
          ...formFieldsData,
          [titleId]: {
            ...formFieldsData[titleId],
            value: user?.title ?? '',
          },
        });
        return titleId;
      }
      return '';
    }

    return '';
  }, [selectedTemplate]);

  const handleCreatePreliminaryNotice = async () => {
    setLoading(true);
    const createdPreliminaryNotice = await dispatch(
      createPreliminaryNotice({
        preliminaryNoticeDetails: {
          supplierId: user?.companyId ?? '',
          jobId: selectedJob?.id ?? '',
          ownerStatus: { trackLink: '' },
          contractorStatus: { trackLink: '' },
          lenderStatus: { trackLink: '' },
          status: 'pending',
          fieldsData: Object.values(formFieldsData),
          templateId: selectedTemplate?.id ?? '',
          pdf: {
            url: currentPdfUrl,
            name: selectedTemplate?.name ?? '',
          },
        },
      })
    );
    setLoading(false);
    if (createdPreliminaryNotice.type === 'preliminaryNotices/createPreliminaryNotice/fulfilled') {
      if (
        user &&
        vendorContactTitleId &&
        formFieldsData[vendorContactTitleId]?.value !== user?.title
      ) {
        const updateUserDetailsResponse = await dispatch(
          updateUserDetails({
            userId: user.id,
            userDetails: { title: formFieldsData[vendorContactTitleId]?.value },
          })
        );
        if (updateUserDetailsResponse.type === 'auth/updateUserDetails/fulfilled') {
          await dispatch(getUserDetails());
        }
      }
      setIsPrelimanaryNoticeCreateSuccessModalOpen(true);
    } else {
      dispatch(
        openMessageModal({
          title: 'Error creating preliminary notice!',
          subTitle: `Contact customer support.`,
          buttonText: 'Close',
          modalType: 'ERROR',
          isSecondButton: false,
          onClose: () => {},
        })
      );
    }
  };

  const handleSubmitPreliminaryNotice = async () => {
    dispatch(
      openMessageModal({
        title: 'Are you sure you want to submit this preliminary notice?',
        subTitle: `This action will send this preliminary notice to the Dill team for mailing. You will be able to find tracking links and additional documentation for this notice mailing in your Dill dashboard soon.`,
        buttonText: 'Close',
        modalType: 'WARN',
        isSecondButton: true,
        secondButtonText: 'Yes, Send',
        onSecondButtonClick: () => {
          handleCreatePreliminaryNotice();
        },
        onClose: () => {},
      })
    );
  };

  const hasSignature = useMemo(() => {
    if (selectedTemplate) {
      const filteredSignatureField = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'SIGNATURE_FIELD' && formFields.id.startsWith('signature')
      );
      return filteredSignatureField.length > 0;
    }

    return false;
  }, [selectedTemplate]);

  const handleAddSignature = (newSignature: string, isManual: boolean) => {
    setManualSign(isManual);
    if (isManual) {
      setCurrentPdfUrl(newSignature);
    } else {
      setSignature(newSignature);
    }
    setIsCreateSignatureModalOpen(false);
  };

  const handleDownloadRealease = async () => {
    const createdAtTimeStamp = new Date().getTime();
    const flattened = await flattenPDF(currentPdfUrl || '');
    if (flattened) {
      downloadFile(
        flattened,
        `${selectedJob?.name ? `${selectedJob.name}-` : ''}${
          selectedJob?.buyer?.name ?? ''
        }-${createdAtTimeStamp}.pdf`
      );
    }
  };

  const checkSignatureError = useMemo(() => {
    let fields: string[] = [];
    if (selectedTemplate) {
      selectedTemplate.formFields.forEach((formField) => {
        if (
          formField.fromFieldType === 'SIGNATURE_FIELD' &&
          formField.whoPopulatesField === 'SUPPLIER'
        ) {
          if (signature === '' && !manualSign) {
            fields = [...fields, formField.id];
          }
        }
      });
    }
    return fields;
  }, [formFieldsData, selectedJob, selectedTemplate, signature, manualSign]);

  const checkNonSignatureErrors = useMemo(() => {
    let fields: string[] = [];

    if (!selectedJob) {
      fields = [...fields, 'job'];
    }

    Object.keys(formFieldsData).forEach((fieldKey) => {
      const fieldData = formFieldsData[fieldKey];
      if (
        fieldData.isRequired &&
        !fieldData.value &&
        !fieldData.id.startsWith('check') &&
        !fieldData.id.startsWith('signedDate')
      ) {
        fields = [...fields, fieldKey];
      }
    });
    return fields;
  }, [formFieldsData, selectedJob, selectedTemplate, signature, manualSign]);

  const handleEditJob = (e: React.MouseEvent<HTMLButtonElement>, job: SupplierJobApiResponse) => {
    e.stopPropagation();
    setSelectedJob(job);
    setOpenEditJob(true);
  };

  return (
    <div className="flex flex-row h-full py-4">
      <AppNewSupplierJobModal
        open={openEditJob}
        editMode={true}
        selectedSupplierJob={selectedJob}
        handleClose={({ wasBuyerEdited }) => {
          setOpenEditJob(false);
          if (wasBuyerEdited) {
            recursiveFetchSupplierJob({ lastDockId: '', fetchJobs: [] });
          }
        }}
      />
      <CreateSignatureModal
        open={isCreateSignatureModalOpen}
        handleClose={() => {
          setIsCreateSignatureModalOpen(false);
        }}
        addSignature={handleAddSignature}
        onDownloadRealease={handleDownloadRealease}
        defaultIsManualSign={defaultIsManualSign}
      />
      <AppSuccessModal
        open={isPrelimanaryNoticeCreateSuccessModalOpen}
        modalImage={successChecksCircle}
        title="Successfully submitted preliminary notice"
        subTitle="Your preliminary notice has been submitted"
        buttonText="View All Preliminary Notices"
        handleClose={() => {
          setIsPrelimanaryNoticeCreateSuccessModalOpen(false);
          navigate('/preliminaryNotices/overview');
        }}
      />
      <div className={twMerge('flex flex-col w-5/12 mx-4 h-5/6 overflow-scroll hidescrollbar')}>
        <div className={twMerge('flex flex-col  bg-white px-4 py-3 mb-3')}>
          <div className="my-2 flex ">
            <AppDropDown2
              label="Job"
              isRequired={true}
              errorText={
                errorFields.includes('job')
                  ? 'Please select a job'
                  : `${
                      missingJobDetails
                        ? `Job is missing key information like ${missingJobDetailsList.join(
                            ', '
                          )}. Please update the job in the dropdown above.`
                        : ''
                    }`
              }
              value={selectedJob?.id || ''}
              isLoading={!dataFullyLoaded}
              items={[
                ...Object.values(supplierJobsNoInvoicesMap)
                  .filter((jb) => !jb?.archivedState?.includes('SUPPLIER_ARCHIVED'))
                  .map((job) => {
                    return {
                      label: `${job?.jobNumber ? `${job.jobNumber} - ` : ''}${job.name}`,
                      value: job.id,
                      labelInElement: (
                        <div className="flex px-2 py-3 m-[-5px] items-center justify-between group relative">
                          <div className="text-xs">{`${
                            job?.jobNumber ? `${job.jobNumber} - ` : ''
                          }${job.name}`}</div>
                          <button
                            onClick={(e) => {
                              handleEditJob(e, job);
                            }}
                            className="text-xs px-4 py-1 WHITE-BG font-bold TEXT_SECONDARY-CLR rounded absolute hidden group-hover:inline-block right-2">
                            Edit
                          </button>
                        </div>
                      ),
                    };
                  }),
              ]}
              onSelectChange={(item) => {
                if (item?.value) {
                  const uu = supplierJobsNoInvoicesMap[item.value];
                  if (uu) {
                    setSelectedJob(uu);
                    setMissingJobDetails(false);
                    setShowErrorAbove(false);
                  }
                }
              }}
              disabled={manualSign}
            />
          </div>
          <div className="my-1">
            <AppDropDown
              label="Preliminary Notice Template"
              isRequired={true}
              errorText={errorFields.includes('template') ? 'Please select template' : ''}
              value={selectedTemplate ? selectedTemplate.id : ''}
              items={[
                ...filteredTemplates.map((template) => {
                  return {
                    label: template.name,
                    value: template.id,
                  };
                }),
              ]}
              onSelectChange={(item) => {
                if (item?.value) {
                  const uu = filteredTemplates.find((val) => val.id === item.value);
                  if (uu) {
                    setSelectedTemplate(uu);
                    setShowErrorAbove(false);
                  }
                }
              }}
              isDisabled={manualSign}
            />
          </div>

          {selectedTemplate && (
            <div className="flex flex-wrap ">
              {selectedTemplateFields &&
                selectedTemplateFields.length > 0 &&
                [...selectedTemplateFields]
                  .filter(
                    (ffield) =>
                      !ffield.id.startsWith('check') && !ffield.id.startsWith('signedDate')
                  )
                  .map((formField, i) => {
                    const {
                      id,
                      fieldType,
                      fieldName,
                      isFieldRequired,
                      suffix,
                      prefix,
                      maxValue,
                      minValue,
                    } = formField;
                    const fieldValue = formFieldsData[id]?.value || '';
                    let finalFieldName = fieldName;
                    if (id === vendorContactTitleId) {
                      finalFieldName = 'Job Title';
                    }
                    return (
                      <div
                        key={i + 'oo'}
                        className={twMerge(
                          'w-1/2 my-2',
                          i % 2 === 0 ? 'pr-1' : 'pl-1',
                          i ===
                            selectedTemplate.formFields.filter(
                              (formFields) =>
                                formFields.fromFieldType === 'NEW_FIELD' &&
                                !formFields.id.startsWith('signedDate') &&
                                formFields.whoPopulatesField === 'SUPPLIER'
                            ).length -
                              1 && i % 2 === 0
                            ? 'w-full p-0'
                            : '',
                          fieldType === 'TEXT_AREA' ? 'w-full p-0' : ''
                        )}>
                        {(fieldType === 'TEXT' ||
                          fieldType === 'NUMBER' ||
                          fieldType === 'CURRENCY' ||
                          fieldType === 'TEXT_AREA') && (
                          <AppInputField
                            label={finalFieldName}
                            inputType={
                              fieldType === 'TEXT_AREA'
                                ? 'textarea'
                                : fieldType === 'NUMBER' || fieldType === 'CURRENCY'
                                ? 'number'
                                : 'text'
                            }
                            value={
                              fieldValue !== null && fieldValue !== undefined ? fieldValue : ''
                            }
                            isRequired={isFieldRequired}
                            errorText={errorFields.includes(id) ? 'Required' : ''}
                            suffix={suffix || ''}
                            prefix={prefix || ''}
                            onBlur={(e) => {
                              let val = e.target.value;
                              if (minValue && parseInt(val) <= minValue) {
                                setFormFieldsData({
                                  ...formFieldsData,
                                  [id]: { ...formFieldsData[id], value: minValue },
                                });
                                return;
                              }
                              if (maxValue && parseInt(val) >= maxValue) {
                                setFormFieldsData({
                                  ...formFieldsData,
                                  [id]: { ...formFieldsData[id], value: maxValue },
                                });
                                return;
                              }
                              if (fieldType === 'CURRENCY') {
                                if (!val) {
                                  val = null;
                                } else {
                                  val = Number(Number(val)).toFixed(2);
                                }
                                setFormFieldsData({
                                  ...formFieldsData,
                                  [id]: { ...formFieldsData[id], value: val },
                                });
                              }
                            }}
                            onTextChange={(text) => {
                              let val = text;

                              if (fieldType === 'NUMBER') {
                                if (text !== undefined && text !== null) {
                                  val = text;
                                } else {
                                  val = null;
                                }
                              } else if (fieldType === 'CURRENCY') {
                                if (text !== undefined && text !== null) {
                                  val = text;
                                } else {
                                  val = null;
                                }
                              }
                              setShowErrorAbove(false);
                              setFormFieldsData({
                                ...formFieldsData,
                                [id]: { ...formFieldsData[id], value: val },
                              });
                            }}
                            isDisabled={manualSign}
                          />
                        )}
                        {fieldType === 'DATE' && (
                          <AppDatePicker
                            label={finalFieldName}
                            selectedDate={fieldValue}
                            isRequired={isFieldRequired}
                            errorText={errorFields.includes(id) ? 'Required' : ''}
                            onDateSelected={(date) => {
                              setShowErrorAbove(false);
                              setFormFieldsData({
                                ...formFieldsData,
                                [id]: { ...formFieldsData[id], value: date },
                              });
                            }}
                            isDisabled={manualSign}
                          />
                        )}
                      </div>
                    );
                  })}
            </div>
          )}
          {selectedTemplate && (
            <div className="flex flex-wrap ">
              {selectedTemplateFields &&
                selectedTemplateFields.length > 0 &&
                [...selectedTemplateFields]
                  .filter(
                    (ffield) =>
                      !ffield.id.startsWith('checkByMail') && !ffield.id.startsWith('signedDate')
                  )
                  .map((formField, i) => {
                    const {
                      id,
                      fieldType,
                      fieldName,
                      isFieldRequired,
                      suffix,
                      prefix,
                      maxValue,
                      minValue,
                    } = formField;
                    const fieldValue = formFieldsData[id]?.value || '';
                    return (
                      <div key={i + 'oo'} className={twMerge('w-full mb-2')}>
                        {id.startsWith('check') && (
                          <div className="my-1 flex items-center">
                            <AppCheckBox
                              isChecked={
                                fieldValue !== null && fieldValue !== undefined && fieldValue !== ''
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                if (
                                  fieldValue !== null &&
                                  fieldValue !== undefined &&
                                  fieldValue !== ''
                                ) {
                                  setFormFieldsData({
                                    ...formFieldsData,
                                    [id]: { ...formFieldsData[id], value: null },
                                  });
                                } else {
                                  setFormFieldsData({
                                    ...formFieldsData,
                                    [id]: { ...formFieldsData[id], value: 'X' },
                                  });
                                }
                              }}
                              isDisabled={manualSign}
                            />
                            <div className="ml-2 text-sm">{fieldName}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
            </div>
          )}
          {selectedTemplate && (
            <div className="flex flex-wrap ">
              {selectedTemplateFields &&
                selectedTemplateFields.length > 0 &&
                [...selectedTemplateFields]
                  .filter((ffield) => ffield.id.startsWith('checkByMail'))
                  .map((formField, i) => {
                    const {
                      id,
                      fieldType,
                      fieldName,
                      isFieldRequired,
                      suffix,
                      prefix,
                      maxValue,
                      minValue,
                    } = formField;
                    const fieldValue = formFieldsData[id]?.value || '';
                    return (
                      <div key={i + 'oo'} className={twMerge('w-full mb-2')}>
                        {id.startsWith('check') && (
                          <div className="my-1 flex items-center">
                            <AppCheckBox
                              isChecked={
                                fieldValue !== null && fieldValue !== undefined && fieldValue !== ''
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                if (
                                  fieldValue !== null &&
                                  fieldValue !== undefined &&
                                  fieldValue !== ''
                                ) {
                                  setFormFieldsData({
                                    ...formFieldsData,
                                    [id]: { ...formFieldsData[id], value: null },
                                  });
                                } else {
                                  setFormFieldsData({
                                    ...formFieldsData,
                                    [id]: { ...formFieldsData[id], value: 'X' },
                                  });
                                }
                              }}
                              isDisabled={true}
                            />
                            <div className="ml-2 text-sm">{fieldName}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
            </div>
          )}
          <div>
            {hasSignature && (
              <div className="flex">
                <AppButton
                  text={signature !== '' ? 'Signed' : 'Sign'}
                  type="PRIMARY"
                  buttonStyles={{ width: '100px', height: '35px' }}
                  onClick={() => {
                    setDefaultIsManualSign(false);
                    setIsCreateSignatureModalOpen(true);
                  }}
                  isDisabled={manualSign || signature !== ''}
                />
                <AppButton
                  text="Download & Manual Upload"
                  type="SECONDARY"
                  buttonStyles={{
                    marginLeft: '4px',
                    height: '35px',
                  }}
                  onClick={() => {
                    const checkMissingDetails = checkMissingJobDetails(selectedJob);
                    setMissingJobDetails(checkMissingDetails);
                    if (!checkMissingDetails) {
                      setDefaultIsManualSign(true);
                      setIsCreateSignatureModalOpen(true);
                    }
                  }}
                  isDisabled={
                    checkNonSignatureErrors.length !== 0 || manualSign || signature !== ''
                  }
                />
              </div>
            )}
          </div>
        </div>
        {showErrorAbove && (
          <div className="my-2 text-xs ERROR_500-CLR text-center">{`${
            checkNonSignatureErrors.length > 0 || missingJobDetails
              ? `Please see errors above ${
                  checkSignatureError.length > 0
                    ? 'and add a signature or upload signed document'
                    : ''
                }`
              : checkSignatureError.length > 0
              ? 'Please add a signature to the document or upload a signed document'
              : ''
          }`}</div>
        )}
        <div className="flex flex-row justify-end ">
          <AppButton
            text="Close"
            type="SECONDARY"
            buttonStyles={{ width: '100px', height: '40px' }}
            onClick={() => {
              navigate('/preliminaryNotices/overview');
            }}
          />
          <div className="flex flex-row align-center ml-3">
            <AppButton
              text="Submit"
              buttonStyles={{ width: '100px', height: '40px' }}
              onClick={() => {
                const fieldsWithErrors = checkErrors();
                const checkMissingDetails = checkMissingJobDetails(selectedJob);
                setShowErrorAbove(
                  fieldsWithErrors.length !== 0 ||
                    checkSignatureError.length !== 0 ||
                    checkMissingDetails
                );
                setMissingJobDetails(checkMissingDetails);
                if (
                  fieldsWithErrors.length === 0 &&
                  checkSignatureError.length === 0 &&
                  !checkMissingDetails
                ) {
                  handleSubmitPreliminaryNotice();
                }
              }}
              isLoading={loading}
              isDisabled={loading}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-7/12 h-full">
        <div className="h-5/6 relative  overflow-scroll flex flex-col items-center pb-3">
          <div
            style={{
              width: '612px',
              background: '#F4F7FA',
            }}
            className="sticky top-0  z-10 flex justify-between  mb-0.5"></div>

          <div
            style={{
              width: '612px',
              height: '792px',
            }}
            className="relative ">
            {currentPdfUrl && (
              <Viewer
                defaultScale={1}
                fileUrl={currentPdfUrl}
                plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                initialPage={currentPage}
                onPageChange={(e) => setCurrentPage(e.currentPage)}
                renderLoader={(percentages: number) => (
                  <div style={{ width: '240px' }}>
                    <ProgressBar progress={Math.round(percentages)} />
                  </div>
                )}
              />
            )}
          </div>
        </div>

        <div className="flex flex-row justify-center">
          <div className="mx-4">
            <CurrentPageLabel>
              {(props: RenderCurrentPageLabelProps) => {
                return (
                  <div className="mx-4 flex items-center justify-center">
                    {props.numberOfPages > 1 && (
                      <button
                        className={`mr-2 flex items-center justify-center py-[2px] px-2 ${
                          props.numberOfPages > props.currentPage + 1
                            ? 'GREY_500-CLR cursor-default'
                            : 'hover:bg-blue-100  hover:text-[#3762FB]'
                        }`}
                        onClick={() => {
                          setCurrentPage(props.currentPage - 1);
                          jumpToPreviousPage();
                        }}>
                        <CaretCircleLeft className="mr-1" />
                        Previous Page
                      </button>
                    )}
                    <span>{`${props.numberOfPages > 1 ? '' : 'page'} ${props.currentPage + 1} / ${
                      props.numberOfPages
                    }`}</span>
                    {props.numberOfPages > 1 && (
                      <button
                        className={`ml-2 flex items-center justify-center py-[2px] px-2 ${
                          props.numberOfPages - 1 < props.currentPage + 1
                            ? 'GREY_500-CLR cursor-default'
                            : 'hover:bg-blue-100 hover:text-[#3762FB]'
                        }`}
                        onClick={() => {
                          jumpToNextPage();
                          setCurrentPage(props.currentPage + 1);
                        }}>
                        Next Page
                        <CaretCircleRight className="ml-1" />
                      </button>
                    )}
                  </div>
                );
              }}
            </CurrentPageLabel>
          </div>

          <ZoomOutButton />
          <ZoomPopover />
          <ZoomInButton />
        </div>
      </div>
    </div>
  );
};

export default AddPreliminaryNotice;
