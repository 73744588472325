import { useState, useEffect, useMemo } from 'react';
import { Plus, Search, AlertCircle, Trash } from 'react-feather';
import { AppButton } from '../../../general/AppButton/AppButton';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { AppErrorPage } from '../../../general/AppErrorPage/AppErrorPage';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { COLORS } from '../../../../utils/colors';
import {
  archiveSupplierJob,
  getSupplierJobs,
  recursiveGetSupplierJobs,
} from '../../../../redux/services/supplierJobsService';
import { SupplierJobApiResponse } from '@dill/dill-shared/dist/src';
import SupplierJobsTable from '../SupplierJobsTable/SupplierJobsTable';
import { AppNewSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppNewSupplierJobModal';
import { getSupplierBuyers } from '../../../../redux/services/supplierBuyersService';
import { getSupplierLienTemplates } from '../../../../redux/services/leinTemplatesService';
import { getUserSignatures } from '../../../../redux/services/authService';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { AppLoadingProgress } from '../../../general/AppLoadingProgress/AppLoadingProgress';
import { openDillToast, removeExtraSpaces } from '../../../../utils/helpers';
import {
  resetSelectedSupplierJobs,
  resetSupplierJobs,
} from '../../../../redux/globalSlices/supplierJobsSlice';
import { AppBulkEditSupplierJobModal } from '../../../general/AppNewSupplierJobModal/AppBulkEditSupplierJobModal';
import { AppDropDown2 } from '../../../general/AppDropDown2/AppDropDown2';
import { AppCheckBox } from '../../../general/AppCheckBox/AppCheckBox';
import {
  getRequestedSupplierJobs,
  updateRequestedSupplierJob,
} from '../../../../redux/services/requestJobInformationService';
import AppRequestedJobsModal from '../../../general/AppRequestedJobsModal/AppRequestedJobsModal';
import { AppDropdownButton } from '../../../general/AppDropdownButton/AppDropdownButton';
import { AppRequestInfoJobModal } from '../../../general/AppNewSupplierJobModal/AppRequestInfoJobModal';
import { useLocation } from 'react-router-dom';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { AppStandardInfoSheet } from '../../../general/AppNewSupplierJobModal/AppStandardInfoSheet';

enum ModalState {
  ADD_JOB,
  REQUEST_JOB_INFO_SHEET,
}

const SupplierJobs = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [addNewJob, setAddNewJob] = useState(false);
  const [requestJobInfo, setRequestJobInfo] = useState(false);
  const [bulkEditJob, setBulkEditJob] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [toogle, setToogle] = useState(true);
  const [supplierJobsCount, setSupplierJobsCount] = useState(0);
  const [customerFilterOption, setCustomerFilterOption] = useState<string[] | null>(null);

  const { loadingErrorList, supplierJobsMap, selectedSupplierJobs } = useAppSelector(
    (state) => state.supplierJobs
  );
  const { requestedSupplierJobs } = useAppSelector((state) => state.requestJobInformation);
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { userSignatures } = useAppSelector((state) => state.auth);
  const [sortOptions, setSortOptions] = useState<{ sortOrder: 'asc' | 'desc'; sortBy: string }>({
    sortOrder: 'desc',
    sortBy: 'createdAt',
  });
  const [previousLastDocumentId, setPreviousLastDocumentId] = useState('');
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSupplierJobs, setCurrentSupplierJobs] = useState<SupplierJobApiResponse[]>([]);
  const [dataFullyLoaded, setDataFullyLoaded] = useState<boolean>(false);
  const [openRequestedJobsModal, setOpenRequestedJobsModal] = useState<boolean>(false);
  const [selectedSupplierJob, setSelectedSupplierJob] = useState<SupplierJobApiResponse | null>(
    null
  );
  const [editSupplierJob, setEditSupplierJob] = useState(false);
  const [requestedJobId, setRequestedJobId] = useState('');
  const [generalJobInfo, setGeneralJobInfo] = useState<boolean>(false);

  const searchedSupplierJobs: SupplierJobApiResponse[] = useMemo(() => {
    let filteredSupplierJobs: SupplierJobApiResponse[] = [];
    const activeJobs = Object.values(supplierJobsMap).filter((job) => {
      if (toogle && job?.archivedState && job?.archivedState.includes('SUPPLIER_ARCHIVED')) {
        return false;
      }
      return true;
    });
    if (activeJobs && searchText) {
      const searched = activeJobs.filter((supplierJob) => {
        const updateSearchedText = removeExtraSpaces(searchText);
        if (
          (supplierJob.name &&
            removeExtraSpaces(supplierJob.name)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobNumber &&
            supplierJob.jobNumber
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.name &&
            supplierJob.buyer?.name
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.address &&
            removeExtraSpaces(supplierJob.buyer?.address)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.city &&
            removeExtraSpaces(supplierJob.buyer?.city)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.state &&
            removeExtraSpaces(supplierJob.buyer?.state)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.buyer?.zipCode &&
            removeExtraSpaces(supplierJob.buyer?.zipCode)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.owner &&
            removeExtraSpaces(supplierJob.owner)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.ownerAddress &&
            removeExtraSpaces(supplierJob.ownerAddress)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.ownerCity &&
            removeExtraSpaces(supplierJob.ownerCity)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.ownerState &&
            removeExtraSpaces(supplierJob.ownerState)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.ownerZipCode &&
            removeExtraSpaces(supplierJob.ownerZipCode)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobAddress &&
            removeExtraSpaces(supplierJob.jobAddress)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobCity &&
            removeExtraSpaces(supplierJob.jobCity)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobState &&
            removeExtraSpaces(supplierJob.jobState)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob.jobZipCode &&
            removeExtraSpaces(supplierJob.jobCity)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.GC &&
            removeExtraSpaces(supplierJob.GC)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.GCAddress &&
            removeExtraSpaces(supplierJob.GCAddress)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.GCCity &&
            removeExtraSpaces(supplierJob.GCCity)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.GCState &&
            removeExtraSpaces(supplierJob.GCState)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.apn &&
            removeExtraSpaces(supplierJob.apn)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.GCZipCode &&
            removeExtraSpaces(supplierJob.GCZipCode)
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase())) ||
          (supplierJob?.estimatedMaterialAmount &&
            removeExtraSpaces(String(supplierJob.estimatedMaterialAmount))
              .toString()
              .toLowerCase()
              .includes(updateSearchedText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      filteredSupplierJobs = searched;
    } else {
      filteredSupplierJobs = activeJobs;
    }

    if (customerFilterOption && customerFilterOption.length > 0) {
      filteredSupplierJobs = filteredSupplierJobs.filter((supplierJob) =>
        customerFilterOption.includes(supplierJob.buyerId)
      );
    }

    return filteredSupplierJobs;
  }, [supplierJobsMap, searchText, toogle, customerFilterOption]);

  const error = useMemo(() => {
    if (loadingErrorList.length > 0) {
      const errorMessage: any = loadingErrorList.filter((obj) =>
        obj.hasOwnProperty('getSupplierJobs')
      );
      if (errorMessage.length > 0) {
        return errorMessage[0]?.getSupplierJobs;
      }
    }
    return null;
  }, [loadingErrorList]);

  const recursiveFetchSupplierJob = async (
    count: number,
    lastDockId: string,
    fetchedJobs: SupplierJobApiResponse[]
  ) => {
    if (fetchedJobs.length > count && fetchedJobs.length > 0) {
      setDataFullyLoaded(true);
      return;
    }
    setSupplierJobsCount(count);
    const pageSize = 100;
    let currentPreviousLastDocumentId = lastDockId;
    for (let i = fetchedJobs.length; i < count; i += pageSize) {
      const results = await dispatch(
        recursiveGetSupplierJobs({
          includeInvoices: true,
          includePreLiens: true,
          previousLastDocumentId: currentPreviousLastDocumentId,
          pageSize,
          sortBy: sortOptions.sortBy,
          sortOrder: sortOptions.sortOrder,
        })
      );
      if (results.type === 'supplierJobs/recursiveGetSupplierJobs/fulfilled') {
        if (results.payload) {
          if (supplierJobsCount === 0) {
            const payload = results.payload as any;
            setSupplierJobsCount(Number(payload.count));
          }
          const payload = results.payload as any;
          const fetchedSupplierJobs: SupplierJobApiResponse[] = payload.data as any;
          const lastDocumentId = payload.lastDocumentId;
          if (lastDocumentId !== currentPreviousLastDocumentId) {
            currentPreviousLastDocumentId = lastDocumentId;
          }
          if (!lastDockId || fetchedSupplierJobs.length < pageSize) {
            console.log('No more pages to fetch');
            setDataFullyLoaded(true);
            break;
          }
        }
      } else {
        setDataFullyLoaded(true);
        console.log('Fetch failed or interrupted');
        break;
      }
    }
  };

  const handleLoadMore = async (loadOptions?: { isFreshFetch?: boolean }) => {
    setLoading(true);
    setDataFullyLoaded(false);
    const pageSize = 50;
    let currentPreviousLastDocumentId = previousLastDocumentId;
    let newSupplierJobs = currentSupplierJobs;
    if (loadOptions && loadOptions?.isFreshFetch) {
      currentPreviousLastDocumentId = '';
      newSupplierJobs = [];
    }
    const results = await dispatch(
      getSupplierJobs({
        includeInvoices: true,
        includePreLiens: true,
        previousLastDocumentId: currentPreviousLastDocumentId,
        pageSize,
        sortBy: sortOptions.sortBy,
        sortOrder: sortOptions.sortOrder,
      })
    );
    setLoading(false);
    if (results.type === 'supplierJobs/getSupplierJobs/fulfilled') {
      if (results.payload) {
        const payload = results.payload as any;
        const fetchedSupplierJobs: SupplierJobApiResponse[] = payload.data as any;
        const lastDockId = payload.lastDocumentId;

        if (lastDockId && lastDockId !== currentPreviousLastDocumentId) {
          setPreviousLastDocumentId(lastDockId);

          if (payload.count) {
            const count = Number(payload.count);
            if (count <= fetchedSupplierJobs.length) {
              setDataFullyLoaded(true);
            }
            recursiveFetchSupplierJob(count, lastDockId, [
              ...newSupplierJobs,
              ...fetchedSupplierJobs,
            ]);
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(resetSelectedSupplierJobs());
    dispatch(getRequestedSupplierJobs());
    handleLoadMore({ isFreshFetch: true });
    dispatch(getSupplierBuyers());
    if (lienTemplates.length === 0) {
      dispatch(getSupplierLienTemplates());
    }
    if (userSignatures.length === 0) {
      dispatch(getUserSignatures());
    }
  }, []);

  useEffect(() => {
    if (location.search.includes('refresh=true')) {
      dispatch(resetSelectedSupplierJobs());
      dispatch(getRequestedSupplierJobs());
      handleLoadMore({ isFreshFetch: true });
      dispatch(getSupplierBuyers());
      if (lienTemplates.length === 0) {
        dispatch(getSupplierLienTemplates());
      }
      if (userSignatures.length === 0) {
        dispatch(getUserSignatures());
      }
    }
  }, [location]);

  const [infiniteRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const customerDropdownList = useMemo(() => {
    const customersMap = new Map();

    Object.values(supplierJobsMap).forEach((job) => {
      if (job.buyer) {
        customersMap.set(job.buyer.id, {
          value: job.buyer.id ?? '',
          label: job.buyer?.name?.toLowerCase() ?? '',
        });
      }
    });

    const customers = Array.from(customersMap.values());

    return customers;
  }, [supplierJobsMap]);

  const handleEditSupplierJob = (data: SupplierJobApiResponse, id: string) => {
    setSelectedSupplierJob(data);
    setEditSupplierJob(true);
    setRequestedJobId(id);
  };

  const handleUpdateRequestedJob = async () => {
    await dispatch(
      updateRequestedSupplierJob({
        requestedSupplierJobId: requestedJobId,
        requestedSupplierJobDetails: { status: 'approved' },
      })
    );
    await dispatch(getRequestedSupplierJobs());
  };
  const handleBulkArchiveJobs = async () => {
    if (selectedSupplierJobs.length > 0) {
      dispatch(
        openMessageModal({
          buttonText: 'Yes, I’m sure',
          modalType: 'INFO',
          customIcon: (
            <div className="rounded-full ERROR_100-BG p-6">
              <Trash color={COLORS.ERROR_500} size={52} />
            </div>
          ),
          title: `Are you sure you want to mark the selected jobs as a inactive?`,
          subTitle: `This action will archive the job. This action can't be undone`,
          isSecondButton: true,
          secondButtonText: 'No, cancel',
          onClose: async () => {
            const response = await dispatch(
              archiveSupplierJob({ supplierJobsIds: selectedSupplierJobs.map((job) => job.id) })
            );
            if (response.type === 'supplierJobs/archiveSupplierJob/fulfilled') {
              dispatch(resetSelectedSupplierJobs());
              openDillToast({ message: 'Successfuly archived jobs', type: 'SUCCESS' });
            } else {
              let errorMessage = '';
              if (response.type === 'supplierJobs/archiveSupplierJob/rejected') {
                errorMessage = response.payload as any;
              }
              openDillToast({ message: `${errorMessage}`, type: 'ERROR' });
            }
          },
          onSecondButtonClick: async () => {},
        })
      );
    }
  };

  return (
    <div className="h-full p-8 flex ">
      <AppNewSupplierJobModal
        open={addNewJob}
        handleClose={() => {
          setAddNewJob(false);
        }}
        onReload={() => {
          handleLoadMore({ isFreshFetch: true });
        }}
      />
      <AppRequestInfoJobModal
        open={requestJobInfo}
        handleClose={() => {
          setRequestJobInfo(false);
        }}
        onReload={() => {
          handleLoadMore({ isFreshFetch: true });
        }}
      />
      <AppNewSupplierJobModal
        open={editSupplierJob}
        handleClose={() => {
          setEditSupplierJob(false);
        }}
        onReload={() => {}}
        editMode={true}
        selectedSupplierJob={selectedSupplierJob}
        onUpdateRequestedJob={handleUpdateRequestedJob}
      />
      <AppBulkEditSupplierJobModal
        open={bulkEditJob}
        handleClose={() => {
          setBulkEditJob(false);
        }}
      />
      <AppRequestedJobsModal
        open={openRequestedJobsModal}
        handleClose={() => {
          setOpenRequestedJobsModal(false);
        }}
        requestedSupplierJobs={requestedSupplierJobs}
        onEditSupplierJob={handleEditSupplierJob}
        onReload={() => {
          handleLoadMore({ isFreshFetch: true });
        }}
      />
      <AppStandardInfoSheet
        open={generalJobInfo}
        handleClose={() => {
          setGeneralJobInfo(false);
        }}
      />
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="flex justify-between">
          <div className="flex items-center">
            <div className="flex items-center">
              <AppInputField
                inputFieldStyles={{ width: '300px' }}
                id="search"
                value={searchText}
                placeholder={'Search '}
                icon={<Search size={15} />}
                onTextChange={(text) => {
                  setSearchText(text);
                }}
              />
              <div className="flex ml-2">
                <div
                  onClick={() => setToogle(!toogle)}
                  className={`w-[40px] h-[22px] rounded-full p-[1px] grid flex-col cursor-pointer ${
                    toogle ? 'justify-items-end bg-[#3862fb]' : 'GREY_500-BG'
                  }`}>
                  <div className="bg-white w-[20px] h-[20px] rounded-full" />
                </div>
                <div className="w-[180px] ml-2 font-semibold text-sm">Show Only Active Jobs</div>
              </div>
            </div>
            <div className="w-[220px] relative">
              <AppDropDown2
                value={''}
                isMulti={true}
                placeholder="Filter by Customer"
                isCreatable={false}
                closeMenu={false}
                items={[
                  ...customerDropdownList.map((item) => ({
                    label: item.label,
                    value: item.value,
                    labelInElement: (
                      <div className="flex m-1 items-center">
                        <div className="w-[25px] mr-2">
                          <AppCheckBox isChecked={customerFilterOption?.includes(item.value)} />
                        </div>
                        <div className="text-black text-sm capitalize">{item.label}</div>
                      </div>
                    ),
                  })),
                ]}
                onMultiSelectChange={(data, oldMultiValue) => {
                  const valueList = data.map((item) => item.value);
                  setCustomerFilterOption(valueList);
                }}
                onSelectChange={(item) => {}}
                itemWidth={220}
                multiValue={customerFilterOption}
              />
              {customerFilterOption && customerFilterOption?.length > 0 && (
                <div className="flex absolute bottom-6 right-[-12px] PRIMARY_500-BG WHITE-CLR rounded-full text-xs font-bold h-8 w-8 items-center justify-center">
                  {customerFilterOption?.length}
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center">
            <AppLoadingProgress
              text="Supplier Job"
              currentCount={Object.keys(supplierJobsMap).length}
              totalCount={supplierJobsCount}
              showTotalLoading={true}
            />

            {selectedSupplierJobs.length > 0 && (
              <AppDropdownButton
                buttonType="SECONDARY"
                options={[
                  { label: 'Bulk Edit', value: 'bulkEdit' },
                  { label: 'Bulk Archive', value: 'bulkArchive' },
                ]}
                onSelect={(val) => {
                  if (val.value === 'bulkEdit') {
                    setBulkEditJob(true);
                  } else if (val.value === 'bulkArchive') {
                    handleBulkArchiveJobs();
                  } else {
                    // setBulkRequestPrelimNotices(true);
                  }
                }}
                buttonTitle="Bulk Action"
                buttonStyles={{ marginLeft: '8px' }}
                contentWidth={220}
              />
            )}
            <AppDropdownButton
              options={[
                { label: 'Add Job Without Request Info Sheet', value: 'withoutRequestInfoSheet' },
                { label: 'Add Job With Request Info Sheet', value: 'withRequestInfoSheet' },
                { label: 'Standard Job Info Sheet Link', value: 'standardJobInfoSheetLink' },
              ]}
              onSelect={(val) => {
                if (val.value === 'withoutRequestInfoSheet') {
                  setAddNewJob(true);
                } else if (val.value === 'withRequestInfoSheet') {
                  setRequestJobInfo(true);
                } else {
                  setGeneralJobInfo(true);
                }
              }}
              buttonTitle="Add New Job"
              icon={<Plus color={COLORS.WHITE} />}
              buttonStyles={{ marginLeft: '8px' }}
              buttonIconPosition="LEFT"
              contentWidth={220}
            />
          </div>
        </div>
        {requestedSupplierJobs.length > 0 && (
          <div className="flex rounded PRIMARY_100-BG PRIMARY_500-CLR p-2 items-center w-fit mt-2">
            <AlertCircle size={18} />
            <div className="mx-2 text-sm">
              {`There ${
                requestedSupplierJobs.length > 1
                  ? `are ${requestedSupplierJobs.length} new jobs`
                  : `is a new job ${requestedSupplierJobs[0].name}`
              } entered by your contractor${requestedSupplierJobs.length > 1 ? 's' : ''}.`}
            </div>
            <div
              onClick={() => setOpenRequestedJobsModal(true)}
              className="underline text-sm cursor-pointer font-semibold">
              Review here
            </div>
          </div>
        )}
        {error ? (
          <AppErrorPage
            title="Error loading  jobs."
            contactMessage="Please contact customer support."
            errorMessage={error}
          />
        ) : (
          <SupplierJobsTable
            searchedSupplierJobs={searchedSupplierJobs}
            infiniteRef={infiniteRef}
            hasNextPage={false}
            loading={loading}
            onReload={() => {
              handleLoadMore({ isFreshFetch: true });
            }}
            dataFullyLoaded={dataFullyLoaded}
          />
        )}
      </div>
    </div>
  );
};

export default SupplierJobs;
