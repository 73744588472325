import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import {
  RequestedSupplierJob,
  RequestedSupplierJobCreate,
  RequestedSupplierJobUpdate,
} from '@dill/dill-shared';

export const generateRequestJobInformationLink = createAsyncThunk(
  'requestJobInformation/generateRequestJobInformationLink',
  async (
    {
      supplierId,
      domainId,
      jobId,
      buyerId,
      buyerName,
      name,
      jobNumber,
      jobAddress,
      jobCity,
      jobState,
      jobZipCode,
    }: {
      supplierId: string;
      domainId: string;
      jobId: string;
      buyerId: string;
      buyerName: string;
      name: string;
      jobNumber: string;
      jobAddress: string;
      jobCity: string;
      jobState: string;
      jobZipCode: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/suppliers/requestJobInformation/generateRequestJobInformationLink`,
        {
          supplierId,
          domainId,
          jobId,
          buyerId,
          buyerName,
          name,
          jobNumber,
          jobAddress,
          jobCity,
          jobState,
          jobZipCode,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createRequestedSupplierJob = createAsyncThunk(
  'requestJobInformation/createRequestedSupplierJob',
  async (
    {
      requestedSupplierJobDetails,
      emailTo,
      ccEmails,
    }: {
      requestedSupplierJobDetails: RequestedSupplierJobCreate;
      emailTo: string;
      ccEmails: string[];
    },
    thunkApi
  ) => {
    try {
      const response = await axios.post<any>(
        `${BASE_API_URL}/requestJobInformation/createRequestedSupplierJob`,
        {
          ...requestedSupplierJobDetails,
          emailTo,
          ccEmails,
        },
        {
          headers: {},
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateRequestedSupplierJob = createAsyncThunk(
  'requestJobInformation/updateRequestedSupplierJob',
  async (
    {
      requestedSupplierJobDetails,
      requestedSupplierJobId,
    }: {
      requestedSupplierJobDetails: RequestedSupplierJobUpdate;
      requestedSupplierJobId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/suppliers/requestJobInformation/updateRequestedSupplierJob/${requestedSupplierJobId}`,
        { ...requestedSupplierJobDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getRequestedSupplierJobs = createAsyncThunk(
  'requestJobInformation/getRequestedSupplierJobs',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/requestJobInformation/getRequestedSupplierJobs`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getRequestedSupplierJob = createAsyncThunk(
  'requestJobInformation/getRequestedSupplierJob',
  async ({ requestedSupplierJobId }: { requestedSupplierJobId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL +
          `/suppliers/requestJobInformation/getRequestedSupplierJob/${requestedSupplierJobId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const deleteRequestedSupplierJob = createAsyncThunk(
  'requestJobInformation/deleteRequestedSupplierJob',
  async (
    {
      requestedSupplierJobId,
    }: {
      requestedSupplierJobId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.delete<AppServerResponse>(
        BASE_API_URL +
          `/suppliers/requestJobInformation/deleteRequestedSupplierJob/${requestedSupplierJobId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
