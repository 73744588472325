import { Modal } from '@mui/material';
import React, { useState } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import RequestedJobList from './RequestedJobList';
import { on } from 'events';
import { RequestedSupplierJobApiResponse, SupplierJobApiResponse } from '@dill/dill-shared';
import RequestedJobView from './RequestedJobView';
import { updateSupplierJob } from '../../../redux/services/supplierJobsService';
import { useAppDispatch } from '../../../redux/store';
import { openDillToast } from '../../../utils/helpers';
import {
  getRequestedSupplierJobs,
  updateRequestedSupplierJob,
} from '../../../redux/services/requestJobInformationService';

enum ModalState {
  JOB_LIST,
  JOB_VIEW,
}

const AppRequestedJobsModal = ({
  open,
  handleClose,
  requestedSupplierJobs,
  onEditSupplierJob,
  onReload,
}: {
  open: boolean;
  handleClose: () => void;
  requestedSupplierJobs: RequestedSupplierJobApiResponse[];
  onEditSupplierJob: (data: SupplierJobApiResponse, id: string) => void;
  onReload: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [modalState, setModalState] = useState<ModalState>(ModalState.JOB_LIST);
  const [selectedJob, setSelectedJob] = useState<RequestedSupplierJobApiResponse>(
    {} as RequestedSupplierJobApiResponse
  );
  const [accepting, setAccepting] = useState<boolean>(false);
  const [declining, setDeclining] = useState<boolean>(false);

  const handleCloseModal = () => {
    handleClose();
    setModalState(ModalState.JOB_LIST);
    setSelectedJob({} as RequestedSupplierJobApiResponse);
  };

  const handleSelectJob = (data: RequestedSupplierJobApiResponse) => {
    setSelectedJob(data);
    setModalState(ModalState.JOB_VIEW);
  };
  const handleAcceptJob = async (data: RequestedSupplierJobApiResponse) => {
    setAccepting(true);

    if (selectedJob?.buyerId === '') {
      const acceptSupplierJob = await dispatch(
        updateRequestedSupplierJob({
          requestedSupplierJobId: data.id,
          requestedSupplierJobDetails: { status: 'approved', buyerId: data.buyerId },
        })
      );
      if (acceptSupplierJob.type === 'requestJobInformation/updateRequestedSupplierJob/fulfilled') {
        await dispatch(getRequestedSupplierJobs());
        setAccepting(false);
        onReload();
        openDillToast({ message: 'Successfully accepted job', type: 'SUCCESS' });
        handleCloseModal();
      } else {
        setAccepting(false);
        openDillToast({ type: 'ERROR', message: 'Failed to accept job' });
      }
    } else {
      let moreDetails = {};
      if (data.jobState === 'AZ') {
        moreDetails = {
          jobCounty: data.jobCounty,
          jobLegalDescription: data.jobLegalDescription,
        };
      }
      const supplierJobData = {
        name: data.name,
        jobNumber: data.jobNumber,
        jobAddress: data.jobAddress,
        jobCity: data.jobCity,
        jobState: data.jobState,
        jobZipCode: data.jobZipCode,
        owner: data.owner,
        ownerAddress: data.ownerAddress,
        ownerCity: data.ownerCity,
        ownerState: data.ownerState,
        ownerZipCode: data.ownerZipCode,
        GC: data.GC,
        GCAddress: data.GCAddress,
        GCCity: data.GCCity,
        GCState: data.GCState,
        GCZipCode: data.GCZipCode,
        lender: data.lender,
        lenderAddress: data.lenderAddress,
        lenderCity: data.lenderCity,
        lenderState: data.lenderState,
        lenderZipCode: data.lenderZipCode,
        jobType: data.jobType,
        jobSector: data.jobSector,
        customerType: data.customerType,
        jobFurnishings: data.jobFurnishings,
        hasNoLender: data.hasNoLender,
        estimatedMaterialAmount: data.estimatedMaterialAmount,
        apn: data.apn,
        buyerId: data.buyerId,
        ...moreDetails,
      };
      const updateSupplierJobResponse = await dispatch(
        updateSupplierJob({
          supplierJobId: data.supplierJobId,
          supplierJobDetails: supplierJobData,
        })
      );
      if (updateSupplierJobResponse.type === 'supplierJobs/updateSupplierJob/fulfilled') {
        await dispatch(
          updateRequestedSupplierJob({
            requestedSupplierJobId: data.id,
            requestedSupplierJobDetails: { status: 'approved' },
          })
        );
        await dispatch(getRequestedSupplierJobs());
        setAccepting(false);
        openDillToast({ message: 'Successfully accepted job', type: 'SUCCESS' });
        handleCloseModal();
      } else {
        setAccepting(false);
        openDillToast({ type: 'ERROR', message: 'Failed to accept job' });
      }
    }
  };

  const handleDeclineJob = async (data: RequestedSupplierJobApiResponse) => {
    setDeclining(true);
    const declineSupplierJob = await dispatch(
      updateRequestedSupplierJob({
        requestedSupplierJobId: data.id,
        requestedSupplierJobDetails: { status: 'declined' },
      })
    );
    if (declineSupplierJob.type === 'requestJobInformation/updateRequestedSupplierJob/fulfilled') {
      await dispatch(getRequestedSupplierJobs());
      setDeclining(false);
      openDillToast({ message: 'Successfully declined job', type: 'SUCCESS' });
      handleCloseModal();
    } else {
      setDeclining(false);
      openDillToast({ type: 'ERROR', message: 'Failed to decline job' });
    }
  };

  const handleEditJob = async (data: RequestedSupplierJobApiResponse) => {
    let moreDetails = {};
    if (data.jobState === 'AZ') {
      moreDetails = {
        jobCounty: data.jobCounty,
        jobLegalDescription: data.jobLegalDescription,
      };
    }
    const supplierJobData: SupplierJobApiResponse = {
      name: data.name,
      jobNumber: data.jobNumber,
      jobAddress: data.jobAddress,
      jobCity: data.jobCity,
      jobState: data.jobState,
      jobZipCode: data.jobZipCode,
      owner: data.owner,
      ownerAddress: data.ownerAddress,
      ownerCity: data.ownerCity,
      ownerState: data.ownerState,
      ownerZipCode: data.ownerZipCode,
      GC: data.GC,
      GCAddress: data.GCAddress,
      GCCity: data.GCCity,
      GCState: data.GCState,
      GCZipCode: data.GCZipCode,
      lender: data.lender,
      lenderAddress: data.lenderAddress,
      lenderCity: data.lenderCity,
      lenderState: data.lenderState,
      lenderZipCode: data.lenderZipCode,
      jobType: data.jobType,
      jobSector: data.jobSector,
      customerType: data.customerType,
      jobFurnishings: data.jobFurnishings,
      hasNoLender: data.hasNoLender,
      estimatedMaterialAmount: data.estimatedMaterialAmount,
      apn: data.apn,
      id: data.supplierJobId,
      supplierId: data.supplierId,
      buyerId: data.buyerId,
      ...moreDetails,
    };
    onEditSupplierJob(supplierJobData, data.id);
    handleCloseModal();
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <>
        {modalState === ModalState.JOB_LIST && (
          <RequestedJobList
            onClose={handleCloseModal}
            requestedSupplierJobs={requestedSupplierJobs}
            onSelectJob={handleSelectJob}
          />
        )}
        {modalState === ModalState.JOB_VIEW && (
          <RequestedJobView
            onClose={handleCloseModal}
            requestedSupplierJob={selectedJob}
            onAcceptSupplierJob={handleAcceptJob}
            onEditSupplierJob={handleEditJob}
            accepting={accepting}
            onDeclineSupplierJob={handleDeclineJob}
            declining={declining}
          />
        )}
      </>
    </Modal>
  );
};

export default AppRequestedJobsModal;
