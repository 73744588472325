import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import dillLogoWithText from '../../assets/svg/dill_logo_with_text.svg';
import { getBasicSupplier } from '../../redux/services/buyersService';
import { AppInputField } from '../../components/general/AppInputField/AppInputField';
import { RequestedSupplierJobCreate, Supplier, SupplierJobUpdate } from '@dill/dill-shared';
import { AppButton } from '../../components/general/AppButton/AppButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import JobDetailsForm from './JobDetailsForm';
import {
  ContructionMonitorPermitOption,
  fetchQueuryJobSiteAddresses,
} from '../../redux/services/verifyAddressService';
import { SupplierVerifiedJobFormData } from '../../components/general/AppNewSupplierJobModal/constants';
import stringSimilarity from 'string-similarity';
import VerifyJobForm from './VerifyJobForm';
import { createRequestedSupplierJob } from '../../redux/services/requestJobInformationService';
import JobSavingSuccess from './JobSavingSuccess';
import { AppSubmitEmailModal } from '../../components/general/AppSubmitEmailModal/AppSubmitEmailModal';
import { on } from 'events';

interface VerifyJobRequestData {
  zipCode: string;
  state: string;
  city: string;
  address: string;
  buyerId: string;
  jobNumber: string;
  name: string;
  buyerName: string;
}

type PartialSupplierJobPreVerify = Pick<
  SupplierJobUpdate,
  | 'buyerId'
  | 'name'
  | 'jobNumber'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
  | 'constructionMonitorPermitId'
>;

type DecodedData = {
  expiresAt: number;
  supplierId: string;
  domainId: string;
  jobId: string;
  name: string;
  jobNumber: string;
  jobAddress: string;
  jobCity: string;
  jobState: string;
  jobZipCode: string;
  owner: string;
  ownerAddress: string;
  ownerCity: string;
  ownerState: string;
  ownerZipCode: string;
  GC: string;
  GCAddress: string;
  GCCity: string;
  GCState: string;
  GCZipCode: string;
  buyerId: string;
  buyerName: string;
  lender: string;
  lenderAddress: string;
  lenderCity: string;
  lenderState: string;
  lenderZipCode: string;
  apn: string;
  estimatedMaterialAmount: number;
  jobType: string;
  jobSector: string;
  customerType: string;
  jobFurnishings: string;
  iat: number;
};

enum ModalState {
  ADD_JOB,
  VERIFY_JOB,
  SUCCESS_SAVING_JOB,
}

const INITIAL_STATE = {
  name: '',
  jobNumber: '',
  jobAddress: '',
  jobCity: '',
  jobState: '',
  jobZipCode: '',
  buyerId: '',
  constructionMonitorPermitId: null,
} as PartialSupplierJobPreVerify;
const RequestJobInformationSheet = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { basicSupplier } = useAppSelector((state) => state.buyers);

  const [supplierJobInput, setSupplierJobInput] = useState(INITIAL_STATE);
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [errors, setErrors] = useState(INITIAL_STATE);
  const [decodedData, setDecodedData] = useState<DecodedData | null>(null);
  const [domainId, setDomainId] = useState('');
  const [modalState, setModalState] = useState(ModalState.ADD_JOB);
  const [verifying, setVerifying] = useState(false);
  const [saving, setSaving] = useState(false);
  const [verifyJobData, setVerifyJobData] = useState<SupplierVerifiedJobFormData>(
    {} as SupplierVerifiedJobFormData
  );
  const [modalTitle, setModalTitle] = useState('');
  const [modalDetails, setModalDetail] = useState('');
  const [modalType, setModalType] = useState<'Success' | 'Error'>('Success');
  const [submitEmailModal, setSubmitEmailModal] = useState(false);
  const [submitData, setSubmitData] = useState<RequestedSupplierJobCreate>(
    {} as RequestedSupplierJobCreate
  );

  const fetchDataUsingDomainId = async (supplierDomainId: string) => {
    try {
      const response = await dispatch(getBasicSupplier({ supplierDomainId }));
      if (response.type === 'buyers/getBasicSupplier/fulfilled') {
        const supplier: Supplier = (response.payload as { data: Supplier })?.data;
        if (supplier) {
          setDecodedData({
            supplierId: supplier.id,
            domainId: supplier.domainId,
            jobId: '',
            name: '',
            jobNumber: '',
            jobAddress: '',
            jobCity: '',
            jobState: '',
            jobZipCode: '',
            owner: '',
            ownerAddress: '',
            ownerCity: '',
            ownerState: '',
            ownerZipCode: '',
            GC: '',
            GCAddress: '',
            GCCity: '',
            GCState: '',
            GCZipCode: '',
            buyerId: '',
            buyerName: '',
            lender: '',
            lenderAddress: '',
            lenderCity: '',
            lenderState: '',
            lenderZipCode: '',
            apn: '',
            estimatedMaterialAmount: 0,
            jobType: 'commercial',
            jobSector: 'private',
            customerType: 'SUB',
            jobFurnishings: '',
            iat: 0,
            expiresAt: 0,
          });
          setModalState(ModalState.ADD_JOB);
        } else {
          setModalState(ModalState.SUCCESS_SAVING_JOB);
          setModalType('Error');
          setModalTitle('Invalid link!');
          setModalDetail('The link you are trying to access is invalid');
        }
      }
    } catch (error) {
      setModalState(ModalState.SUCCESS_SAVING_JOB);
      setModalType('Error');
      setModalTitle('Invalid link!');
      setModalDetail('The link you are trying to access is invalid');
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const paramDomainId = params.get('domainId');
    if (paramDomainId) {
      fetchDataUsingDomainId(paramDomainId);
    } else if (token) {
      try {
        const decoded: DecodedData = jwtDecode(token);
        setDecodedData(decoded);
        const supplierDomainId = decoded?.domainId ?? '';
        setDomainId(supplierDomainId);
        setModalState(ModalState.ADD_JOB);
      } catch (error) {
        setModalState(ModalState.SUCCESS_SAVING_JOB);
        setModalType('Error');
        setModalTitle('Invalid link!');
        setModalDetail('The link you are trying to access is invalid');
      }
    } else {
      setModalState(ModalState.SUCCESS_SAVING_JOB);
      setModalType('Error');
      setModalTitle('Invalid link!');
      setModalDetail('The link you are trying to access is invalid');
      return;
    }
  }, []);

  useEffect(() => {
    if (domainId) {
      dispatch(getBasicSupplier({ supplierDomainId: domainId }));
    }
  }, [domainId]);

  const onVerifyJobState = (dataToVerify: SupplierVerifiedJobFormData) => {
    setVerifyJobData(dataToVerify);
    if (decodedData) {
      const newData = {
        ...decodedData,
        owner: dataToVerify.owner ?? decodedData.owner,
        ownerAddress: dataToVerify.ownerAddress ?? decodedData.ownerAddress,
        ownerCity: dataToVerify.ownerCity ?? decodedData.ownerCity,
        ownerState: dataToVerify.ownerState ?? decodedData.ownerState,
        ownerZipCode: dataToVerify.ownerZipCode ?? decodedData.ownerZipCode,
        GC: dataToVerify.GC ?? decodedData.GC,
        GCAddress: dataToVerify.GCAddress ?? decodedData.GCAddress,
        GCCity: dataToVerify.GCCity ?? decodedData.GCCity,
        GCState: dataToVerify.GCState ?? decodedData.GCState,
        GCZipCode: dataToVerify.GCZipCode ?? decodedData.GCZipCode,
        apn: dataToVerify.apn ?? decodedData.apn,
        constructionMonitorPermitId: dataToVerify.constructionMonitorPermitId ?? null,
        jobType: dataToVerify.jobType ?? decodedData.jobType,
        buyerName:
          decodedData.buyerName === '' ? dataToVerify.buyerName ?? '' : decodedData.buyerName ?? '',
      };
      setDecodedData(newData);
    }
    setModalState(ModalState.VERIFY_JOB);
  };

  const handleVerifyData = async (jobAddressData: VerifyJobRequestData) => {
    // setVerifying(true);
    // if (basicSupplier?.ownerVerificationEnabled) {
    //   await dispatch(fetchQueuryJobSiteAddresses(jobAddressData)).then((res) => {
    //     const { address, city, state, zipCode, jobNumber, name, buyerId } = jobAddressData;
    //     if (res.type === 'jobOwner/fetchAddresses/fulfilled') {
    //       const permits = res.payload as ContructionMonitorPermitOption[];

    //       const queriedJobAddress = `${address}, ${city}, ${state}, ${zipCode}`;

    //       if (permits.length > 0) {
    //         // loop through results and use compareTwoStrings to queriedJobAddress to find the best match
    //         // best match will be the one with a score above 0.5 or null if none are above 0.5
    //         let bestMatch: ContructionMonitorPermitOption | null = null;
    //         let bestMatchScore = 0;

    //         for (const permit of permits) {
    //           const permitAddress = `${permit.ownerAddress}, ${permit.ownerCity}, ${permit.ownerState}, ${permit.ownerZipCode}`;
    //           const score = stringSimilarity.compareTwoStrings(queriedJobAddress, permitAddress);

    //           if (score > 0.5 && score > bestMatchScore) {
    //             bestMatch = permit;
    //             bestMatchScore = score;
    //           }
    //           const jobToVerify = {
    //             name,
    //             buyerId,
    //             owner: '',
    //             ownerAddress: '',
    //             ownerCity: '',
    //             ownerState: '',
    //             ownerZipCode: '',
    //             jobNumber,
    //             jobAddress: address,
    //             jobCity: city,
    //             jobState: state,
    //             jobZipCode: zipCode,
    //             constructionMonitorPermitId: null,
    //             GC: '',
    //             GCAddress: '',
    //             GCCity: '',
    //             GCState: '',
    //             GCZipCode: '',
    //             apn: '',
    //             customerType: 'SUB' as const,
    //           };

    //           if (bestMatch) {
    //             onVerifyJobState({
    //               ...jobToVerify,
    //               owner: bestMatch.owner,
    //               ownerAddress: bestMatch.ownerAddress,
    //               ownerCity: bestMatch.ownerCity,
    //               ownerState: bestMatch.ownerState,
    //               ownerZipCode: bestMatch.ownerZipCode,
    //               constructionMonitorPermitId: Number(bestMatch.constructionMonitorPermitId),
    //               GC: bestMatch.gcName,
    //               GCAddress: bestMatch.gcAddress,
    //               GCCity: bestMatch.gcCity,
    //               GCState: bestMatch.gcState,
    //               GCZipCode: bestMatch.gcZipCode,
    //               jobType: bestMatch.jobType,
    //               apn: bestMatch.apn,
    //               verifiedProperty: {
    //                 address: bestMatch.jobAddress,
    //                 city: bestMatch.jobCity,
    //                 state: bestMatch.jobState,
    //                 zipCode: bestMatch.jobZipCode,
    //                 apn: bestMatch.apn,
    //               },
    //             });
    //             setVerifying(false);
    //           } else {
    //             onVerifyJobState(jobToVerify);
    //             setVerifying(false);
    //           }
    //         }
    //       }
    //     } else {
    //       console.error('Error fetching data: ', res);
    //       setVerifying(false);
    //     }
    //   });
    // } else {
    const { address, city, state, zipCode, jobNumber, name, buyerId, buyerName } = jobAddressData;
    const jobToVerify = {
      name,
      buyerId,
      owner: '',
      ownerAddress: '',
      ownerCity: '',
      ownerState: '',
      ownerZipCode: '',
      jobNumber,
      jobAddress: address,
      jobCity: city,
      jobState: state,
      jobZipCode: zipCode,
      constructionMonitorPermitId: null,
      GC: '',
      GCAddress: '',
      GCCity: '',
      GCState: '',
      GCZipCode: '',
      apn: '',
      jobType: 'commercial' as const,
      jobSector: 'private' as const,
      customerType: 'SUB' as const,
      jobFurnishings: '',
      buyerName,
    };
    onVerifyJobState(jobToVerify);
    setVerifying(false);
    // }
  };

  const handleSubmitJob = async (formData: SupplierVerifiedJobFormData) => {
    setSubmitEmailModal(true);
    let moreDetails = {};
    if (formData.jobState === 'AZ') {
      moreDetails = {
        jobCounty: formData.jobCounty ?? '',
        jobLegalDescription: formData.jobLegalDescription ?? '',
      };
    }
    const data = {
      supplierId: decodedData?.supplierId || '',
      supplierJobId: decodedData?.jobId || '',
      name: formData.name || '',
      jobNumber: formData.jobNumber || '',
      jobAddress: formData.jobAddress || '',
      jobCity: formData.jobCity || '',
      jobState: formData.jobState || '',
      jobZipCode: formData.jobZipCode || '',
      owner: formData.owner || '',
      ownerAddress: formData.ownerAddress || '',
      ownerCity: formData.ownerCity || '',
      ownerState: formData.ownerState || '',
      ownerZipCode: formData.ownerZipCode || '',
      buyerId: decodedData?.buyerId || '',
      GC: formData.GC || '',
      GCAddress: formData.GCAddress || '',
      GCCity: formData.GCCity || '',
      GCState: formData.GCState || '',
      GCZipCode: formData.GCZipCode || '',
      lender: formData.lender || '',
      lenderAddress: formData.lenderAddress || '',
      lenderCity: formData.lenderCity || '',
      lenderState: formData.lenderState || '',
      lenderZipCode: formData.lenderZipCode || '',
      apn: formData.apn || '',
      constructionMonitorPermitId: Number(formData.constructionMonitorPermitId) || null,
      estimatedMaterialAmount: formData.estimatedMaterialAmount
        ? Number(formData.estimatedMaterialAmount)
        : null,
      jobType: formData.jobType || 'commercial',
      jobSector: formData.jobSector || 'private',
      customerType: formData.customerType || 'SUB',
      jobFurnishings: formData.jobFurnishings || '',
      hasNoLender: formData.hasNoLender || false,
      status: 'pending' as const,
      buyerName: decodedData?.buyerName || '',
      ...moreDetails,
    };
    setSubmitData(data);
  };

  const handleSubmitData = async (email: string) => {
    setSaving(true);
    const ccEmail = [
      ...new Set([
        'team@usedill.com',
        basicSupplier?.email ?? '',
        ...(basicSupplier?.prelienNotificationEmails || []),
      ]),
    ];
    const createdRequestedSupplierJob = await dispatch(
      createRequestedSupplierJob({
        requestedSupplierJobDetails: submitData,
        emailTo: email,
        ccEmails: ccEmail.filter((eml) => eml !== '' && eml !== email),
      })
    );
    if (
      createdRequestedSupplierJob.type ===
      'requestJobInformation/createRequestedSupplierJob/fulfilled'
    ) {
      setModalState(ModalState.SUCCESS_SAVING_JOB);
      setSaving(false);
      setModalType('Success');
      setModalTitle('Success!');
      setModalDetail(
        `The job information has been successfully sent to ${basicSupplier?.name ?? ''}.`
      );
    } else {
      setModalState(ModalState.SUCCESS_SAVING_JOB);
      setModalType('Error');
      setModalTitle('Error!');
      setModalDetail(
        `There was an error sending the job information to ${
          basicSupplier?.name ?? ''
        }. Please try again later.`
      );
      setSaving(false);
    }
  };

  return (
    <div className="w-full GREY_100-BG min-h-full pb-4">
      <AppSubmitEmailModal
        open={submitEmailModal}
        handleClose={() => setSubmitEmailModal(false)}
        onSubmit={handleSubmitData}
      />
      <div className="w-full flex items-center justify-center text-xl WHITE-BG font-bold  p-5">
        Job Information Sheet
      </div>
      <div className="flex flex-col px-5 py-4 w-full">
        {basicSupplier && basicSupplier.logo && basicSupplier.logo?.url && (
          <div className="flex flex-col items-center mb-4">
            <img className="h-[80px]" src={basicSupplier.logo.url} />
            <div className="flex items-center mt-2">
              <p>Powered By</p>
              <img className="h-8 ml-2" src={dillLogoWithText} />
            </div>
          </div>
        )}
        {(!basicSupplier || (basicSupplier && !basicSupplier.logo?.url)) && (
          <div className="flex flex-col items-center mb-4 w-full">
            <img className="w-[200px]" src={dillLogoWithText} />
          </div>
        )}
        <div className="flex justify-center items-center">
          <div className="w-1/3">
            <div className="text-center font-semibold">
              Hi <span className="capitalize">{`${decodedData?.buyerName ?? ''}`}</span>, you’ve
              been invited by{' '}
              <span className="PRIMARY_500-CLR">{`${basicSupplier?.name ?? ''}`}</span>, to complete
              the job information below.
            </div>
            {modalState === ModalState.ADD_JOB && decodedData && (
              <JobDetailsForm
                onFormSubmit={handleVerifyData}
                jobSiteData={{
                  name: verifyJobData?.name || decodedData?.name || '',
                  jobNumber: verifyJobData?.jobNumber || decodedData?.jobNumber || '',
                  jobAddress: verifyJobData?.jobAddress || decodedData?.jobAddress || '',
                  jobCity: verifyJobData?.jobCity || decodedData?.jobCity || '',
                  jobState: verifyJobData?.jobState || decodedData?.jobState || '',
                  jobZipCode: verifyJobData?.jobZipCode || decodedData?.jobZipCode || '',
                  buyerId: verifyJobData?.buyerId || decodedData?.buyerId || '',
                  constructionMonitorPermitId: verifyJobData?.constructionMonitorPermitId || null,
                  buyerName: decodedData?.buyerName || '',
                }}
                verifying={verifying}
              />
            )}
            {modalState === ModalState.VERIFY_JOB && (
              <VerifyJobForm
                {...verifyJobData}
                supplierJobData={supplierJobInput}
                onUseVerifiedData={handleSubmitJob}
                onManualDataUpdate={handleSubmitJob}
                onGoBack={() => setModalState(ModalState.ADD_JOB)}
                saving={saving}
                ownerVerificationEnable={false}
                // ownerVerificationEnable={basicSupplier?.ownerVerificationEnabled}
              />
            )}
            {modalState === ModalState.SUCCESS_SAVING_JOB && (
              <JobSavingSuccess type={modalType} details={modalDetails} title={modalTitle} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestJobInformationSheet;
