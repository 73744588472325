import React, { useEffect, useMemo, useState } from 'react';
import { AppInputField } from '../../components/general/AppInputField/AppInputField';
import { AppButton } from '../../components/general/AppButton/AppButton';
import { getAllStates, SupplierJobUpdate } from '@dill/dill-shared/dist/src';
import {
  PartialSupplierOwnerVerify,
  SupplierVerifiedJobFormData,
} from '../../components/general/AppNewSupplierJobModal/constants';
import VerifySupplierJob from './VerifySupplierJob';
import { AppCheckBox } from '../../components/general/AppCheckBox/AppCheckBox';
import { AppDropDown2 } from '../../components/general/AppDropDown2/AppDropDown2';

type VerifiedJobDataPick = Pick<
  SupplierJobUpdate,
  | 'owner'
  | 'ownerAddress'
  | 'ownerCity'
  | 'ownerState'
  | 'ownerZipCode'
  | 'GC'
  | 'GCAddress'
  | 'GCCity'
  | 'GCState'
  | 'GCZipCode'
  | 'GCEmail'
  | 'lender'
  | 'lenderAddress'
  | 'lenderCity'
  | 'lenderState'
  | 'lenderZipCode'
  | 'apn'
  | 'constructionMonitorPermitId'
  | 'estimatedMaterialAmount'
  | 'jobType'
  | 'jobSector'
  | 'customerType'
  | 'jobFurnishings'
  | 'hasNoLender'
  | 'jobAddress'
  | 'jobCity'
  | 'jobState'
  | 'jobZipCode'
  | 'jobCounty'
  | 'jobLegalDescription'
>;

interface SupplierVerifyJobFormFunctions {
  supplierJobData: PartialSupplierOwnerVerify;
  onUseVerifiedData: (data: Record<string, any>) => void;
  onManualDataUpdate: (data: Record<string, any>) => void;
  onGoBack: () => void;
  saving: boolean;
  ownerVerificationEnable?: boolean;
}

enum ModalState {
  ADD_JOB,
  VERIFY_JOB,
  VERIFY_OWNER,
  VERIFY_OWNER_MANUAL,
}

type SupplierVerifyJobFormProps = SupplierVerifyJobFormFunctions & SupplierVerifiedJobFormData;

const VerifyJobForm = (props: SupplierVerifyJobFormProps) => {
  const {
    onUseVerifiedData,
    onManualDataUpdate,
    onGoBack,
    jobAddress,
    jobCity,
    jobState,
    jobZipCode,
    owner,
    ownerAddress,
    ownerCity,
    ownerState,
    ownerZipCode,
    verifiedProperty,
    jobNumber,
    name,
    buyerId,
    supplierJobData,
    constructionMonitorPermitId,
    estimatedMaterialAmount,
    jobType,
    jobSector,
    customerType,
    jobFurnishings,
    hasNoLender,
    GC,
    GCAddress,
    GCCity,
    GCState,
    GCZipCode,
    saving,
    ownerVerificationEnable,
  } = props;

  const [modalState, setModalState] = useState<ModalState>(ModalState.VERIFY_OWNER);
  const [formState, setFormState] = useState<VerifiedJobDataPick>({});
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [ownerAndGCSame, setOwnerAndGCSame] = useState(false);

  const [errors, setErrors] = useState<VerifiedJobDataPick>({});
  const [verifyJobData, setVerifyJobData] = useState<VerifiedJobDataPick>({});

  const onChange2 = (data: { name: string; value: string | number | boolean }) => {
    setFormState((prevState) => ({
      ...prevState,
      [data.name]: data.value,
    }));
  };

  useEffect(() => {
    setVerifyJobData({
      jobAddress: jobAddress,
      jobCity: jobCity,
      jobState: jobState,
      jobZipCode: jobZipCode,
      owner: owner,
      ownerAddress: ownerAddress,
      ownerCity: ownerCity,
      ownerState: ownerState,
      ownerZipCode: ownerZipCode,
      GC: GC?.trim() ?? '',
      GCAddress,
      GCCity,
      GCState,
      GCZipCode,
      jobType,
      apn: verifiedProperty?.apn ?? '',
    });
  }, [
    jobAddress,
    jobCity,
    jobState,
    jobZipCode,
    owner,
    ownerAddress,
    ownerCity,
    ownerState,
    ownerZipCode,
    GC,
    GCAddress,
    GCCity,
    GCState,
    GCZipCode,
    jobType,
  ]);

  useEffect(() => {
    if (!ownerVerificationEnable) {
      setModalState(ModalState.VERIFY_OWNER_MANUAL);
    }
  }, []);

  const allStates = useMemo(() => {
    return getAllStates();
  }, []);

  const checkIfNotDataFound = useMemo(() => {
    const keysToCheck: Array<keyof VerifiedJobDataPick> = [
      'owner',
      'ownerAddress',
      'ownerCity',
      'ownerState',
      'ownerZipCode',
      'GC',
      'GCAddress',
      'GCCity',
      'GCState',
      'GCZipCode',
      'apn',
    ];

    return keysToCheck.every((key) => verifyJobData[key] === '');
  }, [verifyJobData]);

  const changeAutoState = () => {
    setModalState(ModalState.VERIFY_OWNER);
  };

  const changeManualState = () => {
    setModalState(ModalState.VERIFY_OWNER_MANUAL);
  };

  const validateInputs = () => {
    const newErrors = {} as VerifiedJobDataPick;

    let isValid = true;

    if (formState.owner?.trim() === '') {
      newErrors.owner = 'Owner is required.';
      isValid = false;
    }
    if (formState.ownerAddress?.trim() === '') {
      newErrors.ownerAddress = 'Owner Address is required.';
      isValid = false;
    }
    if (formState.ownerCity?.trim() === '') {
      newErrors.ownerCity = 'Owner City is required.';
      isValid = false;
    }
    if (formState.ownerState?.trim() === '') {
      newErrors.ownerState = 'Owner State is required.';
      isValid = false;
    }
    if (formState.ownerZipCode?.trim() === '') {
      newErrors.ownerZipCode = 'Owner Zip is required.';
      isValid = false;
    }
    if (formState.GC?.trim() === '') {
      newErrors.GC = 'GC is required.';
      isValid = false;
    }
    if (formState.GCAddress?.trim() === '') {
      newErrors.GCAddress = 'GC Address is required.';
      isValid = false;
    }
    if (formState.GCCity?.trim() === '') {
      newErrors.GCCity = 'GC City is required.';
      isValid = false;
    }
    if (formState.GCState?.trim() === '') {
      newErrors.GCState = 'GC State is required.';
      isValid = false;
    }
    if (formState.GCZipCode?.trim() === '') {
      newErrors.GCZipCode = 'GC Zip is required.';
      isValid = false;
    }
    if (
      formState.estimatedMaterialAmount === '' ||
      Number(formState.estimatedMaterialAmount) <= 0
    ) {
      newErrors.estimatedMaterialAmount =
        formState.estimatedMaterialAmount === ''
          ? 'Estimated material amount is required.'
          : 'Estimated material amount should be more than 0.';
      isValid = false;
    }
    if (formState.jobFurnishings?.trim() === '') {
      newErrors.jobFurnishings = 'Job Furnishings is required.';
      isValid = false;
    }
    if (formState.lender?.trim() === '' && !formState.hasNoLender) {
      newErrors.lender = 'Lender is required.';
      isValid = false;
    }
    if (formState.lenderAddress?.trim() === '' && !formState.hasNoLender) {
      newErrors.lenderAddress = 'Lender Address is required.';
      isValid = false;
    }
    if (formState.lenderCity?.trim() === '' && !formState.hasNoLender) {
      newErrors.lenderCity = 'Lender City is required.';
      isValid = false;
    }
    if (formState.lenderState?.trim() === '' && !formState.hasNoLender) {
      newErrors.lenderState = 'Lender State is required.';
      isValid = false;
    }
    if (formState.lenderZipCode?.trim() === '' && !formState.hasNoLender) {
      newErrors.lenderZipCode = 'Lender Zip is required.';
      isValid = false;
    }
    if (formState.jobState?.trim() === 'AZ' && formState.jobCounty?.trim() === '') {
      newErrors.jobCounty = 'Job county is required.';
      isValid = false;
    }
    if (formState.jobState?.trim() === 'AZ' && formState.jobLegalDescription?.trim() === '') {
      newErrors.jobLegalDescription = 'Job legal description is required.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const onSubmitData = () => {
    if (!validateInputs()) {
      setShowErrorAbove(true);
      return;
    }
    setShowErrorAbove(false);
    const newFormStateData = {
      ...formState,
      lender: formState.hasNoLender ? '' : formState.lender,
      lenderAddress: formState.hasNoLender ? '' : formState.lenderAddress,
      lenderCity: formState.hasNoLender ? '' : formState.lenderCity,
      lenderState: formState.hasNoLender ? '' : formState.lenderState,
      lenderZipCode: formState.hasNoLender ? '' : formState.lenderZipCode,
    };
    if (modalState === ModalState.VERIFY_OWNER) {
      const updatedData = {
        ...newFormStateData,
        jobAddress: verifiedProperty?.address ? verifiedProperty.address : jobAddress,
        jobCity: verifiedProperty?.city ? verifiedProperty.city : jobCity,
        jobState: verifiedProperty?.state ? verifiedProperty.state : jobState,
        jobZipCode: verifiedProperty?.zipCode ? verifiedProperty.zipCode : jobZipCode,
        apn: verifiedProperty?.apn ? verifiedProperty.apn : '',
        constructionMonitorPermitId: constructionMonitorPermitId,
      };
      onUseVerifiedData(updatedData);
    } else if (modalState === ModalState.VERIFY_OWNER_MANUAL) {
      onManualDataUpdate(newFormStateData);
    }
  };

  useEffect(() => {
    if (modalState === ModalState.VERIFY_OWNER || modalState === ModalState.VERIFY_OWNER_MANUAL) {
      onChange2({
        name: 'owner',
        value: modalState === ModalState.VERIFY_OWNER ? owner || '' : '',
      });
      onChange2({
        name: 'ownerAddress',
        value: modalState === ModalState.VERIFY_OWNER ? ownerAddress || '' : '',
      });
      onChange2({
        name: 'ownerCity',
        value: modalState === ModalState.VERIFY_OWNER ? ownerCity || '' : '',
      });
      onChange2({
        name: 'ownerState',
        value: modalState === ModalState.VERIFY_OWNER ? ownerState || '' : '',
      });
      onChange2({
        name: 'ownerZipCode',
        value: modalState === ModalState.VERIFY_OWNER ? ownerZipCode || '' : '',
      });
      onChange2({
        name: 'apn',
        value: modalState === ModalState.VERIFY_OWNER ? verifiedProperty?.apn || '' : '',
      });
      onChange2({ name: 'GC', value: formState.GC || '' });
      onChange2({ name: 'GCAddress', value: formState.GCAddress || '' });
      onChange2({ name: 'GCCity', value: formState.GCCity || '' });
      onChange2({ name: 'GCState', value: formState.GCState || '' });
      onChange2({ name: 'GCZipCode', value: formState.GCZipCode || '' });
      onChange2({ name: 'lender', value: formState.lender || '' });
      onChange2({ name: 'lenderAddress', value: formState.lenderAddress || '' });
      onChange2({ name: 'lenderCity', value: formState.lenderCity || '' });
      onChange2({ name: 'lenderState', value: formState.lenderState || '' });
      onChange2({ name: 'lenderZipCode', value: formState.lenderZipCode || '' });
      onChange2({ name: 'jobNumber', value: jobNumber || '' });
      onChange2({ name: 'jobAddress', value: jobAddress || '' });
      onChange2({ name: 'jobCity', value: jobCity || '' });
      onChange2({ name: 'jobState', value: jobState || '' });
      onChange2({ name: 'jobZipCode', value: jobZipCode || '' });
      onChange2({ name: 'name', value: name || '' });
      onChange2({ name: 'buyerId', value: buyerId || '' });
      onChange2({
        name: 'estimatedMaterialAmount',
        value: formState.estimatedMaterialAmount || 0,
      });
      onChange2({ name: 'jobType', value: jobType || 'commercial' });
      onChange2({ name: 'jobSector', value: formState.jobSector || 'private' });
      onChange2({ name: 'customerType', value: formState.customerType || 'SUB' });
      onChange2({ name: 'jobFurnishings', value: formState.jobFurnishings || '' });
      onChange2({ name: 'hasNoLender', value: formState.hasNoLender || false });
      if (jobState === 'AZ') {
        onChange2({ name: 'jobCounty', value: formState.jobCounty || '' });
        onChange2({ name: 'jobLegalDescription', value: formState.jobLegalDescription || '' });
      }
    }
  }, [modalState]);

  useEffect(() => {
    if (supplierJobData) {
      let moreDetails = {};
      if (supplierJobData.jobState === 'AZ') {
        moreDetails = {
          jobCounty: supplierJobData.jobCounty ?? '',
          jobLegalDescription: supplierJobData.jobLegalDescription ?? '',
        };
      }
      setFormState({
        apn: supplierJobData.apn,
        owner: supplierJobData.owner,
        ownerAddress: supplierJobData.ownerAddress,
        ownerCity: supplierJobData.ownerCity,
        ownerState: supplierJobData.ownerState,
        ownerZipCode: supplierJobData.ownerZipCode,
        GC: supplierJobData.GC,
        GCAddress: supplierJobData.GCAddress,
        GCCity: supplierJobData.GCCity,
        GCState: supplierJobData.GCState,
        GCZipCode: supplierJobData.GCZipCode,
        lender: supplierJobData.lender,
        lenderAddress: supplierJobData.lenderAddress,
        lenderCity: supplierJobData.lenderCity,
        lenderState: supplierJobData.lenderState,
        lenderZipCode: supplierJobData.lenderZipCode,
        estimatedMaterialAmount: supplierJobData.estimatedMaterialAmount,
        jobType: supplierJobData.jobType,
        jobSector: supplierJobData.jobSector,
        customerType: supplierJobData.customerType,
        jobFurnishings: supplierJobData.jobFurnishings,
        hasNoLender: supplierJobData.hasNoLender,
        ...moreDetails,
      });
    }
  }, [supplierJobData]);

  const handleOwnerAndGCSame = (value: boolean) => {
    if (value) {
      const data = {
        owner: formState.owner ? formState.owner : formState.GC,
        ownerAddress: formState.ownerAddress ? formState.ownerAddress : formState.GCAddress,
        ownerCity: formState.ownerCity ? formState.ownerCity : formState.GCCity,
        ownerState: formState.ownerState ? formState.ownerState : formState.GCState,
        ownerZipCode: formState.ownerZipCode ? formState.ownerZipCode : formState.GCZipCode,
      };
      setFormState((prevState) => ({
        ...prevState,
        ...data,
      }));
    }
    setOwnerAndGCSame(value);
  };

  return (
    <div className="WHITE-BG w-full mt-4 rounded-lg">
      <div className="flex flex-col w-full">
        <VerifySupplierJob
          verifyJobData={verifyJobData}
          ownerVerificationEnable={ownerVerificationEnable}
        />
        {(modalState === ModalState.VERIFY_OWNER ||
          modalState === ModalState.VERIFY_OWNER_MANUAL) && (
          <div className="px-6">
            <div className="pb-3">
              <div className="text-xs mb-1 font-semibold">Job Type</div>
              <div className="flex flex-row gap-3">
                <div className="flex  items-center">
                  <div
                    className="flex items-center mr-1 cursor-pointer"
                    onClick={() => onChange2({ name: 'jobType', value: 'commercial' })}>
                    <div
                      className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                        formState.jobType === 'commercial'
                          ? 'PRIMARY_500-BORDER'
                          : 'GREY_500-BORDER'
                      }`}>
                      <div
                        className={`flex items-center h-2 w-2 rounded-full ${
                          formState.jobType === 'commercial' ? 'PRIMARY_500-BG ' : ''
                        }`}></div>
                    </div>
                  </div>
                  <div className="text-xs">Commercial</div>
                </div>
                <div className="flex  items-center">
                  <div
                    className="flex items-center mr-1 cursor-pointer"
                    onClick={() => onChange2({ name: 'jobType', value: 'residential' })}>
                    <div
                      className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                        formState.jobType === 'residential'
                          ? 'PRIMARY_500-BORDER'
                          : 'GREY_500-BORDER'
                      }`}>
                      <div
                        className={`flex items-center h-2 w-2 rounded-full ${
                          formState.jobType === 'residential' ? 'PRIMARY_500-BG ' : ''
                        }`}></div>
                    </div>
                  </div>
                  <div className="text-xs">Residential</div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="text-xs mb-1 font-semibold">Job Sector</div>
              <div className="flex flex-row gap-3">
                <div className="flex  items-center">
                  <div
                    className="flex items-center mr-1 cursor-pointer"
                    onClick={() => onChange2({ name: 'jobSector', value: 'private' })}>
                    <div
                      className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                        formState.jobSector === 'private' ? 'PRIMARY_500-BORDER' : 'GREY_500-BORDER'
                      }`}>
                      <div
                        className={`flex items-center h-2 w-2 rounded-full ${
                          formState.jobSector === 'private' ? 'PRIMARY_500-BG ' : ''
                        }`}></div>
                    </div>
                  </div>
                  <div className="text-xs">Private</div>
                </div>
                <div className="flex  items-center">
                  <div
                    className="flex items-center mr-1 cursor-pointer"
                    onClick={() => onChange2({ name: 'jobSector', value: 'public' })}>
                    <div
                      className={`flex items-center justify-center h-4 w-4 border-2 rounded-full ${
                        formState.jobSector === 'public' ? 'PRIMARY_500-BORDER' : 'GREY_500-BORDER'
                      }`}>
                      <div
                        className={`flex items-center h-2 w-2 rounded-full ${
                          formState.jobSector === 'public' ? 'PRIMARY_500-BG ' : ''
                        }`}></div>
                    </div>
                  </div>
                  <div className="text-xs">Public</div>
                </div>
              </div>
            </div>
            <div className="flex flex-row  pb-3">
              <div className="w-full">
                <AppInputField
                  id="jobFurnishings"
                  label="Job Furnishings"
                  placeholder="Job Furnishings"
                  isRequired={true}
                  value={formState.jobFurnishings}
                  errorText={errors.jobFurnishings ? `${errors.jobFurnishings}` : ''}
                  onTextChange={(text) => {
                    onChange2({ name: 'jobFurnishings', value: text });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row  pb-3">
              <div className="w-full">
                <AppInputField
                  id="estimatedMaterialAmount"
                  label="Estimated Material Amount"
                  placeholder="12345"
                  inputType="number"
                  isRequired={true}
                  value={formState.estimatedMaterialAmount}
                  errorText={
                    errors.estimatedMaterialAmount ? `${errors.estimatedMaterialAmount}` : ''
                  }
                  onTextChange={(text) => {
                    onChange2({ name: 'estimatedMaterialAmount', value: text });
                  }}
                />
              </div>
            </div>
            {formState.jobState === 'AZ' && (
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="jobCounty"
                    label="Job County"
                    isRequired={true}
                    placeholder="Job County"
                    value={formState.jobCounty}
                    errorText={errors.jobCounty ? `${errors.jobCounty}` : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'jobCounty', value: text });
                    }}
                  />
                </div>
              </div>
            )}
            {formState.jobState === 'AZ' && (
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="jobLegalDescription"
                    isRequired={true}
                    label="Job Legal Description"
                    placeholder="Job Legal Description"
                    value={formState.jobLegalDescription}
                    errorText={errors.jobLegalDescription ? `${errors.jobLegalDescription}` : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'jobLegalDescription', value: text });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-row  pb-3">
              <div className="w-full">
                <AppInputField
                  id="apn"
                  label="Property APN"
                  placeholder="12345"
                  isRequired={false}
                  value={formState.apn}
                  errorText={errors.apn ? errors.apn : ''}
                  onTextChange={(text) => {
                    onChange2({ name: 'apn', value: text });
                  }}
                />
              </div>
            </div>
            <div className="flex w-full pb-3">
              <AppCheckBox
                className="mr-2"
                isChecked={formState.customerType === 'GC'}
                onClick={(val: boolean) => {
                  onChange2({ name: 'customerType', value: val ? 'GC' : 'SUB' });
                }}
              />
              <div className="text-black text-sm">
                We are the general contractor on the project, not a subcontractor.
              </div>
            </div>
            <div className="flex flex-row  pb-3">
              <div className="w-full relative">
                <div className="w-full ">
                  <AppInputField
                    id="gc"
                    label="GC"
                    inputType="text"
                    placeholder="GC"
                    isRequired={true}
                    value={formState.GC}
                    errorText={errors.GC ? errors.GC : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'GC', value: text });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row  pb-3">
              <div className="w-full ">
                <AppInputField
                  id="GCAddress"
                  label="GC Address"
                  inputType="text"
                  placeholder="GC Address"
                  isRequired={true}
                  value={formState.GCAddress}
                  errorText={errors.GCAddress ? errors.GCAddress : ''}
                  onTextChange={(text) => {
                    onChange2({ name: 'GCAddress', value: text });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row  pb-3  gap-2">
              <div className="w-full">
                <AppInputField
                  id="GCCity"
                  label="GC City"
                  inputType="text"
                  placeholder="San Francisco"
                  isRequired={true}
                  value={formState.GCCity}
                  errorText={errors.GCCity ? errors.GCCity : ''}
                  onTextChange={(text) => {
                    onChange2({ name: 'GCCity', value: text });
                  }}
                />
              </div>

              <div className="spacer" />

              <div className="w-full">
                <AppDropDown2
                  isRequired
                  label="GC State"
                  placeholder="CA"
                  value={formState.GCState || null}
                  errorText={errors.GCState ? errors.GCState : ''}
                  items={[
                    ...Object.keys(allStates).map((stateName) => {
                      const key = stateName as keyof typeof allStates;
                      const stateKey = allStates[key];
                      return {
                        label: `${stateKey} - ${stateName}`,
                        value: stateKey,
                      };
                    }),
                  ]}
                  onSelectChange={(item) => {
                    if (item && item?.value !== null) {
                      const foundState = Object.values(allStates).find(
                        (stateName) => stateName === item?.value
                      );
                      if (foundState) {
                        onChange2({ name: 'GCState', value: item.value });
                      }
                    }
                  }}
                />
              </div>
              <div className="spacer" />
              <div className="w-full">
                <AppInputField
                  id="GCZip"
                  label="GC Zip Code"
                  inputType="text"
                  placeholder="12345"
                  isRequired={true}
                  value={formState.GCZipCode}
                  errorText={errors.GCZipCode ? errors.GCZipCode : ''}
                  onTextChange={(text) => {
                    onChange2({ name: 'GCZipCode', value: text });
                  }}
                />
              </div>
            </div>
            <div className="flex w-full pb-3">
              <AppCheckBox
                className="mr-2"
                isChecked={ownerAndGCSame}
                onClick={handleOwnerAndGCSame}
              />
              <div className="text-black text-sm">Owner and GC are the same.</div>
            </div>
            <div className="flex flex-row  pb-3">
              <div className="w-full">
                <AppInputField
                  id="owner"
                  label="Owner"
                  inputType="text"
                  placeholder="Owner"
                  isRequired={true}
                  value={formState.owner}
                  errorText={errors.owner ? errors.owner : ''}
                  onTextChange={(text) => {
                    onChange2({ name: 'owner', value: text });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row  pb-3">
              <div className="w-full">
                <AppInputField
                  id="ownerAddress"
                  label="Owner Address"
                  inputType="text"
                  placeholder="Owner Address"
                  isRequired={true}
                  value={formState.ownerAddress}
                  errorText={errors.ownerAddress ? errors.ownerAddress : ''}
                  onTextChange={(text) => {
                    onChange2({ name: 'ownerAddress', value: text });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row pb-3  gap-2">
              <div className="w-full">
                <div className="w-full">
                  <AppInputField
                    id="ownerCity"
                    label="Owner City"
                    inputType="text"
                    placeholder="San Francisco"
                    isRequired={true}
                    value={formState.ownerCity}
                    errorText={errors.ownerCity ? errors.ownerCity : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'ownerCity', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="spacer" />
              <div className="w-full">
                <AppDropDown2
                  isRequired
                  label="Owner State"
                  placeholder="CA"
                  value={formState.ownerState || null}
                  errorText={errors.ownerState ? errors.ownerState : ''}
                  items={[
                    ...Object.keys(allStates).map((stateName) => {
                      const key = stateName as keyof typeof allStates;
                      const stateKey = allStates[key];
                      return {
                        label: `${stateKey} - ${stateName}`,
                        value: stateKey,
                      };
                    }),
                  ]}
                  onSelectChange={(item) => {
                    if (item && item?.value !== null) {
                      const foundState = Object.values(allStates).find(
                        (stateName) => stateName === item?.value
                      );
                      if (foundState) {
                        onChange2({ name: 'ownerState', value: item.value });
                      }
                    }
                  }}
                />
              </div>
              <div className="spacer" />
              <div className="w-full">
                <div className="w-full">
                  <AppInputField
                    id="ownerZip"
                    label="Owner Zip Code"
                    inputType="text"
                    placeholder="12345"
                    isRequired={true}
                    value={formState.ownerZipCode}
                    errorText={errors.ownerZipCode ? errors.ownerZipCode : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'ownerZipCode', value: text });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full pb-3 items-center">
              <AppCheckBox
                className="mr-2"
                isChecked={formState.hasNoLender}
                onClick={(val: boolean) => {
                  onChange2({ name: 'hasNoLender', value: val });
                }}
              />
              <div className="text-black text-sm">There is no lender on this job</div>
            </div>
            {!formState.hasNoLender && (
              <div>
                <div className="flex flex-row  pb-3">
                  <div className="w-full">
                    <AppInputField
                      id="lender"
                      label="Lender"
                      inputType="text"
                      placeholder="Lender"
                      isRequired={!formState.hasNoLender}
                      value={formState.lender}
                      errorText={errors.lender ? errors.lender : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'lender', value: text });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row  pb-3">
                  <div className="w-full ">
                    <AppInputField
                      id="lenderAddress"
                      label="Lender Address"
                      inputType="text"
                      placeholder="Lender Address"
                      isRequired={!hasNoLender}
                      value={formState.lenderAddress}
                      errorText={errors.lenderAddress ? errors.lenderAddress : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'lenderAddress', value: text });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row  pb-3  gap-2">
                  <div className="w-full ">
                    <AppInputField
                      id="lenderCity"
                      label="Lender City"
                      inputType="text"
                      placeholder="San Francisco"
                      isRequired={!formState.hasNoLender}
                      value={formState.lenderCity}
                      errorText={errors.lenderCity ? errors.lenderCity : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'lenderCity', value: text });
                      }}
                    />
                  </div>
                  <div className="spacer" />
                  <div className="w-full">
                    <AppDropDown2
                      isRequired
                      label="Lender State"
                      placeholder="CA"
                      value={formState.lenderState || null}
                      errorText={errors.lenderState ? errors.lenderState : ''}
                      items={[
                        ...Object.keys(allStates).map((stateName) => {
                          const key = stateName as keyof typeof allStates;
                          const stateKey = allStates[key];
                          return {
                            label: `${stateKey} - ${stateName}`,
                            value: stateKey,
                          };
                        }),
                      ]}
                      onSelectChange={(item) => {
                        if (item && item?.value !== null) {
                          const foundState = Object.values(allStates).find(
                            (stateName) => stateName === item?.value
                          );
                          if (foundState) {
                            onChange2({ name: 'lenderState', value: item.value });
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="spacer" />
                  <div className="w-full">
                    <AppInputField
                      id="lenderZip"
                      label="Lender Zip Code"
                      inputType="text"
                      placeholder="12345"
                      isRequired={!formState.hasNoLender}
                      value={formState.lenderZipCode}
                      errorText={errors.lenderZipCode ? errors.lenderZipCode : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'lenderZipCode', value: text });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
      {showErrorAbove && (
        <div className="my-1 text-xs ERROR_500-CLR text-center">Please see errors above</div>
      )}
      <div className="flex px-4 py-2 w-full">
        {modalState === ModalState.VERIFY_JOB && (
          <div className="flex w-full justify-between">
            <div>
              <AppButton
                text="Back"
                type="TERTIARY"
                onClick={onGoBack}
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  height: '40px',
                }}
              />
            </div>
            <div className="flex flex-row gap-2">
              <AppButton
                text="Manually update information"
                type="SECONDARY"
                onClick={changeManualState}
                buttonStyles={{
                  marginTop: '4px',
                  marginBottom: '4px',
                  height: '40px',
                }}
              />
              {!checkIfNotDataFound && (
                <AppButton
                  text="Use this data"
                  onClick={changeAutoState}
                  buttonStyles={{
                    marginTop: '4px',
                    marginBottom: '4px',
                    height: '40px',
                  }}
                />
              )}
            </div>
          </div>
        )}
        {(modalState === ModalState.VERIFY_OWNER ||
          modalState === ModalState.VERIFY_OWNER_MANUAL) && (
          <div className="flex flex-row-reverse px-4 py-2 w-full gap-2">
            <AppButton
              text="Submit"
              type="PRIMARY"
              onClick={onSubmitData}
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                height: '40px',
                width: '100px',
              }}
              isDisabled={saving}
              isLoading={saving}
            />
            <AppButton
              text="Back"
              type="TERTIARY"
              onClick={onGoBack}
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                height: '40px',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyJobForm;
